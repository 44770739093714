import { ChainId, Pair, Token } from 'gsswap_sdk'
import { useCallback, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { useActiveWeb3React } from '../../hooks'
import { AppDispatch, AppState } from '../index'
import {
    addSerializedPair,
    addSerializedToken,
    removeSerializedToken,
    SerializedPair,
    SerializedToken,
    updateUserDarkMode,
    updateUserDeadline,
    updateUserExpertMode,
    updateUserInfo,
    updateUserSlippageTolerance,
} from './actions'

function serializeToken(token: Token): SerializedToken {
    return {
        chainId: token.chainId,
        address: token.address,
        decimals: token.decimals,
        symbol: token.symbol,
        name: token.name,
    }
}

function deserializeToken(serializedToken: SerializedToken): Token {
    return new Token(
        serializedToken.chainId,
        serializedToken.address,
        serializedToken.decimals,
        serializedToken.symbol,
        serializedToken.name
    )
}

export function useIsDarkMode(): boolean {
    const { userDarkMode, matchesDarkMode } = useSelector<
        AppState,
        { userDarkMode: boolean | null; matchesDarkMode: boolean }
    >(
        ({ user: { matchesDarkMode, userDarkMode } }) => ({
            userDarkMode,
            matchesDarkMode,
        }),
        shallowEqual
    )

    return userDarkMode === null ? matchesDarkMode : userDarkMode
}

export function useDarkModeManager(): [boolean, () => void] {
    const dispatch = useDispatch<AppDispatch>()
    const darkMode = useIsDarkMode()

    const toggleSetDarkMode = useCallback(() => {
        dispatch(updateUserDarkMode({ userDarkMode: !darkMode }))
    }, [darkMode, dispatch])

    return [darkMode, toggleSetDarkMode]
}

export function useIsExpertMode(): boolean {
    return useSelector<AppState, AppState['user']['userExpertMode']>((state) => state.user.userExpertMode)
}

export function useExpertModeManager(): [boolean, () => void] {
    const dispatch = useDispatch<AppDispatch>()
    const expertMode = useIsExpertMode()

    const toggleSetExpertMode = useCallback(() => {
        dispatch(updateUserExpertMode({ userExpertMode: !expertMode }))
    }, [expertMode, dispatch])

    return [expertMode, toggleSetExpertMode]
}

export function useUserSlippageTolerance(): [number, (slippage: number) => void] {
    const dispatch = useDispatch<AppDispatch>()
    const userSlippageTolerance = useSelector<AppState, AppState['user']['userSlippageTolerance']>((state) => {
        return state.user.userSlippageTolerance
    })

    const setUserSlippageTolerance = useCallback(
        (userSlippageTolerance: number) => {
            dispatch(updateUserSlippageTolerance({ userSlippageTolerance }))
        },
        [dispatch]
    )

    return [userSlippageTolerance, setUserSlippageTolerance]
}

export function useUserDeadline(): [number, (slippage: number) => void] {
    const dispatch = useDispatch<AppDispatch>()
    const userDeadline = useSelector<AppState, AppState['user']['userDeadline']>((state) => {
        return state.user.userDeadline
    })

    const setUserDeadline = useCallback(
        (userDeadline: number) => {
            dispatch(updateUserDeadline({ userDeadline }))
        },
        [dispatch]
    )

    return [userDeadline, setUserDeadline]
}

export function useAddUserToken(): (token: Token) => void {
    const dispatch = useDispatch<AppDispatch>()
    return useCallback(
        (token: Token) => {
            dispatch(addSerializedToken({ serializedToken: serializeToken(token) }))
        },
        [dispatch]
    )
}

export function useRemoveUserAddedToken(): (chainId: number, address: string) => void {
    const dispatch = useDispatch<AppDispatch>()
    return useCallback(
        (chainId: number, address: string) => {
            dispatch(removeSerializedToken({ chainId, address }))
        },
        [dispatch]
    )
}

export function useUserAddedTokens(): Token[] {
    const { chainId } = useActiveWeb3React()
    const serializedTokensMap = useSelector<AppState, AppState['user']['tokens']>(({ user: { tokens } }) => tokens)

    return useMemo(() => {
        if (!chainId) return []
        return Object.values(serializedTokensMap[chainId as ChainId] ?? {}).map(deserializeToken)
    }, [serializedTokensMap, chainId])
}

function serializePair(pair: Pair): SerializedPair {
    return {
        token0: serializeToken(pair.token0),
        token1: serializeToken(pair.token1),
    }
}

export function usePairAdder(): (pair: Pair) => void {
    const dispatch = useDispatch<AppDispatch>()

    return useCallback(
        (pair: Pair) => {
            dispatch(addSerializedPair({ serializedPair: serializePair(pair) }))
        },
        [dispatch]
    )
}

/**
 * Given two tokens return the liquidity token that represents its liquidity shares
 * @param tokenA one of the two tokens
 * @param tokenB the other token
 */
export function toV2LiquidityToken([tokenA, tokenB]: [Token, Token]): Token {
    return new Token(tokenA.chainId, Pair.getAddress(tokenA, tokenB), 18, 'UNI-V2', 'Uniswap V2')
}

export const useUserInfo = () => {
    const dispatch = useDispatch<AppDispatch>()
    const userInfo = useSelector<AppState, AppState['user']['userInfo']>(({ user }) => user.userInfo)

    const updateUserInfoData = useCallback((userInfo) => {
        dispatch(
            updateUserInfo({
                userInfo,
            })
        )
    }, [])

    return {
        userInfo,
        updateUserInfoData,
    }
}
