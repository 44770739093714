import { useWeb3React } from '@web3-react/core'
import { Avatar } from 'antd'
import { ajaxGet } from 'api/axios'
import avatar from 'assets/img/avatar.png'
import ShowMoreImg from 'assets/img/show-more.png'
import Modal, { ModalConfirmed } from 'components/Modal'
import StyledSelect from 'components/StyledSelect'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import ReferendumsFactor from 'constants/abis/GSReferendumsFactory.json'
import { GS_REFERENDUMS_FACTORY_ADDRESS } from 'constants/index'
import { parseEther } from 'ethers/lib/utils'
import { ChainId } from 'gsswap_sdk'
import { useContract, useGsCouncillorsVotingContract, useGsNodesVotingContract } from 'hooks/useContract'
import useGetUserVotingInfo from 'hooks/useGetUserVotingInfo'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useETHBalances } from 'state/wallet/hooks'
import styled from 'styled-components'
import { TYPE } from 'theme'
import { shortenAddress } from 'utils'

import { Base as BaseBtn, ButtonPrimary } from '../Button'
import Input from '../NumericalInput'

const StyledMotionDetail = styled.div`
    width: 780px;
    height: 0;
    overflow: hidden;

    &.inited {
        overflow: auto;
        height: auto;
    }
`
const MotionTitle = styled.h1`
    /* margin-top: 10px; */
    font-size: 24px;
    margin-bottom: 0;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
    line-height: 34px;
    height: 34px;
`

const MotionSponsor = styled.div`
    height: 80px;
    display: flex;
    align-items: center;

    span {
        font-size: 18px;
        font-weight: 500;
        color: ${({ theme }) => theme.text6};
        &.motion-tag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 82px;
            height: 32px;

            background: rgba(255, 198, 113, 0.4);
            border-radius: 20px;
            font-size: 14px;
            font-weight: 400;
            color: ${({ theme }) => theme.text1};
            margin-right: 16px;
        }
        &.motion-sponsor {
            color: ${({ theme }) => theme.text1};
            margin-left: 6px;
        }
    }
`

interface MaskImageProps {
    contentHeight: string
}

const MotionContentBox = styled.div<MaskImageProps>`
    position: relative;

    .motion-content {
        word-break: break-all;
        &.hide {
            height: 312px;
            overflow: hidden;
            mask-image: linear-gradient(to bottom, ${({ theme }) => theme.text1}, rgba(0, 0, 0, 0));
        }
        &.show {
            height: ${({ contentHeight }) => contentHeight};
            transition: all 0.3s linear;
        }
    }

    .base-btn-box {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(241, 246, 250);

        ${BaseBtn} {
            border-color: ${({ theme }) => theme.text6};
            background-color: rgba(241, 246, 250);
            padding: 0;

            &:hover {
                opacity: 0.5;
            }

            span {
                margin-right: 10px;
                color: ${({ theme }) => theme.text1};
            }
            img {
                height: 16px;
                width: 16px;
            }
        }
    }
`
const VoteItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 368px;
    border: 1px solid #dcdcdc;
    background: #ffffff;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 400;
    color: ${({ theme }) => theme.text1};
`

const VoteBox = styled.div<{ isNotAllMotion: boolean }>`
    background-color: ${({ isNotAllMotion }) => (isNotAllMotion ? '#fff' : 'transparent')};
    margin: 100px auto 0;
    border-radius: 6px;
    padding-top: 40px;
    padding-bottom: 56px;
    width: 540px;
    /* height: 348px; */
    border: 1px solid ${({ isNotAllMotion }) => (isNotAllMotion ? 'transparent' : 'rgba(0,0,0,0.1)')};
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        margin-bottom: ${(props) => (!props.isNotAllMotion ? '39px' : 0)};
    }

    ${VoteItem} {
        &:hover {
            opacity: 0.5;
        }
        &.active {
            color: ${({ theme }) => theme.primary1};
            border-color: ${({ theme }) => theme.primary1};
        }
        &:nth-child(3) {
            margin-top: 24px;
            margin-bottom: 33px;
        }
    }
`

interface MotionProp {
    title: string
    content: string
    motionSponsor: string
    isNotPreview?: boolean
    motionProcess?: MotionProcess
    referendumAddress?: string
    isDemocraticMotion?: boolean
    summary?: string
    id?: string | number
}

enum VoteStatusEnum {
    FAVOR, // 赞成
    OPPOSE, // 反对
}

const StyledDemocraticOrNodeVote = styled.div`
    .form-item {
        margin-top: 32px;

        .label {
            height: 25px;
            font-size: 18px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            line-height: 21px;
            margin-bottom: 10px;
        }

        .user-info {
            background-color: #eef5f1;
            height: 58px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            padding-left: 14px;
            .user-account {
                margin-left: 12px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.9);
            }
        }
        input {
            width: 368px;
            height: 58px;
            background: #eef5f1;
            border-radius: 6px;
            padding: 10px 12px;
            font-size: 16px;
            line-height: 24px;
            color: ${({ theme }) => theme.text1};
            border: 1px solid transparent;

            &::placeholder {
                color: #b1bab5;
            }
            &:focus {
                border: 1px solid #24ba62;
            }
            transition: all 0.3s linear;
        }
        .user-balance {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            line-height: 24px;
        }
    }
    button {
        margin-top: 72px;
    }
`

const AllVote = ({ onVote }: { onVote: (num: string, votingLockId: string | number) => void }) => {
    const [num, setNum] = useState('')
    const [votingLockId, setVotingLockId] = useState<number | string>()
    const { account } = useWeb3React()
    const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
    const balance = userEthBalance?.toFixed(0) || 0

    const [votingLockOptinos, setVotingLockOptinos] = useState([])
    const getVotingLockConfig = async () => {
        const { data } = await ajaxGet('fetchVotingLockConfig')

        setVotingLockOptinos(
            data.map((item: any) => {
                const days = item.daysTolock / 86400
                const daysText = days ? `锁定期限（${days}天）` : '无锁定期'
                return {
                    value: item.stakingIndex,
                    label: `${item.voteMultiplier / 10000}倍投票余额，${daysText}`,
                }
            })
        )
    }
    useEffect(() => {
        getVotingLockConfig()
    }, [])

    return (
        <StyledDemocraticOrNodeVote>
            <div className="form-item">
                <div className="label">使用账户投票</div>
                <div className="user-info">
                    <Avatar src={avatar}></Avatar>
                    <span className="user-account">{shortenAddress(account as string)}</span>
                </div>
            </div>
            <div className="form-item">
                <div className="label">锁定余额</div>
                <Input value={num} onUserInput={setNum} placeholder="请输入你的投票数量"></Input>
                <div className="user-balance">钱包余额：{balance} GC</div>
            </div>
            <div className="form-item">
                <div className="label">投票锁定</div>
                <div className="form-content">
                    <StyledSelect
                        width="100%"
                        padding="14px"
                        background="#EEF5F1"
                        placeholder="请选择投票锁定期"
                        popupClassName="custom-select-dropdown"
                        options={votingLockOptinos}
                        onChange={(value) => setVotingLockId(value as number | string)}
                    />
                </div>
            </div>

            <ButtonPrimary
                width={'368px'}
                onClick={() => onVote(num, votingLockId as string | number)}
                disabled={!num || +num > +balance || votingLockId === undefined}
            >
                投票
            </ButtonPrimary>
        </StyledDemocraticOrNodeVote>
    )
}

const DemocraticOrNodeVote = ({ onVote }: { onVote: (num: string) => void }) => {
    const ALL_VOTE_STATUS = ['赞成', '反对']
    const [voteStatus, setVoteStatus] = useState<VoteStatusEnum>()

    return (
        <>
            {ALL_VOTE_STATUS.map((val, idx) => (
                <VoteItem key={val} className={voteStatus === idx ? 'active' : ''} onClick={() => setVoteStatus(idx)}>
                    {val}
                </VoteItem>
            ))}
            <ButtonPrimary
                width={'368px'}
                onClick={() => onVote(voteStatus === 0 ? '1' : '0')}
                disabled={typeof voteStatus === 'undefined'}
            >
                投票
            </ButtonPrimary>
        </>
    )
}

enum MotionProcess {
    ALL,
    DIRECTOR,
    NODE,
}
export default ({
    title,
    content,
    motionSponsor,
    isNotPreview = true,
    motionProcess = 1,
    referendumAddress,
    isDemocraticMotion,
    summary = '',
    id,
}: MotionProp) => {
    // const isDemocraticMotion = false // 是否民主提案
    // const motionProcess: MotionProcess = 1 // 提案进度 0 全民投票 1 理事投票 2 节点投票
    const contentRef = useRef<HTMLDivElement>(null)
    const MAX_CONTENT_HEIGHT = 312
    const [isInited, setIsInited] = useState(false)

    const history = useHistory()
    const { chainId } = useWeb3React()

    // 是否隐藏内容
    const [whetherHideContent, setWhetherHideContent] = useState(false)
    const [contentClass, setContentClass] = useState('')
    const [contentHeight, setContentHeight] = useState('auto')

    const handleClickShowMore = () => {
        setContentClass('show')
        setWhetherHideContent(false)
    }

    const [isOpen, setIsOpen] = useState(false)
    const [isOpen1, setIsOpen1] = useState(false)
    const [isDirectorModal, setIsDirectorModal] = useState(false)

    const [attemptingTxn, setAttemptingTxn] = useState(false)
    const [hash, setHash] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState('')
    const addTransaction = useTransactionAdder()
    const contract = useContract(GS_REFERENDUMS_FACTORY_ADDRESS[chainId as ChainId], ReferendumsFactor)
    const councillorsVotingContract = useGsCouncillorsVotingContract()
    const nodesVotingContract = useGsNodesVotingContract()

    const { isNode, isMP, getUserVotingInfo } = useGetUserVotingInfo()

    const onVote = useCallback(
        async (num: string, votingLockId?: string | number) => {
            if (motionProcess === MotionProcess.DIRECTOR && !isMP) {
                setIsDirectorModal(true)
                setIsOpen1(true)
                return
            }
            if (motionProcess === MotionProcess.NODE && !isNode) {
                setIsDirectorModal(false)
                setIsOpen1(true)
                return
            }
            if (!num) return
            if (motionProcess === MotionProcess.NODE && !nodesVotingContract) return
            if (motionProcess === MotionProcess.DIRECTOR && !councillorsVotingContract) return
            if (motionProcess === MotionProcess.ALL && !contract) return

            try {
                setAttemptingTxn(true)
                setIsOpen(true)

                let voteRes
                // 议员投票
                if (motionProcess === MotionProcess.DIRECTOR)
                    voteRes =
                        councillorsVotingContract && (await councillorsVotingContract[+num ? 'agree' : 'disagree'](id))

                // 节点投票
                if (motionProcess === MotionProcess.NODE)
                    voteRes = nodesVotingContract && (await nodesVotingContract[+num ? 'agree' : 'disagree'](id))

                // 全民投票
                if (motionProcess === MotionProcess.ALL)
                    voteRes =
                        contract &&
                        (await contract.agree(referendumAddress, votingLockId, { value: num && parseEther(num) }))

                const hash = voteRes.hash

                setAttemptingTxn(false)
                setHash(hash)
                addTransaction(
                    {
                        hash,
                    } as any,
                    {
                        summary: summary,
                    }
                )
            } catch (err: any) {
                setAttemptingTxn(false)
                setIsOpen(false)
                setErrorMessage(err?.data?.message || err?.message || '投票失败')
            }
        },
        [
            setAttemptingTxn,
            setIsOpen,
            setHash,
            addTransaction,
            isNode,
            isMP,
            contract,
            referendumAddress,
            councillorsVotingContract,
        ]
    )

    useEffect(() => {
        if (isNotPreview && contentRef.current && contentRef.current.clientHeight > MAX_CONTENT_HEIGHT) {
            setContentClass('hide')
            setContentHeight(contentRef.current.clientHeight + 'px')
            setWhetherHideContent(true)
        }
        setIsInited(true)
        if (isNotPreview) getUserVotingInfo()
    }, [])

    return (
        <>
            <StyledMotionDetail className={`motion-detail ${isInited ? 'inited' : ''}`}>
                <MotionTitle>{title}</MotionTitle>
                <MotionSponsor>
                    <span className="motion-tag">{isDemocraticMotion ? '民主提案' : '理事提案'}</span>
                    <span>提案者</span>
                    <span className="motion-sponsor">{motionSponsor}</span>
                </MotionSponsor>

                <MotionContentBox contentHeight={contentHeight}>
                    <div
                        ref={contentRef}
                        className={`motion-content ${contentClass}`}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                    {isNotPreview && whetherHideContent && (
                        <div className="base-btn-box">
                            <BaseBtn height={'48px'} width={'245px'} borderRadius={'6px'} onClick={handleClickShowMore}>
                                <span>显示更多</span>
                                <img src={ShowMoreImg}></img>
                            </BaseBtn>
                        </div>
                    )}
                </MotionContentBox>
                {isNotPreview && (
                    <VoteBox isNotAllMotion={!motionProcess}>
                        <h1>投出您的票</h1>
                        {motionProcess ? <DemocraticOrNodeVote onVote={onVote} /> : <AllVote onVote={onVote} />}
                    </VoteBox>
                )}
                {/* TODO 投票后提示 */}
                <TransactionConfirmationModal
                    isOpen={isOpen}
                    attemptingTxn={attemptingTxn}
                    hash={hash}
                    onDismiss={() => setIsOpen(false)}
                    content={() => <>1231</>}
                />
                <ModalConfirmed
                    isOpen={isOpen1}
                    onDismiss={() => setIsOpen1(false)}
                    onConfirm={() => (isDirectorModal ? history.push('/ToBeDirector') : setIsOpen1(false))}
                    confirmText={isDirectorModal ? '申请成为议员' : '确定'}
                >
                    <TYPE.subHeader>{`该功能仅对${
                        isDirectorModal ? '理事会议员' : '节点'
                    }开放 ，您暂时无法使用!`}</TYPE.subHeader>
                </ModalConfirmed>
            </StyledMotionDetail>

            <Modal isOpen={!!errorMessage} onDismiss={() => setErrorMessage('')} maxHeight={90}>
                <TransactionErrorContent message={errorMessage} onDismiss={() => setErrorMessage('')} />
            </Modal>
        </>
    )
}
