import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled'
import CheckOutlined from '@ant-design/icons/CheckOutlined'
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled'
import CloseOutlined from '@ant-design/icons/CloseOutlined'
import * as React from 'react'
import styled from 'styled-components'

import type { DirectionType } from './Line'
import Line from './Line'

const tuple = <T extends string[]>(...args: T) => args
const ProgressTypes = tuple('line', 'circle', 'dashboard')
export type ProgressType = typeof ProgressTypes[number]
const ProgressStatuses = tuple('normal', 'exception', 'active', 'success')
export type ProgressSize = 'default' | 'small'
export type StringGradients = { [percentage: string]: string }
type FromToGradients = { from: string; to: string }

const ProgressText = styled.div`
    height: 100%;
    margin-right: 6px;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    &.is-less-10 {
        position: absolute;
        margin: auto;
        left: 10%;
        top: 0;
        color: #000;
    }
`

export type ProgressGradient = { direction?: string } & (StringGradients | FromToGradients)

export interface SuccessProps {
    percent?: number
    /** @deprecated Use `percent` instead */
    progress?: number
    strokeColor?: string
}
function validProgress(progress: number | undefined) {
    if (!progress || progress < 0) {
        return 0
    }
    if (progress > 100) {
        return 100
    }
    return progress
}

function getSuccessPercent({
    success,
    successPercent,
}: {
    success?: {
        progress?: number
        percent?: number
    }
    successPercent?: number
}) {
    let percent = successPercent
    /** @deprecated Use `percent` instead */
    if (success && 'progress' in success) {
        percent = success.progress
    }
    if (success && 'percent' in success) {
        percent = success.percent
    }
    return percent
}

export interface ProgressProps {
    prefixCls?: string
    className?: string
    type?: ProgressType
    percent?: number
    total?: number
    pace?: number
    info?: string | number
    format?: (percent?: number, successPercent?: number) => React.ReactNode
    status?: typeof ProgressStatuses[number]
    showInfo?: boolean
    strokeWidth?: number
    strokeLinecap?: 'butt' | 'square' | 'round'
    strokeColor?: string | string[] | ProgressGradient
    trailColor?: string
    width?: number
    success?: SuccessProps
    style?: React.CSSProperties
    gapDegree?: number
    gapPosition?: 'top' | 'bottom' | 'left' | 'right'
    size?: ProgressSize
    steps?: number
    /** @deprecated Use `success` instead */
    successPercent?: number
    children?: React.ReactNode
}

const getProgressStatus = (props: ProgressProps) => {
    const { status } = props
    // if (!ProgressStatuses.includes(status!) && getPercentNumber() >= 100) {
    //     return 'success';
    // }
    return status || 'normal'
}

const renderProcessInfo = (
    props: ProgressProps & { prefixCls: string; progressStatus: typeof ProgressStatuses[number] }
) => {
    const {
        // className,
        // steps,
        percent = 0,
        // size = 'default',
        showInfo = true,
        type = 'line',
        pace = 0,
        total = 100,
        info,
        progressStatus,
        // ...restProps
    } = props
    const { format } = props
    const successPercent = getSuccessPercent(props)
    if (!showInfo) {
        return null
    }
    let text
    const textFormatter = format || ((percentNumber) => `${percentNumber}%`)
    const isLineType = type === 'line'
    if (format || (progressStatus !== 'exception' && progressStatus !== 'success')) {
        text = textFormatter(validProgress(percent), validProgress(successPercent))
    } else if (progressStatus === 'exception') {
        text = isLineType ? <CloseCircleFilled /> : <CloseOutlined />
    } else if (progressStatus === 'success') {
        text = isLineType ? <CheckCircleFilled /> : <CheckOutlined />
    }
    const isLess10 = validProgress((pace / total) * 100) <= 10
    return (
        <ProgressText className={`${isLess10 ? 'is-less-10' : ''}`} title={typeof text === 'string' ? text : undefined}>
            {info !== void 0 ? info : pace}
        </ProgressText>
    )
}

const Progress = (props: ProgressProps) => {
    const {
        prefixCls: customizePrefixCls,
        // className,
        // steps,
        strokeColor,
        // size = 'default',
        // ...restProps
    } = props

    // function getPercentNumber() {
    //     const successPercent = getSuccessPercent(props);
    //     return parseInt(
    //         successPercent !== undefined ? successPercent.toString() : percent.toString(),
    //         10,
    //     );
    // }

    // function renderProcessInfo() {

    // }

    const direction: DirectionType = 'ltr'
    const getPrefixCls = (suffixCls?: string, customizePrefixCls?: string) => {
        if (customizePrefixCls) return customizePrefixCls
        return suffixCls ? `zc-${suffixCls}` : 'zc'
    }
    const prefixCls = getPrefixCls('progress', customizePrefixCls)
    const progressStatus = getProgressStatus(props)
    const progressInfo = renderProcessInfo({ ...props, prefixCls, progressStatus })

    const strokeColorNotArray = Array.isArray(strokeColor) ? strokeColor[0] : strokeColor
    return (
        <Line {...props} strokeColor={strokeColorNotArray} prefixCls={prefixCls} direction={direction}>
            {progressInfo}
        </Line>
    )
}

export default Progress
