import InternalCard from './Card'
import GridCard from './Grid'

type InternalCardType = typeof InternalCard
export interface CardInterface extends InternalCardType {
    GridCard: typeof GridCard
}

const Card = InternalCard as CardInterface
Card.GridCard = GridCard

export default Card
