import { message, Pagination } from 'antd'
import { ajaxPost } from 'api/axios'
import NoDataImg from 'assets/img/no-data.png'
import Back from 'components/Back'
import useCopyClipboard from 'hooks/useCopyClipboard'
import useGetUserVotingInfo from 'hooks/useGetUserVotingInfo'
import useTable from 'hooks/useTable'
import Card from 'pages/Vote/components/Card/Card'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box, Flex, Image } from 'rebass'
import styled from 'styled-components'
import { TYPE } from 'theme'
import { shortenAddress } from 'utils'

interface Voter {
    img: string
    address: string
    num: number | string
}
interface RouteParams {
    address: string
}

const ScrolledBox = styled(Box)`
    &::-webkit-scrollbar {
        width: 8px;
    }
`
const LinkInfo = styled(Flex)`
    padding: 26px 25px 20px 28px;
    height: 152px;
    flex-direction: column;
    justify-content: space-between;
`

const VoterList = () => {
    const {
        paginationConfig,
        changePage,
        tableData: voters,
    } = useTable<any>({
        url: 'getCouncilVoterList',
        current: 1,
        pageSize: 10,
    })
    const AMMOUNT = 7
    return (
        <>
            {typeof paginationConfig.total === 'undefined' ||
                (paginationConfig.total ? (
                    voters.length <= AMMOUNT ? (
                        <Box width="100%" bg="#fff" style={{ borderRadius: '10px' }} padding="20px">
                            {voters.map((item, idx) => (
                                <Flex
                                    key={item.address}
                                    justifyContent="space-between"
                                    paddingBottom="4px"
                                    paddingRight="86px"
                                    marginTop={idx ? '20px' : '0'}
                                    style={{
                                        borderBottom: '1px solid rgba(0,0,0,0.05)',
                                    }}
                                >
                                    <Flex>
                                        <Image
                                            src={item.img}
                                            width="32px"
                                            height="32px"
                                            marginRight="12px"
                                            style={{
                                                borderRadius: '50%',
                                            }}
                                        ></Image>
                                        <TYPE.subHeader color="rgba(0,0,0,0.9)">{item.address}</TYPE.subHeader>
                                    </Flex>
                                    <TYPE.subHeader color="rgba(0,0,0,0.9)">支持数量：{item.voteNumber}</TYPE.subHeader>
                                </Flex>
                            ))}
                        </Box>
                    ) : (
                        <Flex>
                            {voters
                                .reduce<Voter[][]>((ret, item, idx) => {
                                    const _idx = Math.floor(idx / 7)
                                    !ret[_idx] && (ret[_idx] = [])
                                    ret[_idx].push(item)
                                    return ret
                                }, [])
                                .map((item, index) => (
                                    <Box
                                        key={index}
                                        width="419px"
                                        height="412px"
                                        bg="#fff"
                                        style={{ borderRadius: '10px' }}
                                        padding="20px"
                                        marginLeft={index ? '20px' : '0'}
                                    >
                                        {item.map((_item, idx) => (
                                            <Flex
                                                key={_item.address}
                                                justifyContent="space-between"
                                                paddingBottom="4px"
                                                paddingRight="9px"
                                                marginTop={idx ? '20px' : '0'}
                                                style={{
                                                    borderBottom: '1px solid rgba(0,0,0,0.05)',
                                                }}
                                            >
                                                <Flex>
                                                    <Image
                                                        src={_item.img}
                                                        width="32px"
                                                        height="32px"
                                                        marginRight="12px"
                                                        style={{
                                                            borderRadius: '50%',
                                                        }}
                                                    ></Image>
                                                    <TYPE.subHeader color="rgba(0,0,0,0.9)">
                                                        {_item.address}
                                                    </TYPE.subHeader>
                                                </Flex>
                                                <TYPE.subHeader color="rgba(0,0,0,0.9)">
                                                    支持数量：{_item.num}
                                                </TYPE.subHeader>
                                            </Flex>
                                        ))}
                                    </Box>
                                ))}
                        </Flex>
                    )
                ) : (
                    <Flex
                        bg="#fff"
                        flexDirection="column"
                        height="418px"
                        width="100%"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Image src={NoDataImg} width="160px" height="127px"></Image>
                        <TYPE.body color="#999f9c" marginTop="21px">
                            暂无投票信息
                        </TYPE.body>
                    </Flex>
                ))}
            <Pagination
                current={paginationConfig.current}
                total={paginationConfig.total}
                hideOnSinglePage
                pageSize={paginationConfig.pageSize}
                style={{ textAlign: 'right', marginTop: '10px' }}
                onChange={changePage}
            />
        </>
    )
}

export default () => {
    // const [voters, setVoters] = useState<Voter[]>([])
    // const [total] = useState<number>()
    const params = useParams<RouteParams>()
    const [councilInfo, setCouncliInfo] = useState({
        address: '',
        createTime: '',
        email: '',
        headUrl: '',
        introduction: '',
        modifyTime: '',
        nickName: '',
        twitte: '',
        voteNumber: 0,
        website: '',
    })

    const [, setCopied] = useCopyClipboard()
    const handleCopyClick = useCallback((text: string) => {
        setCopied(text)
        message.success('复制成功')
    }, [])

    const { isMP, getUserVotingInfo } = useGetUserVotingInfo()
    const requestGetCouncilDetail = useCallback(async () => {
        const res = await ajaxPost('getCouncilDetail', { address: params.address })
        if (res.code === '200') {
            setCouncliInfo(res.data)
        }
    }, [params.address])

    const history = useHistory()
    // 改为轮询
    useEffect(() => {
        if (typeof isMP === 'undefined') return
        if (isMP) {
            requestGetCouncilDetail()
            return
        }
        history.replace('/ToBeDirector')
        // const timer = setInterval(() => requestGetCouncilDetail(), 10000)
        // return () => clearInterval(timer)
    }, [isMP, history])
    useEffect(() => {
        getUserVotingInfo()
    }, [])

    return (
        <Box paddingBottom="20px">
            <Back />
            <TYPE.black fontSize="24px" margin="20px 0" padding="0" height="34px" lineHeight="34px">
                议员信息
            </TYPE.black>

            <Flex marginTop="20px" justifyContent="space-between">
                <Box width="370px">
                    <Card padding="0" headerStyle={{ inlineDivider: true }} bodyStyle={{ padding: '0' }}>
                        <Box
                            padding="30px 8px 20px"
                            style={{
                                borderBottom: '1px solid rgba(0,0,0,0.05)',
                            }}
                        >
                            <Flex flexDirection="column" alignItems="center">
                                <Image
                                    src={councilInfo.headUrl}
                                    width="48px"
                                    height="48px"
                                    style={{ borderRadius: '50%' }}
                                ></Image>
                                <TYPE.subHeader marginTop="16px" marginBottom="2px" height="20px" lineHeight="20px">
                                    {councilInfo.nickName}
                                </TYPE.subHeader>
                                <TYPE.body height="22px" lineHeight="22px">
                                    {councilInfo.address && shortenAddress(councilInfo.address)}
                                </TYPE.body>
                                <TYPE.body fontSize="18px" marginTop="26px" height="22px" lineHeight="22px">
                                    获得票数：{councilInfo.voteNumber}
                                </TYPE.body>
                            </Flex>
                        </Box>

                        <LinkInfo>
                            <Flex>
                                <TYPE.body width="65px" flexShrink={0} textAlign="left">
                                    Email：
                                </TYPE.body>
                                <TYPE.body
                                    isEllipsis
                                    color="#24BA62"
                                    onClick={() => handleCopyClick(councilInfo.email)}
                                >
                                    {councilInfo.email}
                                </TYPE.body>
                            </Flex>
                            <Flex>
                                <TYPE.body width="65px" flexShrink={0} textAlign="left">
                                    Twitter：
                                </TYPE.body>
                                <TYPE.body
                                    isEllipsis
                                    color="#24BA62"
                                    onClick={() => handleCopyClick(councilInfo.twitte)}
                                >
                                    {councilInfo.twitte}
                                </TYPE.body>
                            </Flex>
                            <Flex>
                                <TYPE.body width="65px" flexShrink={0} textAlign="left">
                                    网站：
                                </TYPE.body>
                                <TYPE.body
                                    isEllipsis
                                    color="#24BA62"
                                    onClick={() => handleCopyClick(councilInfo.website)}
                                >
                                    {councilInfo.website}
                                </TYPE.body>
                            </Flex>
                        </LinkInfo>
                    </Card>
                </Box>
                <Box width="890px">
                    <Card
                        padding="10px 20px 15px"
                        header={
                            <TYPE.black fontSize="16px" textAlign="left" height="43px" lineHeight="43px">
                                议员信息
                            </TYPE.black>
                        }
                        headerStyle={{ inlineDivider: true, width: '290px', padding: '0 0 0 10px' }}
                        bodyStyle={{ padding: '4px 0 0' }}
                    >
                        <ScrolledBox
                            overflowY="auto"
                            height="286px"
                            dangerouslySetInnerHTML={{
                                __html: `${councilInfo.introduction}`,
                            }}
                        />
                    </Card>
                </Box>
            </Flex>

            <TYPE.black fontSize="24px" margin="20px 0" padding="0" height="34px" lineHeight="34px">
                投票人
            </TYPE.black>
            {isMP && <VoterList></VoterList>}
        </Box>
    )
}
