import { Web3Provider } from '@ethersproject/providers'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import { ajaxPost } from 'api/axios'
import { ButtonSecondary } from 'components/Button'
import Loader from 'components/Loader'
import { useLoginStatus } from 'hooks'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'state'
import { setToken } from 'state/application/actions'
import styled, { ThemeContext } from 'styled-components'
import { shortenAddress } from 'utils'

import Avatar from '../../assets/img/avatar.png'
import { SIGN_DATA, SUPPORTED_WALLETS } from '../../constants'

export const ConnectWalletBtn = styled(ButtonSecondary)<{ isActive?: boolean }>`
    width: ${({ isActive }) => (isActive ? 'auto' : '150px')};
    height: 54px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    padding: 0 ${({ isActive }) => (isActive ? '5px' : '0')};
    border: 1px solid ${({ theme }) => theme.primary1};
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.primary1};
    line-height: 19px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    z-index: 2;
    overflow: hidden;
    .login-out {
        transition: all 0.2s ease-in-out 0.2s;
        opacity: 0;
        text-align: left;
    }
    &:hover {
        background: #f5fff9;
    }
`

const PopItem = styled.div`
    width: 158px;
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 16px;
    font-weight: 500;
    color: #24ba62;
    overflow: hidden;
    img {
        width: 18px;
        height: 18px;
    }
    span {
        flex: 1;
        margin-left: 6px;
    }
    .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }
`

const AccountInfo = ({ account }: { account: string }) => {
    return (
        <>
            <PopItem>
                <img className="avatar" src={Avatar} />
                <span>{account}</span>
                {/* <img src={Down} /> */}
            </PopItem>
            {/* <PopItem className='login-out'>
            <img src={LoginOut} />
            <span>退出登录</span>
        </PopItem> */}
        </>
    )
}

export default (): JSX.Element => {
    const { t } = useTranslation()
    const { active, account, activate, error } = useWeb3React()
    const token = useSelector<AppState>((state) => state.application.token)
    const { ethereum } = window
    const dispatch = useDispatch<AppDispatch>()
    const loginStatus = useLoginStatus()
    const theme = useContext(ThemeContext)
    const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>()

    const connectWallet = useCallback(async () => {
        if (active && account && loginStatus) return
        if (active && account && !loginStatus) {
            if (!ethereum) return
            const provider = new Web3Provider(ethereum)
            if (!provider) return
            try {
                const signer = provider.getSigner()
                const signature = await signer.signMessage(SIGN_DATA)

                const { data } = await ajaxPost('fetchToken', {
                    address: account.toLowerCase(),
                    signature,
                })
                localStorage.setItem('account', account)
                dispatch(setToken({ token: data }))
            } catch (error) {
                setPendingWallet(undefined)
                console.log(error)
            }
            return
        }
        const connector = SUPPORTED_WALLETS.INJECTED.connector
        if (connector) {
            setPendingWallet(connector)
            activate(connector, undefined, true).catch((error) => {
                if (error instanceof UnsupportedChainIdError) {
                    activate(connector)
                } else {
                    setPendingWallet(undefined)
                }
            })
        }
    }, [active, activate, account, token, dispatch, setPendingWallet, loginStatus])

    const getConnectBtnContent = () => {
        if (pendingWallet && !active)
            return (
                <>
                    <Loader stroke={theme.primary1} /> &nbsp; Initializing ...
                </>
            )
        if (error) return 'Wrong Network'

        if (active && account) {
            return loginStatus ? <AccountInfo account={shortenAddress(account)} /> : t('Sign in')
        } else {
            return t('Connect wallet')
        }
    }

    useEffect(() => {
        if (account && account !== localStorage.getItem('account')) {
            dispatch(setToken({ token: '', save: false }))
        }
    }, [account, dispatch])

    return (
        <ConnectWalletBtn altDisabledStyle onClick={connectWallet} isActive={active && loginStatus}>
            {getConnectBtnContent()}
        </ConnectWalletBtn>
    )
}
