import '@reach/dialog/styles.css'

import { DialogContent, DialogOverlay } from '@reach/dialog'
import Close from 'assets/img/close.png'
import Tip from 'assets/img/tip.png'
import { ButtonConfirmed, ButtonGray } from 'components/Button'
import { transparentize } from 'polished'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { animated, useSpring, useTransition } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import styled from 'styled-components'

const AnimatedDialogOverlay = animated(DialogOverlay)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDialogOverlay = styled(AnimatedDialogOverlay)`
    &[data-reach-dialog-overlay] {
        z-index: 11;
        background-color: transparent;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(123, 113, 128, 0.7);
    }
`

const AnimatedDialogContent = animated(DialogContent)
// destructure to not pass custom props to Dialog DOM element
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// minHeight, maxHeight, maxWidth, mobile, isOpen,
const StyledDialogContent = styled(({ ...rest }) => <AnimatedDialogContent {...rest} />).attrs({
    'aria-label': 'dialog',
})`
    &[data-reach-dialog-content] {
        margin: 0 0 2rem 0;
        border: 1px solid '#fff';
        background-color: '#fff';
        box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.95, theme.shadow1)};
        width: 50vw;
        padding: 20px;
        align-self: center;
        position: relative;
        max-width: ${({ maxwidth }) => maxwidth}px;
        min-height: ${({ minheight }) => (minheight ? minheight + 'vh' : 'auto')};
        max-height: ${({ maxheight }) => (maxheight ? maxheight + 'vh' : 'auto')};

        border-radius: 4px;
        ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 65vw;
      margin: 0;
    `}
        ${({ theme }) => theme.mediaWidth.upToSmall`
      width:  85vw;
      width: 90vw;
    `}
    }
`

const Title = styled.div`
    width: 100%;
    font-size: 0.4267rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.64rem;
    text-align: center;
    margin-bottom: 0.4267rem;
`

const CloseIcon = styled.div`
    position: absolute;
    right: 0;
    top: -38px;
    width: 22px;
    height: 22px;
    background: url(${Close}) center no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
`

export interface ModalProps {
    isOpen: boolean
    onDismiss: () => void
    minHeight?: number
    maxHeight?: number
    maxWidth?: number
    initialFocusRef?: React.RefObject<any>
    children?: React.ReactNode
    title?: string
    onConfirm?: () => void
    confirmText?: string
    cancelText?: string
    onClose?: () => void
}

export default function Modal({
    isOpen,
    onDismiss,
    minHeight,
    maxHeight = 50,
    maxWidth = 375,
    initialFocusRef,
    children,
    title,
    onClose,
}: ModalProps) {
    const fadeTransition = useTransition(isOpen, null, {
        config: { duration: 200 },
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })

    const [{ y }, set] = useSpring(() => ({ y: 0, config: { mass: 1, tension: 210, friction: 20 } }))
    const bind = useGesture({
        onDrag: (state) => {
            set({
                y: state.down ? state.movement[1] : 0,
            })
            if (state.movement[1] > 300 || (state.velocity > 3 && state.direction[1] > 0)) {
                onDismiss()
            }
        },
    })

    return (
        <>
            {fadeTransition.map(
                ({ item, key, props }) =>
                    item && (
                        <StyledDialogOverlay
                            key={key}
                            style={props}
                            onDismiss={onDismiss}
                            initialFocusRef={initialFocusRef}
                        >
                            <StyledDialogContent
                                {...(isMobile
                                    ? {
                                          ...bind(),
                                          style: {
                                              transform: y.interpolate(
                                                  (y) => `translateY(${(y as number) > 0 ? y : 0}px)`
                                              ),
                                          },
                                      }
                                    : {})}
                                aria-label="dialog content"
                                minheight={minHeight}
                                maxheight={maxHeight}
                                maxwidth={maxWidth}
                            >
                                {/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
                                {!initialFocusRef && isMobile ? <div tabIndex={1} /> : null}
                                <CloseIcon onClick={onClose || onDismiss} />
                                <Title>{title}</Title>
                                {children}
                            </StyledDialogContent>
                        </StyledDialogOverlay>
                    )
            )}
        </>
    )
}

const TitleTip = styled.div`
    font-size: 16px;
    font-family: Nunito Sans-SemiBold, Nunito Sans;
    font-weight: 600;
    color: #1d2129;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
`
const DialogBody = styled.div`
    padding-left: 34px;
`
const DialogFooter = styled.div`
    margin-top: 30px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
        margin-right: 10px;
        &:last-child {
            margin-right: 0px;
        }
    }
`

export const ModalConfirmed = ({
    children,
    confirmText = '确认',
    cancelText = '取消',
    onConfirm,
    ...rest
}: ModalProps) => {
    return (
        <Modal {...rest}>
            <TitleTip>
                <img src={Tip} alt="" />
                提示
            </TitleTip>
            <DialogBody>{children}</DialogBody>
            <DialogFooter>
                <ButtonGray
                    width={'fit-content'}
                    height={'32px'}
                    fontSize={'14px'}
                    borderRadius={'2px'}
                    onClick={() => rest.onDismiss()}
                >
                    {cancelText}
                </ButtonGray>
                <ButtonConfirmed
                    width={'fit-content'}
                    height={'32px'}
                    fontSize={'14px'}
                    borderRadius={'2px'}
                    onClick={() => onConfirm?.()}
                >
                    {confirmText}
                </ButtonConfirmed>
            </DialogFooter>
        </Modal>
    )
}
