// import { transparentize } from 'polished'
import React, { useMemo } from 'react'
import { Text, TextProps } from 'rebass'
import styled, {
    createGlobalStyle,
    css,
    DefaultTheme,
    ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components'

import { useIsDarkMode } from '../state/user/hooks'
import { Colors } from './styled'

export * from './components'

interface TypeTextProps extends TextProps {
    isEllipsis?: boolean
}

const MEDIA_WIDTHS = {
    upToExtraSmall: 500,
    upToSmall: 650,
    upToMedium: 960,
    upToSuitable: 1060,
    upToLarge: 1280,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
    (accumulator, size) => {
        ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
            @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
                ${css(a, b, c)}
            }
        `
        return accumulator
    },
    {}
) as any

const white = '#FFFFFF'
const black = 'rgba(0,0,0,0.9)'

export function colors(darkMode: boolean): Colors {
    return {
        // base
        white,
        black,

        // text
        text1: darkMode ? '#FFFFFF' : 'rgba(0,0,0,0.9)',
        text2: darkMode ? '#C3C5CB' : '#4E5969', // 灰色按钮字体颜色
        text3: darkMode ? '#6C7284' : '#F9AE3F', // 橙色按钮颜色
        text4: darkMode ? '#565A69' : '#C3C5CB',
        text5: darkMode ? '#2C2F36' : '#FC4141', // 红色按钮颜色
        text6: darkMode ? '#2C2F36' : '#999F9C', // 禁用text按钮颜色

        // backgrounds / greys
        bg1: darkMode ? '#212429' : '#FFFFFF',
        bg2: darkMode ? '#2C2F36' : '#F2F3F5', // 灰色按钮背景颜色
        bg3: darkMode ? '#40444F' : '#E1E1E1', // 按钮禁用颜色
        bg4: darkMode ? '#565A69' : '#CED0D9',
        bg5: darkMode ? '#6C7284' : '#888D9B',

        //specialty colors
        modalBG: darkMode ? 'rgba(0,0,0,.425)' : 'rgba(0,0,0,0.3)',
        advancedBG: darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.6)',

        //primary colors
        primary1: darkMode ? '#2172E5' : '#24BA62',
        primary2: darkMode ? '#3680E7' : '#24ba621a', // Secondary按钮背景颜色
        primary3: darkMode ? '#4D8FEA' : '#24ba6233', // Secondary按钮背景颜色
        primary4: darkMode ? '#376bad70' : '#FE9800', // 橙色按钮背景颜色
        primary5: darkMode ? '#153d6f70' : '#FDEAF1',

        // color text
        primaryText1: darkMode ? '#6da8ff' : '#24BA62',

        // secondary colors
        secondary1: darkMode ? '#2172E5' : '#ff007a',
        secondary2: darkMode ? '#17000b26' : '#F6DDE8',
        secondary3: darkMode ? '#17000b26' : '#FDEAF1',

        // other
        red1: '#FF6871',
        red2: '#F82D3A',
        green1: '#27AE60',
        yellow1: '#FFE270',
        yellow2: '#F3841E',

        // dont wanna forget these blue yet
        // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
        // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
    }
}

export function theme(darkMode: boolean): DefaultTheme {
    return {
        ...colors(darkMode),

        grids: {
            sm: 8,
            md: 12,
            lg: 24,
        },

        //shadows
        shadow1: darkMode ? 'rgba(0,0,0,0.9)' : '#2F80ED',

        // media queries
        mediaWidth: mediaWidthTemplates,

        // css snippets
        flexColumnNoWrap: css`
            display: flex;
            flex-flow: column nowrap;
        `,
        flexRowNoWrap: css`
            display: flex;
            flex-flow: row nowrap;
        `,
    }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
    const darkMode = useIsDarkMode()

    const themeObject = useMemo(() => theme(darkMode), [darkMode])

    return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors; isEllipsis?: boolean }>`
    color: ${({ color, theme }) => (theme as any)[color]};
    text-overflow: ${({ isEllipsis }) => (!!isEllipsis ? 'ellipsis' : '')};
    white-space: ${({ isEllipsis }) => (!!isEllipsis ? 'nowrap' : '')};
    overflow: ${({ isEllipsis }) => (!!isEllipsis ? 'hidden' : '')}; ;
`

export const TYPE = {
    main(props: TextProps): JSX.Element {
        return <TextWrapper fontWeight={500} color={'text2'} {...props} />
    },
    link(props: TextProps): JSX.Element {
        return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
    },
    black(props: TextProps): JSX.Element {
        return <TextWrapper fontWeight={500} color={'text1'} {...props} />
    },
    body(props: TypeTextProps): JSX.Element {
        return <TextWrapper fontWeight={400} fontSize={16} color={'#1D2129'} {...props} />
    },
    largeHeader(props: TextProps): JSX.Element {
        return <TextWrapper fontWeight={600} fontSize={24} {...props} />
    },
    mediumHeader(props: TextProps): JSX.Element {
        return <TextWrapper fontWeight={500} fontSize={20} {...props} />
    },
    subHeader(props: TextProps): JSX.Element {
        return <TextWrapper fontWeight={400} fontSize={14} {...props} />
    },
    blue(props: TextProps): JSX.Element {
        return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
    },
    yellow(props: TextProps): JSX.Element {
        return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
    },
    darkGray(props: TextProps): JSX.Element {
        return <TextWrapper fontWeight={500} color={'text3'} {...props} />
    },
    gray(props: TextProps): JSX.Element {
        return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
    },
    italic(props: TextProps): JSX.Element {
        return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
    },
    error({ error, ...props }: { error: boolean } & TextProps): JSX.Element {
        return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
    },
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: Inter, sans-serif ;
  letter-spacing: -0.018em;
  font-display: fallback;
  --antd-wave-shadow-color:#F5FFF9;
}

@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: Inter, sans-serif ;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  /* background-color: #F5F7F9; */
  /* background-color: ${({ theme }) => theme.bg2}; */
}

::-webkit-scrollbar{
  width:16px;

  background:#E4E8ED;
}
::-webkit-scrollbar-thumb{
  background:#D8DCE1;
  border-radius:8px;
}

body {
  position: relative;
  height: 100vh;
   /* background-position: 0 -30vh; */
  /* background: url('https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2F8%2F55402f62682e3.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1671154106&t=679d2f205da9f6d51ba361a71ecbfa90'); */
  /* background-color: red; */
}
.ant-table-tbody > tr > td {
  padding: 14px 16px !important;
}
.ant-table-container table > thead > tr th{
  padding: 9px 16px !important;
  background: #F2F3F5;
  color: rgba(0,0,0,0.9);
  font-size:14px ;
  font-weight: 600;
}
.ant-table{
  border-radius: 0 0 10px 10px;
}

.ant-pagination-item-active a{
  color: ${({ theme }) => theme.primary1};

}
.ant-pagination-item-active{
  border-color: ${({ theme }) => theme.primary1};
}
.ant-pagination-item{
  &:hover{
    border-color: ${({ theme }) => theme.primary1};
    a {
      color: ${({ theme }) => theme.primary1};
    }
  }
}
.ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link{
  &:disabled{
    &:hover{
      /* border-color: auto; */
    }
  }
  &:hover{
    border-color: ${({ theme }) => theme.primary1};
    span {
      color: ${({ theme }) => theme.primary1};
    }
  }
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link{
  border-color: #d9d9d9 !important;
  &:hover{
    span{
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }
}
.ant-table-cell{
  &::before{
    content:none !important;
  }
}
.ant-switch-checked{
  background-color: ${({ theme }) => theme.primary1};
}
`
