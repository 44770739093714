const REQUEST: {
    [key: string]: string
} = {
    getPreSaleStage: '/app/common/getPreSaleStage',
    registerUser: '/app/common/registerUser',
    getUserInfoByAddress: '/app/common/getUserInfoByAddress',
    getNodeInfo: '/app/common/getNodeInfo',
    getPushInfo: '/app/common/getPushInfo',
    getRecommendBuyRecord: '/app/common/getRecommendBuyRecord',
    getBuyRecord: '/app/common/getBuyRecord',
    fetchToken: 'gs/sso/auth/getToken',
    fetchProposalList: '/gs/finances/getNodeList',
    doAddProposal: '/gs/finances/addProposal',
    myProposal: '/gs/finances/myProposal',
    getProposalList: '/gs/finances/getProposalList',
    getCouncilList: '/gs/finances/getCouncilList',
    getNodeList: '/gs/finances/getNodeList',
    getProposalById: '/gs/finances/getProposalById', // 查询全民提案详情
    getCouncilById: '/gs/finances/getCouncilById', // 查询理事会提案详情
    getNodeById: '/gs/finances/getNodeById', //查询节点提案详情
    getVoteAddressList: '/gs/finances/getVoteAddressList', //全民投票地址记录列表
    getCouncilVoteAddressList: '/gs/finances/getCouncilVoteAddressList', // 理事会投票地址记录列表
    getNodeVoteAddressList: '/gs/finances/getNodeVoteAddressList', //节点投票地址记录列表
    getCouncilVoterList: '/gs/council/getCouncilVoterList', //投票地址列表
    doPublishMotion: '/gs/finances/addProposal',
    doUploadFile: '/common/uploadFile',
    doUploadImg: '/common/uploadImg',
    doApplyCouncil: '/gs/council/applyCouncil',
    fetchAddressPosition: '/gs/council/voter/address',
    getCouncilInfo: '/gs/council/info', // 首页-理事会信息
    getTopMembers: '/gs/council/getTopMembers', // 首页-获取当选、候选地址
    getCouncilDetail: '/gs/council/getCouncilInfo', // 查询理事会议员信息
    myProposalList: '/gs/finances/myProposalList', // 全民投票列表-我的提案
    myCouncilList: '/gs/finances/myCouncilList', // 理事会投票列表-我的提案
    myNodeList: '/gs/finances/myNodeList', // 节点投票列表-我的提案
    fetchCouncilDraft: '/gs/council/getCouncilAddress', // 获取用户理事会草稿箱数据
    fetchVotingLockConfig: '/gs/finances/getStakingLockAdminList', // 质押锁定管理接口
}
export default REQUEST
