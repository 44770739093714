import { useWeb3React } from '@web3-react/core'
import { ajaxPost } from 'api/axios'
import { useCallback, useState } from 'react'

/** 用户角色 */
enum UserRole {
    /** 普通人 */ GENERAL,
    /** 当选议员 */ MP,
    /** 当选节点 */ NODE,
    /** 当选议员&当选节点 */ BOTH,
}

/** 用户投票信息 */
interface UserVotingInfo {
    address: string
    /** 全民提案数 */ voterNumber: number
    /** 理事会提案数 */ councilVoterNumber: number
    /** 节点提案数 */ nodeVoterNumber: number
    /** 待领取申领金额 */ applyAmount: number
    /** 待领取提案金 */ proposalAmount: number
    /** 用户角色 */ type: UserRole
}
export default () => {
    const [userVotingInfo, setUserVotingInfo] = useState<UserVotingInfo | null>(null)
    const [isMP, setisMP] = useState<boolean>()
    const [isNode, setIsNode] = useState<boolean>()

    const { account } = useWeb3React()

    const getUserVotingInfo = useCallback(async () => {
        if (!account) return
        const { data }: { data: UserVotingInfo } = await ajaxPost('myProposal', { address: account })
        setUserVotingInfo(data)
        setIsNode([UserRole.NODE, UserRole.BOTH].includes(data.type))
        setisMP([UserRole.MP, UserRole.BOTH].includes(data.type))
    }, [account, setisMP, setIsNode, setUserVotingInfo])

    return {
        isMP,
        isNode,
        userVotingInfo,
        getUserVotingInfo,
    }
}
