import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import styled from 'styled-components'
// import Icon from '@ant-design/icons';
// import { SVGCom } from '../../assets/svg'

const MenuWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 100px;
    z-index: 10;
    ${({ theme }) => theme.mediaWidth.upToSuitable`
        display: none;
    `};
`
const MenuItem = styled.div<{ isActive: boolean }>`
    padding: 10px 18px;
    border-radius: 8px;
    color: ${({ isActive, theme }) => (isActive ? theme.primary1 : theme.black)};
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s linear;
    margin-right: 32px;
    background: transparent;
    svg {
        transition: all 0.2s linear;
    }

    &:hover {
        padding: 10px 18px;
        background: #ecf0ef;
        color: ${({ theme }) => theme.primary1};
        fill: ${({ theme }) => theme.primary1};
        svg {
            transform: scale(1.1);
        }
    }
`

// const CloseIcon = styled(Icon)`
//     cursor: pointer;
// `

export default (): JSX.Element => {
    const [activeIndex, setActiveIndex] = useState(0)
    // const theme = useContext(ThemeContext)
    const history = useHistory()
    const MenuList = useMemo(
        () => [
            {
                name: '概述',
                path: '/Overview',
                icon: 'OverviewSVG',
                activePath: ['/Overview'],
            },
            {
                name: '投票',
                path: '/Vote',
                icon: 'VoteSVG',
                activePath: ['/Vote', '/MyProposal/:type?', '/DoVote/:type/:id'],
            },
            {
                name: '理事会',
                path: '/Council',
                icon: 'CouncilSVG',
                activePath: ['/Council', '/DirectorInfo/:address'],
            },
        ],
        []
    )

    const handleItem = useCallback(
        (index) => {
            if (index === activeIndex) return
            setActiveIndex(index)
            const { path } = MenuList[index]
            if (path) return history.push(path)
        },
        [MenuList, setActiveIndex, history, activeIndex]
    )

    useEffect(() => {
        setActiveIndex(
            MenuList.findIndex((item) => {
                const res = item.activePath.findIndex((activePath) => {
                    return matchPath(history.location.pathname, {
                        path: activePath,
                        exact: true,
                    })
                })
                return res !== -1
            })
        )
    }, [setActiveIndex, MenuList, history.location])
    return (
        <MenuWrapper>
            {MenuList.map((item, index) => (
                <MenuItem isActive={activeIndex === index} key={item.path} onClick={() => handleItem(index)}>
                    {/* <CloseIcon component={SVGCom({ src: item.icon }) as any} style={{ fill: activeIndex === index ? theme.primary1 : '' }} /> */}
                    <span>{item.name}</span>
                </MenuItem>
            ))}
        </MenuWrapper>
    )
}
