import { ChainId } from 'gsswap_sdk'
import React, { useContext } from 'react'
import { AlertTriangle, ArrowUpCircle } from 'react-feather'
import { Text } from 'rebass'
import styled, { ThemeContext } from 'styled-components'

import Circle from '../../assets/img/loading.png'
import { useActiveWeb3React } from '../../hooks'
import { useViewText } from '../../hooks/useWNATIVE'
import { ExternalLink } from '../../theme'
import { CloseIcon, Spinner } from '../../theme/components'
import { getEtherscanLink } from '../../utils'
import { ButtonPrimary } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import Modal from '../Modal'
import { RowBetween } from '../Row'

const Wrapper = styled.div`
    width: 100%;
`
const Section = styled(AutoColumn)`
    padding: 34px 0;
`

const BottomSection = styled(Section)`
    background-color: ${({ theme }) => theme.bg2};
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`

const ConfirmedIcon = styled(ColumnCenter)`
    /* padding: 60px 0; */
    margin-bottom: 12px;
`

const CustomLightSpinner = styled(Spinner)<{ size: string }>`
    height: ${({ size }) => size};
    width: ${({ size }) => size};
`

function ConfirmationPendingContent() {
    const theme = useContext(ThemeContext)
    return (
        <Wrapper>
            <Section>
                <ConfirmedIcon>
                    <CustomLightSpinner src={Circle} alt="loader" size={'56px'} />
                </ConfirmedIcon>
                <AutoColumn gap="12px" justify={'center'}>
                    <Text fontWeight={500} fontSize={16} color={theme.primary1}>
                        加载中
                    </Text>
                    {/* <AutoColumn gap="12px" justify={'center'}>
                        <Text fontWeight={600} fontSize={14} color="" textAlign="center">
                            {pendingText}
                        </Text>
                    </AutoColumn> */}
                    <Text fontSize={16} color={theme.primary1} textAlign="center" marginTop={48}>
                        在你的钱包中确认这笔交易
                    </Text>
                </AutoColumn>
            </Section>
        </Wrapper>
    )
}

function TransactionSubmittedContent({
    onDismiss,
    chainId,
    hash,
}: {
    onDismiss: () => void
    hash: string | undefined
    chainId: ChainId
}) {
    const theme = useContext(ThemeContext)
    const viewText = useViewText()
    return (
        <Wrapper>
            <Section>
                <ConfirmedIcon>
                    <ArrowUpCircle strokeWidth={0.5} size={90} color={theme.primary1} />
                </ConfirmedIcon>
                <AutoColumn gap="12px" justify={'center'}>
                    <Text fontWeight={500} fontSize={20}>
                        Transaction Submitted
                    </Text>

                    {chainId && hash && (
                        <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>
                            <Text fontWeight={500} fontSize={14} color={theme.primary1}>
                                {viewText}
                            </Text>
                        </ExternalLink>
                    )}
                    <ButtonPrimary onClick={onDismiss} style={{ margin: '20px 0 0 0' }}>
                        <Text fontWeight={500} fontSize={20}>
                            Close
                        </Text>
                    </ButtonPrimary>
                </AutoColumn>
            </Section>
        </Wrapper>
    )
}

export function ConfirmationModalContent({
    title,
    bottomContent,
    onDismiss,
    topContent,
}: {
    title: string
    onDismiss: () => void
    topContent: () => React.ReactNode
    bottomContent: () => React.ReactNode
}) {
    return (
        <Wrapper>
            <Section>
                <RowBetween>
                    <Text fontWeight={500} fontSize={20}>
                        {title}
                    </Text>
                    <CloseIcon onClick={onDismiss} />
                </RowBetween>
                {topContent()}
            </Section>
            <BottomSection gap="12px">{bottomContent()}</BottomSection>
        </Wrapper>
    )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
    const theme = useContext(ThemeContext)
    return (
        <Wrapper>
            <Section>
                <RowBetween>
                    <Text fontWeight={500} fontSize={20}>
                        Error
                    </Text>
                    {/* <CloseIcon onClick={onDismiss} /> */}
                </RowBetween>
                <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
                    <AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} />
                    <Text
                        fontWeight={500}
                        fontSize={16}
                        color={theme.red1}
                        style={{ textAlign: 'center', width: '85%', wordBreak: 'break-all' }}
                    >
                        {message}
                    </Text>
                </AutoColumn>
            </Section>
            <BottomSection gap="12px">
                <ButtonPrimary onClick={onDismiss}>取消</ButtonPrimary>
            </BottomSection>
        </Wrapper>
    )
}

interface ConfirmationModalProps {
    isOpen: boolean
    onDismiss: () => void
    hash: string | undefined
    content: () => React.ReactNode
    attemptingTxn: boolean
}

export default function TransactionConfirmationModal({
    isOpen,
    onDismiss,
    attemptingTxn,
    hash,
    content,
}: ConfirmationModalProps) {
    const { chainId } = useActiveWeb3React()

    if (!chainId) return null
    return (
        <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
            {attemptingTxn ? (
                <ConfirmationPendingContent />
            ) : hash ? (
                <TransactionSubmittedContent chainId={chainId} hash={hash} onDismiss={onDismiss} />
            ) : (
                content()
            )}
        </Modal>
    )
}
