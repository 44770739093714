import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { PortisConnector } from '@web3-react/portis-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { ChainId } from 'gsswap_sdk'

import { FortmaticConnector } from './Fortmatic'
import { NetworkConnector } from './NetworkConnector'

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL
const FORMATIC_KEY = process.env.REACT_APP_FORTMATIC_KEY
const PORTIS_ID = process.env.REACT_APP_PORTIS_ID

export const NETWORK_CHAIN_ID: number = parseInt(process.env.REACT_APP_CHAIN_ID ?? '1')

if (typeof NETWORK_URL === 'undefined') {
    throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`)
}

const RPC = {
    [ChainId.MAINNET]: 'https://bsc-dataseed1.defibit.io',
    [ChainId.BSCTESTNET]: 'https://data-seed-prebsc-1-s1.binance.org:8545',
    // [ChainId.OKEX]: 'https://exchainrpc.okex.org',
    // [ChainId.OKEX_TESTNET]: 'https://exchaintestrpc.okex.org',
    [ChainId.GSTESTNET]: 'https://testnet.genesischain.io',
}

export const network = new NetworkConnector({
    urls: RPC || { [NETWORK_CHAIN_ID]: NETWORK_URL },
    defaultChainId: 1281,
})

let networkLibrary: Web3Provider | undefined
export function getNetworkLibrary(): Web3Provider {
    return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any))
}

export const injected = new InjectedConnector({
    supportedChainIds: [1281],
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
    rpc: { [ChainId.BSCTESTNET]: RPC[ChainId.BSCTESTNET] },
    bridge: 'https://bridge.walletconnect.org',
    qrcode: true,
    // pollingInterval: 15000
})

// mainnet only
export const fortmatic = new FortmaticConnector({
    apiKey: FORMATIC_KEY ?? '',
    chainId: 1,
})

// mainnet only
export const portis = new PortisConnector({
    dAppId: PORTIS_ID ?? '',
    networks: [1],
})

// mainnet only
export const walletlink = new WalletLinkConnector({
    url: RPC[ChainId.BSCTESTNET] || NETWORK_URL,
    appName: 'Uniswap',
    appLogoUrl:
        'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg',
})
