import { useWeb3React } from '@web3-react/core'
import ConnectWallet from 'components/ConnectWallet'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { ThemeContext } from 'styled-components'

import { network } from '../../connectors'
import { NetworkContextName } from '../../constants'
import { useEagerConnect, useInactiveListener, useLoginStatus } from '../../hooks'
import Loader from '../Loader'

const MessageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20rem;
`

const Message = styled.h2`
    color: ${({ theme }) => theme.secondary1};
`

const ChildWrapper = styled.div<{ isActive: boolean }>`
    width: 1280px;
    position: relative;
    z-index: 1;
    margin: 0 auto;
    height: 100%;
    /* overflow-x:auto; */
    flex: 1;
    /* height: 100%; */
    /* margin-bottom:20px ; */
    display: ${({ isActive }) => (isActive ? '' : 'flex')};
    align-items: center;
    justify-content: center;
    background: ${({ isActive }) => (isActive ? '' : '#fff')};
    border-radius: 10px;
`

export default function Web3ReactManager({ children }: { children: JSX.Element }): JSX.Element | null {
    const { t } = useTranslation()
    const { active } = useWeb3React()
    const theme = useContext(ThemeContext)
    const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React(NetworkContextName)
    const loginStatus = useLoginStatus()
    // try to eagerly connect to an injected provider, if it exists and has granted access already
    const triedEager = useEagerConnect()
    // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
    useEffect(() => {
        if (triedEager && !networkActive && !networkError && !active) {
            activateNetwork(network)
        }
    }, [triedEager, networkActive, networkError, activateNetwork, active])

    // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
    useInactiveListener(!triedEager)

    // handle delayed loader state
    // const [showLoader, setShowLoader] = useState(false)
    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         setShowLoader(true)
    //     }, 600)

    //     return () => {
    //         clearTimeout(timeout)
    //     }
    // }, [])

    // on page load, do nothing until we've tried to connect to the injected connector
    // if (!triedEager) {
    //   return null
    // }
    // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
    if (!active && networkError) {
        return (
            <MessageWrapper>
                <Message>{t('unknownError')}</Message>
            </MessageWrapper>
        )
    }

    // if neither context is active, spin
    // if (!active && !networkActive) {
    //     return showLoader ? (
    //         <MessageWrapper>
    //             <Loader />
    //         </MessageWrapper>
    //     ) : null
    // }

    return (
        <ChildWrapper isActive={active && loginStatus}>
            {!triedEager ? (
                <Loader stroke={theme.primary1} size="30px" />
            ) : !active || !loginStatus ? (
                <ConnectWallet />
            ) : (
                children
            )}
        </ChildWrapper>
    )
}
