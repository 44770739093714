import { TokenInfo } from '@uniswap/token-lists'

// the Uniswap Default token list lives here
export const DEFAULT_TOKEN_LIST_URL = 'tokens.uniswap.eth'

export const DEFAULT_LIST_OF_LISTS: string[] = [
    // DEFAULT_TOKEN_LIST_URL,
    // 't2crtokens.eth', // kleros
    // 'tokens.1inch.eth', // 1inch
    // 'synths.snx.eth',
    // 'tokenlist.dharma.eth',
    // 'defi.cmc.eth',
    // 'erc20.cmc.eth',
    // 'stablecoin.cmc.eth',
    // 'tokenlist.zerion.eth',
    // 'tokenlist.aave.eth',
    // 'https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json',
    // 'https://app.tryroll.com/tokens.json',
    'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json',
    // 'https://defiprime.com/defiprime.tokenlist.json',
    // 'https://umaproject.org/uma.tokenlist.json'
]

export const DEFAULT_CHAINID_TOKEN_LIST: TokenInfo[] = [
    {
        name: 'GS Token',
        symbol: 'GS',
        address: '0x7b13a4e44BD36d8B222193427Ea06A3cd54de1f9',
        chainId: 1281,
        decimals: 18,
        logoURI: 'https://exchange.pancakeswap.finance/images/coins/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png',
    },
]
