import './select-dropdown.css'

import { Select } from 'antd'
import styled from 'styled-components'

export default styled(Select)<{ width?: string; background?: string; padding?: string }>`
    width: ${(props) => props.width || 'auto'};
    /* background-color: ${(props) => props.background || '#fff'}; */
    .ant-select-selector {
        padding: 0 ${(props) => props.padding || '28px'} !important;
        height: 58px !important;
        border-color: transparent !important;
        border-radius: 6px !important;
        font-size: 16px;
        font-weight: 400;
        background-color: ${(props) => props.background || '#fff'} !important;
        .ant-select-selection-placeholder,
        .ant-select-selection-item {
            line-height: 56px !important;
        }
        .ant-select-selection-placeholder {
            color: #999f9c;
        }
        .ant-select-selection-item {
            color: rgba(0, 0, 0, 0.9);
        }
    }
    &:hover,
    &.ant-select-focused {
        .ant-select-selector {
            border-color: #24ba62 !important;
            box-shadow: none !important;
        }
    }
    .ant-select-arrow {
        right: 28px;
        color: #000;
    }
`
