import BackIconPng from 'assets/img/back.png'
import React from 'react'
// import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const BackWrapper = styled.div`
    height: 48px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 48px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
`

const BackIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 10px;
`

export default ({ beforeBack = () => window.history.go(-1) }: { beforeBack?: () => void }): JSX.Element => {
    // const history = useHistory();

    return (
        <BackWrapper onClick={beforeBack}>
            <BackIcon src={BackIconPng} /> 返回
        </BackWrapper>
    )
}
