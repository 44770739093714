import type { ReactNode } from 'react'
import React from 'react'
import styled from 'styled-components'

import type { ICardProps } from './Card'
import Card from './Card'

const GridCard = styled(Card)`
    .zc-card__body {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
    }
`
const GridCardItem = styled.div<{ width?: string }>`
    width: ${({ width }) => width || 'auto'};
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    padding: 30px 0;
    &.has-bottom-border {
        border-bottom: 1px #f2f3f5 solid;
    }
`
const GridCardItemContent = styled.div<{ width?: string }>`
    width: 100%;
    &.has-right-border {
        border-right: 1px #f2f3f5 solid;
    }
`
const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.6);
`
const Title = styled.span`
    margin-right: 5px;
    font-size: 16px;
    font-weight: 600;
`
const Body = styled.div<{ color?: string }>`
    margin-top: 32px;
    color: ${({ color }) => color || 'rgba(0, 0, 0, 0.90)'};
    font-weight: 600;
    font-size: 28px;
`
const Footer = styled.div``
const HeaderTip = styled.div`
    display: flex;
    align-items: center;
`

interface ICardItemProps {
    title?: ReactNode
    tip?: ReactNode
    body?: ReactNode
    footer?: ReactNode
    children?: ReactNode
    headerStyle?: React.HTMLAttributes<HTMLDivElement>
    bodyStyle?: React.HTMLAttributes<HTMLDivElement>
    footerStyle?: React.HTMLAttributes<HTMLDivElement>
}
type IProps = Omit<ICardProps, 'list'> & {
    list?: ICardItemProps[]
    size?: number
    header?: (item: ICardItemProps) => ReactNode
    body?: (item: ICardItemProps) => ReactNode
    footer?: (item: ICardItemProps) => ReactNode
    headerTip?: (item: ICardItemProps) => ReactNode
    headerStyle?: React.HTMLAttributes<HTMLDivElement>
    bodyStyle?: React.HTMLAttributes<HTMLDivElement>
    footerStyle?: React.HTMLAttributes<HTMLDivElement>
}

export default function ({
    list,
    header: headerFC,
    body: bodyFC,
    footer: footerFC,
    headerTip,
    size,
    headerStyle,
    bodyStyle,
    footerStyle,
    ...style
}: IProps) {
    list = list || []
    const columnSize = size || 4
    return (
        <GridCard className="zc-grid-card" {...style}>
            {list.map((cardItem, index) =>
                cardItem.children ? (
                    cardItem.children
                ) : (
                    <GridCardItem
                        className={`${
                            index <= (list as ICardItemProps[]).length - columnSize ? 'has-bottom-border' : ''
                        }`}
                        key={index}
                        width={`${100 / columnSize}%`}
                    >
                        <GridCardItemContent className={`${(index + 1) % columnSize ? 'has-right-border' : ''}`}>
                            <Header style={{ ...(cardItem.headerStyle || headerStyle) }}>
                                {headerFC ? (
                                    headerFC(cardItem)
                                ) : (
                                    <>
                                        <Title>{cardItem.title}</Title>
                                        {headerTip ? <HeaderTip>{headerTip(cardItem)}</HeaderTip> : <></>}
                                    </>
                                )}
                            </Header>
                            <Body style={{ ...(cardItem.bodyStyle || bodyStyle) }}>
                                {bodyFC ? bodyFC(cardItem) : cardItem.body}
                            </Body>
                            <Footer style={{ ...(cardItem.footerStyle || footerStyle) }}>
                                {footerFC ? footerFC(cardItem) : cardItem.footer}
                            </Footer>
                        </GridCardItemContent>
                    </GridCardItem>
                )
            )}
        </GridCard>
    )
}
