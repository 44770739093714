import React from 'react'

export const CouncilSVG = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path
                id="Vector"
                d="M13 17V20H18V22H6V20H11V17H4C3.73478 17 3.48043 16.8946 3.29289 16.7071C3.10536 16.5196 3 16.2652 3 16V4H2V2H22V4H21V16C21 16.2652 20.8946 16.5196 20.7071 16.7071C20.5196 16.8946 20.2652 17 20 17H13ZM10 6V13L15 9.5L10 6Z"
                fillOpacity="0.9"
            />
        </g>
    </svg>
)

export const OverviewSVG = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path
                id="Vector"
                d="M11 2.04997V13H21.95C21.449 18.053 17.185 22 12 22C6.477 22 2 17.523 2 12C2 6.81497 5.947 2.55097 11 2.04997ZM13 0.542969C18.553 1.01997 22.979 5.44697 23.457 11H13V0.542969Z"
            />
        </g>
    </svg>
)

export const VoteSVG = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path
                id="Vector"
                d="M21 3C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V9.5C21.337 9.5 20.7011 9.76339 20.2322 10.2322C19.7634 10.7011 19.5 11.337 19.5 12C19.5 12.663 19.7634 13.2989 20.2322 13.7678C20.7011 14.2366 21.337 14.5 22 14.5V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V14.5C2.66304 14.5 3.29893 14.2366 3.76777 13.7678C4.23661 13.2989 4.5 12.663 4.5 12C4.5 11.337 4.23661 10.7011 3.76777 10.2322C3.29893 9.76339 2.66304 9.5 2 9.5V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21Z"
                fillOpacity="0.9"
            />
        </g>
    </svg>
)
export const InfoCircleSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="info-circle">
            <g id="Union">
                <path d="M7.50562 11.9998V6.5H8.50562V11.9998H7.50562Z" fill="#0080D0" />
                <path d="M8.59985 4H7.3999V5.19995H8.59985V4Z" fill="#0080D0" />
                <path
                    d="M1 8C1 11.866 4.13403 15 8 15C11.866 15 15 11.866 15 8C15 4.13403 11.866 1 8 1C4.13403 1 1 4.13403 1 8ZM2 8C2 4.68628 4.68628 2 8 2C11.3137 2 14 4.68628 14 8C14 11.3137 11.3137 14 8 14C4.68628 14 2 11.3137 2 8Z"
                    fill="#0080D0"
                />
            </g>
        </g>
    </svg>
)
export const ShareSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame">
            <path
                id="Vector"
                d="M9.33333 2H14V6.66667"
                stroke="#999F9C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                id="Vector_2"
                d="M14 9.82457V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3C2 2.44772 2.44772 2 3 2H6"
                stroke="#999F9C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                id="Vector_3"
                d="M8.59993 7.39993L13.6999 2.29993"
                stroke="#999F9C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
)
export const CircleSvg = () => (
    <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            id="Shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 10C8.26142 10 10.5 7.76142 10.5 5C10.5 2.23858 8.26142 0 5.5 0C2.73858 0 0.5 2.23858 0.5 5C0.5 7.76142 2.73858 10 5.5 10Z"
            fill="#24BA62"
        />
    </svg>
)
export const MoreSvg = () => (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="&#230;&#155;&#180;&#229;&#164;&#154; 1" clipPath="url(#clip0_649_69962)">
            <path
                id="Vector"
                d="M8.22661 15.9292C8.30021 15.9996 8.39941 16.0396 8.50181 16.0396C8.60421 16.0396 8.70341 15.9996 8.77701 15.9292M8.77861 15.9292L16.1866 8.83484C16.513 8.52284 16.4922 8.00444 16.1674 7.69404C15.8426 7.38364 15.3178 7.38204 14.993 7.69244L8.50341 13.9004L2.01541 7.69244C1.68901 7.38044 1.16421 7.38364 0.841014 7.69404C0.514614 8.00284 0.493814 8.52284 0.820214 8.83324L8.22821 15.9276H8.77861V15.9292Z"
                fill="#999F9C"
            />
            <path
                id="Vector_2"
                d="M8.22159 10.4248C8.29519 10.4952 8.39439 10.5336 8.49679 10.5336C8.59919 10.5336 8.69839 10.4936 8.77199 10.4248M8.77359 10.4248L16.1816 3.33039C16.508 3.01839 16.4872 2.49999 16.1624 2.18959C15.8376 1.87919 15.3128 1.87759 14.988 2.18799L8.49839 8.39599L2.00879 2.18799C1.68239 1.87599 1.15759 1.87919 0.834387 2.18959C0.509587 2.49999 0.488787 3.01999 0.815187 3.33039L8.22319 10.4248H8.77359V10.4248Z"
                fill="#999F9C"
            />
        </g>
        <defs>
            <clipPath id="clip0_649_69962">
                <rect width="16" height="16" fill="white" transform="translate(0.5 0.99707)" />
            </clipPath>
        </defs>
    </svg>
)
export const ApproveSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="&#232;&#181;&#158;&#230;&#136;&#144;">
            <path
                id="Vector"
                d="M11.5 7.75001V4.75001C11.5 3.50736 10.4927 2.5 9.25 2.5L6.25 9.25001V17.5H14.965C15.713 17.5085 16.3529 16.9646 16.465 16.225L17.5 9.47501C17.566 9.03989 17.4377 8.59772 17.1489 8.26564C16.8601 7.93355 16.4401 7.74501 16 7.75001H11.5Z"
                stroke="black"
                strokeOpacity="0.9"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                id="Vector_2"
                d="M6.25 9.16675H4.2475C3.36888 9.15121 2.61779 9.879 2.5 10.7498V15.9998C2.61779 16.8706 3.36888 17.5153 4.2475 17.4998H6.25V9.16675Z"
                stroke="black"
                strokeOpacity="0.9"
                strokeWidth="2"
                strokeLinejoin="round"
            />
        </g>
    </svg>
)
export const CancelVoteSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g id="&#229;&#143;&#150;&#230;&#182;&#136;&#230;&#138;&#149;&#231;&#165;&#168;">
            <path
                id="Vector"
                d="M9.99916 1.42847C5.26523 1.42847 1.42773 5.26597 1.42773 9.99989C1.42773 14.7338 5.26523 18.5713 9.99916 18.5713C14.7331 18.5713 18.5706 14.7338 18.5706 9.99989C18.5706 5.26597 14.7331 1.42847 9.99916 1.42847ZM2.57059 9.99989C2.57059 8.99632 2.76702 8.02311 3.15452 7.10882C3.52773 6.22489 4.06523 5.43025 4.74738 4.74632C5.42952 4.0624 6.22416 3.52847 7.10988 3.15347C8.02416 2.76597 8.99738 2.56954 10.0009 2.56954C11.0045 2.56954 11.9777 2.76597 12.892 3.15347C13.5849 3.44632 14.2224 3.83918 14.7956 4.32489L4.32595 14.7945C3.84023 14.2231 3.44738 13.5838 3.15452 12.891C2.76702 11.9749 2.57059 11.0035 2.57059 9.99989ZM15.2527 15.2517C14.5706 15.9338 13.7759 16.4695 12.8902 16.8445C11.9759 17.232 11.0027 17.4285 9.99916 17.4285C8.99559 17.4285 8.02238 17.232 7.10809 16.8445C6.38309 16.5374 5.71881 16.1231 5.12773 15.607L15.6081 5.12847C16.1242 5.71954 16.5384 6.38382 16.8456 7.10882C17.2313 8.02311 17.4277 8.99632 17.4277 9.99989C17.4277 11.0035 17.2313 11.9767 16.8438 12.891C16.4706 13.7749 15.9349 14.5695 15.2527 15.2517Z"
            />
        </g>
    </svg>
)
export const CanvassSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g id="&#230;&#139;&#137;&#231;&#165;&#168;">
            <path
                id="Vector"
                d="M17.851 2.21342C17.2076 1.56994 16.3491 1.21558 15.4338 1.21558C14.5185 1.21558 13.66 1.56994 13.0165 2.21342L8.14012 7.08981C7.49663 7.73326 7.14227 8.59173 7.14227 9.50707C7.14227 10.4224 7.49663 11.2809 8.14008 11.9243C8.6363 12.4206 9.26477 12.7475 9.95752 12.8697C9.99198 12.8758 10.0263 12.8788 10.0601 12.8788C10.3391 12.8788 10.5865 12.6787 10.6366 12.3943C10.6928 12.0755 10.48 11.7715 10.1612 11.7153C9.70657 11.635 9.29435 11.4207 8.96905 11.0954C8.54698 10.6733 8.31458 10.1093 8.31458 9.50707C8.31458 8.90488 8.54698 8.34082 8.96905 7.91875L9.2789 7.6089C9.27369 7.63081 9.26955 7.65319 9.26681 7.67609C9.22862 7.99758 9.45822 8.28912 9.7797 8.32735C10.2848 8.38737 10.7409 8.60818 11.0986 8.96588C11.5206 9.38791 11.7531 9.95201 11.7531 10.5542C11.7531 11.1564 11.5206 11.7205 11.0986 12.1425L6.2222 17.0189C5.80012 17.441 5.23607 17.6734 4.63387 17.6734C4.03168 17.6734 3.46762 17.441 3.04555 17.0189C2.62348 16.5968 2.39107 16.0328 2.39107 15.4306C2.39107 14.8284 2.62348 14.2643 3.04555 13.8423L6.35485 10.5329C6.58377 10.304 6.58377 9.93286 6.35485 9.70398C6.12596 9.4751 5.75479 9.4751 5.52591 9.70398L2.21659 13.0133C1.57311 13.6568 1.21875 14.5152 1.21875 15.4306C1.21875 16.3459 1.57311 17.2044 2.21659 17.8478C2.86004 18.4913 3.71851 18.8457 4.63385 18.8457C5.54919 18.8457 6.40767 18.4913 7.05111 17.8478L11.9275 12.9714C12.571 12.328 12.9254 11.4695 12.9254 10.5542C12.9254 9.63884 12.571 8.78037 11.9275 8.13692C11.3898 7.59911 10.6761 7.25332 9.91807 7.1632C9.8474 7.15483 9.7782 7.15937 9.71283 7.17495L13.8454 3.04236C14.2675 2.62028 14.8316 2.38788 15.4337 2.38788C16.0359 2.38788 16.6 2.62028 17.0221 3.04236C17.4441 3.46443 17.6765 4.02849 17.6765 4.63068C17.6765 5.23287 17.4441 5.79693 17.0221 6.219L14.0889 9.15225C13.8599 9.38114 13.8599 9.75231 14.0889 9.98119C14.3177 10.2101 14.6889 10.2101 14.9178 9.98119L17.851 7.04794C18.4945 6.4045 18.8489 5.54602 18.8489 4.63068C18.8489 3.71534 18.4945 2.85686 17.851 2.21342Z"
            />
        </g>
    </svg>
)
export const CloseProposal = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g id="&#229;&#133;&#179;&#233;&#151;&#173;&#230;&#138;&#149;&#231;&#165;&#168;">
            <g id="Group 427318911">
                <path
                    id="Vector"
                    d="M9.99916 2.65296C10.9915 2.65296 11.9536 2.84704 12.8586 3.22981C13.7333 3.59977 14.5191 4.12969 15.1942 4.80482C15.8694 5.47994 16.3993 6.26577 16.7692 7.1405C17.152 8.04547 17.3461 9.00752 17.3461 9.99989C17.3461 10.9923 17.152 11.9543 16.7692 12.8593C16.3993 13.734 15.8694 14.5198 15.1942 15.195C14.5191 15.8701 13.7333 16.4 12.8586 16.77C11.9536 17.1527 10.9915 17.3468 9.99916 17.3468C9.00679 17.3468 8.04476 17.1527 7.13977 16.77C6.26504 16.4 5.47921 15.8701 4.80409 15.195C4.12896 14.5198 3.59906 13.734 3.22907 12.8593C2.84631 11.9543 2.65222 10.9923 2.65222 9.99989C2.65222 9.00752 2.84631 8.04549 3.22907 7.1405C3.59904 6.26577 4.12896 5.47994 4.80409 4.80482C5.47921 4.12969 6.26504 3.59979 7.13977 3.22981C8.04476 2.84704 9.00679 2.65296 9.99916 2.65296M9.99916 1.42847C5.26528 1.42847 1.42773 5.26602 1.42773 9.99989C1.42773 14.7338 5.26528 18.5713 9.99916 18.5713C14.733 18.5713 18.5706 14.7338 18.5706 9.99989C18.5706 5.26602 14.733 1.42847 9.99916 1.42847V1.42847Z"
                />
                <path
                    id="Vector_2"
                    d="M10.9093 10.0261L13.7877 7.14776C14.0317 6.90368 14.0317 6.50795 13.7877 6.26387C13.5436 6.01979 13.1478 6.01979 12.9038 6.26387L10.0254 9.14227L7.14703 6.26389C6.90294 6.01981 6.50722 6.01981 6.26314 6.26389C6.01906 6.50797 6.01906 6.9037 6.26314 7.14778L9.14154 10.0261L6.26316 12.9045C6.01908 13.1486 6.01908 13.5443 6.26316 13.7884C6.38519 13.9104 6.54515 13.9714 6.70509 13.9714C6.86503 13.9714 7.025 13.9104 7.14703 13.7884L10.0254 10.91L12.9038 13.7884C13.0258 13.9104 13.1858 13.9714 13.3457 13.9714C13.5057 13.9714 13.6656 13.9104 13.7877 13.7884C14.0317 13.5443 14.0317 13.1486 13.7877 12.9045L10.9093 10.0261Z"
                />
            </g>
        </g>
    </svg>
)
export const DisapproveSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="&#229;&#143;&#141;&#229;&#175;&#185;">
            <path
                id="Vector"
                d="M8.49162 12.2501V15.2501C8.49162 16.4928 9.49895 17.5001 10.7416 17.5001L13.7416 10.7501V2.5001H5.02662C4.27866 2.49164 3.63876 3.03554 3.52661 3.7751L2.49161 10.5251C2.42556 10.9602 2.55393 11.4024 2.84271 11.7345C3.13149 12.0665 3.55154 12.2551 3.99161 12.2501H8.49162Z"
                stroke="#FC4141"
                strokeWidth="2"
                strokeLinejoin="round"
            />
            <path
                id="Vector_2"
                d="M13.7422 2.50003H15.7447C16.6233 2.48449 17.3744 3.12921 17.4922 4.00003V9.25004C17.3744 10.1208 16.6233 10.8489 15.7447 10.8334H13.7422V2.50003Z"
                stroke="#FC4141"
                strokeWidth="2"
                strokeLinejoin="round"
            />
        </g>
    </svg>
)
export const PreviewDirectorSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="&#230;&#159;&#165;&#231;&#156;&#139;&#232;&#174;&#174;&#229;&#145;&#152;">
            <path
                id="Vector"
                d="M10 2.50439C10.9703 2.50439 11.8826 2.88232 12.5688 3.56846C13.2549 4.25459 13.6328 5.16689 13.6328 6.13721C13.6328 7.10752 13.2549 8.01982 12.5688 8.70596C11.8826 9.39209 10.9703 9.77002 10 9.77002C9.02969 9.77002 8.11738 9.39209 7.43125 8.70596C6.74512 8.01982 6.36719 7.10771 6.36719 6.13721C6.36719 5.1667 6.74512 4.25459 7.43125 3.56846C8.11738 2.88232 9.02969 2.50439 10 2.50439M10 1.25439C7.30332 1.25439 5.11719 3.44053 5.11719 6.13721C5.11719 8.83389 7.30332 11.02 10 11.02C12.6967 11.02 14.8828 8.83389 14.8828 6.13721C14.8828 3.44053 12.6967 1.25439 10 1.25439Z"
                fill="black"
            />
            <path
                id="Vector_2"
                d="M10.7627 10.3402C10.7627 10.027 10.5099 9.77109 10.1966 9.77051H10.1832C9.10016 9.77051 8.04996 10.0033 7.06148 10.4623C6.11129 10.9035 5.25894 11.534 4.52848 12.3363C3.80172 13.1344 3.23141 14.0627 2.83355 15.0953C2.4943 15.976 2.29332 16.9012 2.23355 17.8535C2.21305 18.1814 2.47379 18.4584 2.8023 18.4584H2.91324C3.2148 18.4584 3.46246 18.2232 3.48199 17.9223C3.73101 14.0703 6.64156 11.0205 10.1834 11.0205H10.1892C10.5054 11.0209 10.7629 10.767 10.7629 10.4508V10.3402H10.7627ZM13.831 12.766C14.4052 12.766 14.9353 13.0262 15.2855 13.4797C15.9039 14.2807 15.7554 15.4354 14.9545 16.0537C14.6302 16.3041 14.2431 16.4365 13.8351 16.4365C13.2609 16.4365 12.7306 16.1764 12.3804 15.7229C11.7621 14.9219 11.9105 13.7672 12.7115 13.1488C13.0359 12.8984 13.4228 12.766 13.831 12.766ZM13.8308 11.516C13.1724 11.516 12.5089 11.726 11.9476 12.1594C10.599 13.2006 10.3498 15.1381 11.3912 16.4867C11.999 17.274 12.9121 17.6865 13.8353 17.6865C14.4937 17.6865 15.1572 17.4766 15.7185 17.0432C17.0671 16.002 17.3164 14.0645 16.275 12.7158C15.6671 11.9285 14.7541 11.516 13.8308 11.516Z"
                fill="black"
            />
            <path
                id="Vector_3"
                d="M15.586 15.7307C15.4261 15.7307 15.2661 15.7917 15.144 15.9137C14.8999 16.1579 14.8999 16.5536 15.144 16.7975L16.6218 18.2752C16.7438 18.3973 16.9038 18.4583 17.0638 18.4583C17.2237 18.4583 17.3837 18.3973 17.5058 18.2752C17.7499 18.0311 17.7499 17.6354 17.5058 17.3915L16.028 15.9137C15.97 15.8556 15.9011 15.8095 15.8253 15.7781C15.7494 15.7467 15.6681 15.7306 15.586 15.7307Z"
                fill="black"
            />
        </g>
    </svg>
)
export const ReceiveProposalAmountSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g id="&#233;&#162;&#134;&#229;&#143;&#150;&#230;&#143;&#144;&#230;&#161;&#136;&#233;&#135;&#145;">
            <g id="Vector">
                <path d="M5.50065 6.08317C7.80184 6.08317 9.66732 5.15043 9.66732 3.99984C9.66732 2.84924 7.80184 1.9165 5.50065 1.9165C3.19946 1.9165 1.33398 2.84924 1.33398 3.99984C1.33398 5.15043 3.19946 6.08317 5.50065 6.08317Z" />
                <path d="M1.33398 3.99984C1.33398 3.99984 1.33398 5.76592 1.33398 6.9165C1.33398 8.06709 3.19946 8.99984 5.50065 8.99984C7.80182 8.99984 9.66732 8.06709 9.66732 6.9165C9.66732 6.235 9.66732 3.99984 9.66732 3.99984" />
                <path d="M1.33398 6.9165C1.33398 6.9165 1.33398 8.68259 1.33398 9.83317C1.33398 10.9838 3.19946 11.9165 5.50065 11.9165C7.80182 11.9165 9.66732 10.9838 9.66732 9.83317C9.66732 9.15167 9.66732 6.9165 9.66732 6.9165" />
                <path d="M1.33398 9.83317C1.33398 9.83317 1.33398 11.5993 1.33398 12.7498C1.33398 13.9004 3.19946 14.8332 5.50065 14.8332C7.80182 14.8332 9.66732 13.9004 9.66732 12.7498C9.66732 12.0683 9.66732 9.83317 9.66732 9.83317" />
                <path d="M1.33398 12.7498C1.33398 12.7498 1.33398 14.5159 1.33398 15.6665C1.33398 16.8171 3.19946 17.7498 5.50065 17.7498C7.80182 17.7498 9.66732 16.8171 9.66732 15.6665C9.66732 14.985 9.66732 12.7498 9.66732 12.7498" />
                <path d="M13.834 11.9165C16.1352 11.9165 18.0007 10.9838 18.0007 9.83317C18.0007 8.68258 16.1352 7.74984 13.834 7.74984C11.5328 7.74984 9.66732 8.68258 9.66732 9.83317C9.66732 10.9838 11.5328 11.9165 13.834 11.9165Z" />
                <path d="M9.66732 9.83317C9.66732 9.83317 9.66732 11.5993 9.66732 12.7498C9.66732 13.9004 11.5328 14.8332 13.834 14.8332C16.1352 14.8332 18.0007 13.9004 18.0007 12.7498C18.0007 12.0683 18.0007 9.83317 18.0007 9.83317" />
                <path d="M9.66732 12.7498C9.66732 12.7498 9.66732 14.5159 9.66732 15.6665C9.66732 16.8171 11.5328 17.7498 13.834 17.7498C16.1352 17.7498 18.0007 16.8171 18.0007 15.6665C18.0007 14.985 18.0007 12.7498 18.0007 12.7498" />
                <path
                    d="M9.66732 3.99984C9.66732 5.15043 7.80184 6.08317 5.50065 6.08317C3.19946 6.08317 1.33398 5.15043 1.33398 3.99984M9.66732 3.99984C9.66732 2.84924 7.80184 1.9165 5.50065 1.9165C3.19946 1.9165 1.33398 2.84924 1.33398 3.99984M9.66732 3.99984C9.66732 3.99984 9.66732 6.235 9.66732 6.9165M1.33398 3.99984C1.33398 3.99984 1.33398 5.76592 1.33398 6.9165M1.33398 6.9165C1.33398 8.06709 3.19946 8.99984 5.50065 8.99984C7.80182 8.99984 9.66732 8.06709 9.66732 6.9165M1.33398 6.9165C1.33398 6.9165 1.33398 8.68259 1.33398 9.83317M9.66732 6.9165C9.66732 6.9165 9.66732 9.15167 9.66732 9.83317M1.33398 9.83317C1.33398 10.9838 3.19946 11.9165 5.50065 11.9165C7.80182 11.9165 9.66732 10.9838 9.66732 9.83317M1.33398 9.83317C1.33398 9.83317 1.33398 11.5993 1.33398 12.7498M9.66732 9.83317C9.66732 9.83317 9.66732 12.0683 9.66732 12.7498M9.66732 9.83317C9.66732 10.9838 11.5328 11.9165 13.834 11.9165C16.1352 11.9165 18.0007 10.9838 18.0007 9.83317M9.66732 9.83317C9.66732 8.68258 11.5328 7.74984 13.834 7.74984C16.1352 7.74984 18.0007 8.68258 18.0007 9.83317M9.66732 9.83317C9.66732 9.83317 9.66732 11.5993 9.66732 12.7498M1.33398 12.7498C1.33398 13.9004 3.19946 14.8332 5.50065 14.8332C7.80182 14.8332 9.66732 13.9004 9.66732 12.7498M1.33398 12.7498C1.33398 12.7498 1.33398 14.5159 1.33398 15.6665C1.33398 16.8171 3.19946 17.7498 5.50065 17.7498C7.80182 17.7498 9.66732 16.8171 9.66732 15.6665M9.66732 12.7498C9.66732 12.7498 9.66732 14.985 9.66732 15.6665M9.66732 12.7498C9.66732 13.9004 11.5328 14.8332 13.834 14.8332C16.1352 14.8332 18.0007 13.9004 18.0007 12.7498M9.66732 12.7498C9.66732 12.7498 9.66732 14.5159 9.66732 15.6665M9.66732 15.6665C9.66732 16.8171 11.5328 17.7498 13.834 17.7498C16.1352 17.7498 18.0007 16.8171 18.0007 15.6665C18.0007 14.985 18.0007 12.7498 18.0007 12.7498M18.0007 9.83317C18.0007 9.83317 18.0007 12.0683 18.0007 12.7498"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </g>
    </svg>
)
export const ReceiveRequestedAmountSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="&#233;&#162;&#134;&#229;&#143;&#150;&#231;&#148;&#179;&#232;&#175;&#183;&#232;&#181;&#132;&#233;&#135;&#145;">
            <g id="Group 427318914">
                <path
                    id="Vector"
                    d="M2.96459 14.0931C2.63078 13.6893 2.65659 13.0125 3.02497 12.6581C3.86716 11.8452 5.30611 10.6526 6.24149 10.6526C7.63931 10.6517 12.4225 10.692 12.7052 11.0512C12.9799 11.4003 12.7454 12.4787 11.4045 12.5982C10.4363 12.6844 9.24451 12.6712 8.59832 12.7062C8.48063 12.7132 8.44738 12.9075 8.55282 12.9727C9.06164 13.2859 10.0692 13.7851 11.2719 13.7339C12.2747 13.6906 13.2262 13.1731 13.9783 12.3536C14.6004 11.6772 15.5446 10.7318 15.9694 10.7318C16.2817 10.7318 16.4865 10.7987 16.6156 10.8687C16.7284 10.9304 16.7669 11.1045 16.6913 11.2253C16.2043 12.0075 14.2762 14.9266 12.1557 15.7018C10.3925 16.3471 8.43732 16.1275 7.47525 15.953C7.07319 15.8799 6.66762 15.9963 6.33337 16.2802L5.94312 16.6127C5.63774 16.8726 5.22649 16.828 4.96136 16.5081L2.96459 14.094V14.0931Z"
                    fill="#FF9432"
                />
                <path
                    id="Vector_2"
                    d="M12.5024 6.50027C12.5024 6.95993 12.4119 7.41508 12.236 7.83975C12.0601 8.26442 11.8023 8.65029 11.4772 8.97531C11.1522 9.30034 10.7663 9.55817 10.3417 9.73407C9.91701 9.90997 9.46185 10.0005 9.0022 10.0005C8.54254 10.0005 8.08738 9.90997 7.66271 9.73407C7.23804 9.55817 6.85218 9.30034 6.52715 8.97531C6.20212 8.65029 5.9443 8.26442 5.76839 7.83975C5.59249 7.41508 5.50195 6.95993 5.50195 6.50027C5.50195 5.572 5.8707 4.68176 6.52709 4.02538C7.18347 3.369 8.07371 3.00024 9.00198 3.00024C9.93024 3.00024 10.8205 3.369 11.4769 4.02538C12.1332 4.68176 12.502 5.572 12.502 6.50027"
                    fill="#FFCC87"
                />
                <path
                    id="Vector_3"
                    d="M12.1143 3.13805C11.6395 2.9946 11.138 2.96241 10.6487 3.04398C10.6242 3.04792 10.6246 3.08248 10.6487 3.08773C12.2102 3.43949 13.377 4.83337 13.377 6.50026C13.377 8.20871 12.152 9.62928 10.5332 9.93684C10.7458 9.97753 10.965 10.0003 11.1895 10.0003C12.0603 10.0003 12.8999 9.67563 13.5442 9.08974C14.1885 8.50385 14.5912 7.69882 14.6737 6.83188C14.8194 5.28181 13.6049 3.58955 12.1143 3.13805"
                    fill="#FF9432"
                />
                <path
                    id="Vector_4"
                    d="M9.77005 6.0492H10.1585V6.57377H9.50229L9.36667 6.83627V7.10315H10.1585V7.62815H9.36667V8.36841H8.64129V7.62815H7.84503V7.10315H8.64216V6.83627L8.50566 6.57377H7.84503V6.04877H8.23879L7.51953 4.63257H8.31185L9.00442 6.1437L9.69698 4.63257H10.4845L9.77005 6.0492Z"
                    fill="white"
                />
            </g>
        </g>
    </svg>
)
export const DisableReceiveRequestedAmountSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="&#233;&#162;&#134;&#229;&#143;&#150;&#231;&#148;&#179;&#232;&#175;&#183;&#232;&#181;&#132;&#233;&#135;&#145;">
            <g id="Group 427318914">
                <path
                    id="Vector"
                    d="M2.96459 14.0931C2.63078 13.6893 2.65659 13.0125 3.02497 12.6581C3.86716 11.8452 5.30611 10.6526 6.24149 10.6526C7.63931 10.6517 12.4225 10.692 12.7052 11.0512C12.9799 11.4003 12.7454 12.4787 11.4045 12.5982C10.4363 12.6844 9.24451 12.6712 8.59832 12.7062C8.48063 12.7132 8.44738 12.9075 8.55282 12.9727C9.06164 13.2859 10.0692 13.7851 11.2719 13.7339C12.2747 13.6906 13.2262 13.1731 13.9783 12.3536C14.6004 11.6772 15.5446 10.7318 15.9694 10.7318C16.2817 10.7318 16.4865 10.7987 16.6156 10.8687C16.7284 10.9304 16.7669 11.1045 16.6913 11.2253C16.2043 12.0075 14.2762 14.9266 12.1557 15.7018C10.3925 16.3471 8.43732 16.1275 7.47525 15.953C7.07319 15.8799 6.66762 15.9963 6.33337 16.2802L5.94312 16.6127C5.63774 16.8726 5.22649 16.828 4.96136 16.5081L2.96459 14.094V14.0931Z"
                    fill="#cdcdcd"
                />
                <path
                    id="Vector_2"
                    d="M12.5024 6.50027C12.5024 6.95993 12.4119 7.41508 12.236 7.83975C12.0601 8.26442 11.8023 8.65029 11.4772 8.97531C11.1522 9.30034 10.7663 9.55817 10.3417 9.73407C9.91701 9.90997 9.46185 10.0005 9.0022 10.0005C8.54254 10.0005 8.08738 9.90997 7.66271 9.73407C7.23804 9.55817 6.85218 9.30034 6.52715 8.97531C6.20212 8.65029 5.9443 8.26442 5.76839 7.83975C5.59249 7.41508 5.50195 6.95993 5.50195 6.50027C5.50195 5.572 5.8707 4.68176 6.52709 4.02538C7.18347 3.369 8.07371 3.00024 9.00198 3.00024C9.93024 3.00024 10.8205 3.369 11.4769 4.02538C12.1332 4.68176 12.502 5.572 12.502 6.50027"
                    fill="#cdcdcd"
                />
                <path
                    id="Vector_3"
                    d="M12.1143 3.13805C11.6395 2.9946 11.138 2.96241 10.6487 3.04398C10.6242 3.04792 10.6246 3.08248 10.6487 3.08773C12.2102 3.43949 13.377 4.83337 13.377 6.50026C13.377 8.20871 12.152 9.62928 10.5332 9.93684C10.7458 9.97753 10.965 10.0003 11.1895 10.0003C12.0603 10.0003 12.8999 9.67563 13.5442 9.08974C14.1885 8.50385 14.5912 7.69882 14.6737 6.83188C14.8194 5.28181 13.6049 3.58955 12.1143 3.13805"
                    fill="#cdcdcd"
                />
                <path
                    id="Vector_4"
                    d="M9.77005 6.0492H10.1585V6.57377H9.50229L9.36667 6.83627V7.10315H10.1585V7.62815H9.36667V8.36841H8.64129V7.62815H7.84503V7.10315H8.64216V6.83627L8.50566 6.57377H7.84503V6.04877H8.23879L7.51953 4.63257H8.31185L9.00442 6.1437L9.69698 4.63257H10.4845L9.77005 6.0492Z"
                    fill="white"
                />
            </g>
        </g>
    </svg>
)
export const VoteSvg = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g id="&#230;&#138;&#149;&#231;&#165;&#168; (1) 1">
            <path
                id="Vector"
                d="M1.25 17.5H18.75V18.75H1.25V17.5ZM7.5375 16.0625C7.65 16.1875 7.8125 16.25 7.975 16.25C8.1375 16.25 8.3 16.1875 8.4125 16.0625L17.3125 7.1625C17.4375 7.05 17.5 6.9 17.5 6.725C17.5 6.55 17.4375 6.4 17.3125 6.2875L12.4625 1.4375C12.2125 1.1875 11.825 1.1875 11.575 1.4375L2.6875 10.325C2.4375 10.575 2.4375 10.9625 2.6875 11.2125L7.5375 16.0625V16.0625ZM12.025 2.7625L16 6.7375L7.9875 14.75L4.0125 10.775L12.025 2.7625V2.7625Z"
            />
            <path id="Vector_2" d="M10.1074 5.99117L10.9912 5.10742L13.6424 7.75867L12.7587 8.64242L10.1074 5.99117Z" />
        </g>
    </svg>
)

const config: any = {
    CouncilSVG,
    OverviewSVG,
    VoteSVG,
    InfoCircleSvg,
    ShareSvg,
    CircleSvg,
    MoreSvg,
    ApproveSvg,
    CancelVoteSvg,
    CanvassSvg,
    CloseProposal,
    DisapproveSvg,
    PreviewDirectorSvg,
    ReceiveProposalAmountSvg,
    DisableReceiveRequestedAmountSvg,
    ReceiveRequestedAmountSvg,
    VoteSvg,
}

export const SVGCom = ({ src }: { src: string }): JSX.Element => {
    return config[src]
}
