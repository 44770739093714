import { useWeb3React } from '@web3-react/core'
import { Form, Input, message, Upload } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import type { UploadChangeParam } from 'antd/es/upload'
import type { UploadFile, UploadProps } from 'antd/es/upload/interface'
import { ajaxGet, ajaxPost, RSASignData } from 'api/axios'
import baseUrl from 'api/env'
import TipImage from 'assets/img/tip.png'
import UploadImage from 'assets/img/upload.png'
import Back from 'components/Back'
import { ButtonPrimary } from 'components/Button'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import WangEditor from 'components/WangEditor'
import { useGsCouncilContract } from 'hooks/useContract'
import useGetUserVotingInfo from 'hooks/useGetUserVotingInfo'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Flex, Image } from 'rebass'
import { useUserToken } from 'state/application/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled, { css, ThemeContext } from 'styled-components'
import { TYPE } from 'theme'

const StyledUpload = styled(Upload)`
    .ant-upload-select {
        width: 142px;
        height: 142px;
        background-color: #fff;
        img {
            height: 100%;
        }
    }
`

const CustomUpload = ({ value = '', onChange }: { value?: string; onChange?: (val: string) => void }) => {
    const [loading, setLoading] = useState(false)
    const userToken = useUserToken()
    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return
        }
        const { status, response } = info.file
        if (status === 'done') {
            if (response?.code === '200') {
                onChange?.(response.data[0])
                message.success('头像上传成功')
            }
        } else if (status === 'error') {
            setLoading(false)
            message.error('头像上传失败')
        }
    }

    const { account } = useWeb3React()

    const theme = useContext(ThemeContext)

    const params = useMemo(() => {
        if (!account) return {}
        return {
            address: account,
            sign: RSASignData({
                address: account,
            }),
        }
    }, [account])

    return (
        <StyledUpload
            name="imgs"
            listType="picture-card"
            className="avatar-StyledUploader"
            showUploadList={false}
            action={`${baseUrl}/common/uploadImg`}
            headers={{
                Authorization: userToken,
            }}
            accept=".png,.jpg"
            data={params}
            onChange={handleChange}
        >
            {value ? (
                <img src={value} alt="avatar" style={{ width: '100%' }} />
            ) : (
                <Box>
                    {loading ? (
                        <Loader stroke={theme.primary1} />
                    ) : (
                        <Image src={UploadImage} width="27px" height="24px"></Image>
                    )}
                    <TYPE.body color="#999F9C" marginTop="15px">
                        上传照片
                    </TYPE.body>
                </Box>
            )}
        </StyledUpload>
    )
}

const PseudoClassCss = css`
    &:focus {
        border-color: ${({ theme }) => theme.primary1};
        box-shadow: none;
    }
    &:hover {
        border-color: ${({ theme }) => theme.primary1};
    }
`

const InputCss = css`
    padding: 18px 28px;
    border-color: transparent;
    border-radius: 6px;
    line-height: 19px;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.text1};

    &::placeholder {
        color: #999f9c;
    }
`

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 28px;
    &:last-child {
        margin-bottom: 64px;
    }
    .ant-form-item-label {
        padding-bottom: 10px;
        label {
            font-size: 18px;
            font-weight: 400;
            color: ${({ theme }) => theme.text1};
            line-height: 21px;
        }
    }

    .ant-form-item-required {
        display: inline-block;
        &::before {
            display: none !important;
        }
        &::after {
            line-height: 21px;
            font-size: 18px;
            font-weight: 400;
            display: inline-block !important;
            content: '*';
            color: #ff4d4f;
            vertical-align: middle;
        }
    }
`

const StyledInput = styled(Input)`
    ${InputCss};
    ${PseudoClassCss};
`

interface RegistryFormProps {
    nickName?: string
    headUrl?: string
    email?: string
    twitte?: string
    website?: string
    introduction?: string
}

const RegistryForm = ({
    setIsActionDisabled,
    form,
    onFinish,
    isMP,
}: {
    setIsActionDisabled: (isActionDisabled: boolean) => void
    form: FormInstance<RegistryFormProps>
    onFinish: (values: RegistryFormProps) => void
    isMP: boolean | undefined
}) => {
    const triggersSetActionStatus = useCallback(
        (values: RegistryFormProps) => {
            const allValuesKeys = Object.keys(values)
            const isActionDisabled = allValuesKeys.some((item) => {
                if (item === 'introduction') return !values[item] || values[item] === '<p><br></p>'
                if (['email', 'twitte', 'website'].includes(item)) return false
                return !values[item as keyof RegistryFormProps]
            })
            setIsActionDisabled(isActionDisabled)
        },
        [setIsActionDisabled]
    )

    const onValuesChange = useCallback(() => triggersSetActionStatus(form.getFieldsValue()), [triggersSetActionStatus])

    const { account } = useWeb3React()
    const getFormData = useCallback(async () => {
        if (!account || isMP || typeof isMP === 'undefined') return

        const { data, code } = await ajaxGet('fetchCouncilDraft', {
            address: account,
        })
        if (code === '200') {
            const values = {
                nickName: data.nickName,
                headUrl: data.headUrl,
                email: data.email || undefined,
                twitte: data.twitte || undefined,
                website: data.website || undefined,
                introduction: data.introduction,
            }
            form.setFieldsValue(values)
            triggersSetActionStatus(values)
        }
    }, [account, triggersSetActionStatus, form, isMP])
    useEffect(() => {
        getFormData()
    }, [])
    return (
        <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish} onValuesChange={onValuesChange}>
            <StyledFormItem name="nickName" label="昵称" rules={[{ required: true, message: '请输入昵称' }]}>
                <StyledInput maxLength={10} placeholder="请输入昵称" />
            </StyledFormItem>
            <StyledFormItem name="headUrl" label="头像" rules={[{ required: true, message: '请选择头像' }]}>
                <CustomUpload />
            </StyledFormItem>
            <StyledFormItem
                name="introduction"
                label="简介"
                rules={[
                    {
                        required: true,
                        validator: async (rule, val) => {
                            if (!val || val == '<p><br></p>') throw new Error('请输入简介')
                        },
                    },
                ]}
            >
                <WangEditor placeholder="请输入简介" />
            </StyledFormItem>
            <StyledFormItem name="email" label="Email" rules={[{ type: 'email', message: '请输入正确的邮箱地址' }]}>
                <StyledInput placeholder="请输入您的Email" />
            </StyledFormItem>
            <StyledFormItem name="twitte" label="Twitter">
                <StyledInput placeholder="请输入您的Twitter账号" />
            </StyledFormItem>
            <StyledFormItem name="website" label="网站" rules={[{ type: 'url', message: '请输入正确的邮箱地址' }]}>
                <StyledInput placeholder="请输入您的网站地址" />
            </StyledFormItem>
        </Form>
    )
}

export default () => {
    const [isActionDisabled, setIsActionDisabled] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
    const [hash, setHash] = useState<string>('')
    const addTransaction = useTransactionAdder()

    const [isApplying, setIsApplying] = useState(false)

    const [form] = Form.useForm<RegistryFormProps>()

    const { account } = useWeb3React()

    const councilContract = useGsCouncilContract()

    const { isMP, getUserVotingInfo } = useGetUserVotingInfo()

    const onFinish = useCallback(
        async (values: RegistryFormProps) => {
            if (!account || !councilContract) return
            setIsApplying(true)
            let prevMember1
            try {
                const { introduction, ...params } = values
                const sign = RSASignData(params)
                await ajaxPost('doApplyCouncil', { ...params, sign, introduction })

                const { data } = await ajaxPost('fetchAddressPosition', { address: account, type: 'join' })
                prevMember1 = data.prevMember1
            } catch {
                setIsApplying(false)
                return
            }

            try {
                setAttemptingTxn(true)
                setIsOpen(true)
                const { hash } = await councilContract.join(prevMember1)

                setIsApplying(false)
                setAttemptingTxn(false)
                setHash(hash)
                addTransaction(
                    {
                        hash,
                    } as any,
                    {
                        summary: '申请成为议员成功！',
                    }
                )
            } catch (error: any) {
                setAttemptingTxn(false)
                setIsApplying(false)
                setIsOpen(false)
                setErrorMessage(error?.data?.message || error?.message || '申请成为议员失败')
            }
        },
        [setAttemptingTxn, setIsOpen, setHash, addTransaction, account, setIsApplying, setErrorMessage]
    )

    const onRegistry = useCallback(() => {
        if (isApplying) return
        form.submit()
    }, [form, isApplying])

    const theme = useContext(ThemeContext)

    const history = useHistory()

    useEffect(() => {
        getUserVotingInfo()
    }, [])

    useEffect(() => {
        console.log({ isMP })

        account && isMP && history.replace(`/DirectorInfo/${account}`)
    }, [isMP, account, history])

    return (
        <Box paddingBottom="26px">
            <Back />
            <Box width="540px" style={{ margin: '0 auto' }}>
                <Flex
                    alignItems="center"
                    justifyContent="center"
                    height="30px"
                    marginBottom="28px"
                    width="100%"
                    backgroundColor="#FFA47C"
                >
                    <Image src={TipImage} width="16px" height="16px" marginRight="8px"></Image>
                    <TYPE.body color="#fff">任何人都可以参与竞选</TYPE.body>
                </Flex>
                <RegistryForm form={form} setIsActionDisabled={setIsActionDisabled} onFinish={onFinish} isMP={isMP} />
                <ButtonPrimary disabled={isActionDisabled} onClick={onRegistry}>
                    {isApplying && <Loader stroke={theme.white} />} 注册候选人身份
                </ButtonPrimary>
            </Box>
            <TransactionConfirmationModal
                isOpen={isOpen}
                attemptingTxn={attemptingTxn}
                hash={hash}
                onDismiss={() => setIsOpen(false)}
                content={() => <>在你的钱包中确认这笔交易</>}
            />

            <Modal isOpen={!!errorMessage} onDismiss={() => setErrorMessage('')} maxHeight={90}>
                <TransactionErrorContent message={errorMessage} onDismiss={() => setErrorMessage('')} />
            </Modal>
        </Box>
    )
}
