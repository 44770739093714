import { message } from 'antd'
import Axios, { AxiosInstance } from 'axios'
import jsrsasign from 'jsrsasign'

import request from './api'
import baseUrl from './env'

// import Qs from 'qs'
// import { loginOut } from 'state/userInfo/actions'
// import store from 'state'

const axios: AxiosInstance = Axios.create({
    timeout: 1300000,
})

// const UPLOAD_URL_LIST = ['/common/uploadImg', '/common/uploadFile']
axios.interceptors.request.use(
    (config) => {
        const tokenStorage = localStorage.getItem('redux_localstorage_simple_application.token')
        let token = undefined
        try {
            token = tokenStorage && tokenStorage.startsWith(`"`) ? JSON.parse(tokenStorage) : tokenStorage
        } catch (err: any) {
            console.log('localStorage token error: ', err.message)
        }
        const header: any = {
            language: localStorage.getItem('i18nextLng') === 'en' ? 'zh_CN' : 'zh_CN',
            Authorization: token,
        }

        // header['Content-Type'] = UPLOAD_URL_LIST.includes(config.url as string)
        //     ? 'multipart/form-data'
        //     : 'application/json;charset=UTF-8'
        config.headers = header
        return config
    },
    (err) => {
        return Promise.reject(err)
    }
)
axios.interceptors.response.use(
    (response) => {
        if (['200', '237', '236'].includes(response.data.code)) {
            return response.data
        }
        if (response.data.code === '403') {
            return {
                ...response.data,
                data: null,
            }
        }

        message.error(response.data.msg)
        return Promise.reject(response.data)
    },
    (error) => {
        if (Axios.isCancel(error)) {
            return Promise.reject({
                code: -1,
                message: error.message,
                result: null,
            })
        }
        return Promise.reject(new Error(error))
    }
)

function doAjax(url: string, params: { [key: string]: any } = {}, type: string) {
    if (!params.sign) {
        params.sign = RSASignData(params)
    }
    const Method: any = {
        get: axios.get,
        delete: axios.delete,
        post: axios.post,
        put: axios.put,
    }
    return Method[type](
        request[url] || url,
        ['get', 'delete'].includes(type)
            ? {
                  params,
              }
            : params
    )
}
export const signParams = (str: string) => {
    const priK =
        '-----BEGIN PRIVATE KEY-----MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCr08LPaBf3x/bwhQjFFTwtbFW6M0anE+wuP5zbi+lcMQ+ow8OPH7pImZv0ASZ3mjmZwOIAOAwhbc3QmWmJoYSw/BDQw8HYGy+yASxnuButwcm7Srp66SApZ9xYO1XotcC85kk7Gezd6D0YpCG5BaqwTsz8lOOS324S2gIQc41+C7veT11ze2mo+oBxdrbPlHRrCsQVvsReokMOEw39ab3aDWHgAqhawG/spKnWjZ9X1CLuTfRorzjwJT+mNgvNlEAgZxzWErdQ1Q5edhd+6z1WVUmtWIdmC7JamClDbInWPsLtJ9sanWZ2VusDxxulIY61l6FO8pAJ1imYcHy5ZvtlAgMBAAECggEATmdB7QHCbf2AYB9T8RnPa6qTM3NSOcqNakz8dfFReg+o5YjuItCHX7k3wA8GTmzA+7dXaGIqptZDo3tc/MAGm/tocNniCYneKYr/iBQqDQ7vzdY7YPofAsWxdVEd0iGc4JTztVQPEPJ3blb2TEaZq/lI77fAX7QztcZkSr1vQqO4jUL7SxKdeBrxV/HGFWFxT3L/w+IBcYBcAetMrvV8QZvBUh66vPwairehYRUSARuY7WuMhbWvj1Z0rU7nPWUypCpFA2OsVeWU3xNGyndOIAdpLoRFsCljseJ8rsQVsjpDktF16/bws6NszMjlsge1y1k7+4lbGrKicH5RMB0iAQKBgQDXbsWIWPM2zXQ6eM2CtK1dzsrrEd1PwAVibA/5mcKUWC9+U8LDzuqTiKp5bNGdQ3M+G/gZuDesWNcnw0Jmdmql4oszZq2niSQbvWnFgVIgNXAq0hqAKY/YgNvj3F/akbUudlV47iW0QvJw/976Ms46+VBoXqsVbjKxJaKrlF2jOQKBgQDMLu0QSsOSPCeLhzjERmUukp/fHrZDjb/2qnEUcDxdfuv4y/DjE4QGPtbiQ7zwxK54iW2aKjdDrdqXgwp7L1WR8XSOgYmiqy9HyRxuxiQRAx/wgl6XbfA+bZyklkxWl9EHi5kxBd5Q/07U0166E5A2ZbhvaZcju7WTRKNXY+u9jQKBgQClMc3KxdqKNXVguO1twroF6uJy6tn3o1j9pbuCPEOWmSCiD/yTRYFe7Dc8sRLzMpuWDsBqiHY4dE9XsESkaT1SQqtXGO8kPucvVklbJ9Uf2ZpPW5jymewxqm3zBjOCWKTEIf1co9DJr2RU/f+vf3Hgivj7b0xlPIEmkaNxSm2bEQKBgH/BhNdC0Ge5d8zXyO8WVMz1NU73+nb+RHRkZOiy6TJe7er4QDr0xRDbVLyyQZAsoDYPVyK082tHZpSSi4ZUMahledaIusOrdKPn9ABjvcFfQSbNi74YLdAB6ZcZ653q1AFymWGRGG95r6t+rbCj+QHwV5xuZwEWvB/vPHy7qo8pAoGALaLw4IsUZt/fkRQpt1nzE/HHPB+UtYcjTVZpFyopBVmu8XLXPAJyQHqcnfC0zuaEHS3KnKad+IEk5CPa1dAlYDcRNUYixhfXg+dAhKQEJ/IKfssAIIIxZrn7YWQajmifEBWSkEwtkHaQYjZyZX/21POcz24DA1clfXgtAqJ8FD4=-----END PRIVATE KEY-----'

    const rsa = jsrsasign.KEYUTIL.getKey(priK)
    const sig = new jsrsasign.KJUR.crypto.Signature({ alg: 'SHA256withRSA' })

    sig.init(rsa)
    sig.updateString(str)
    const sign = jsrsasign.hextob64(sig.sign())

    return sign
}

export const RSASignData = (opt: { [key: string]: any }) => {
    const keys = Object.keys(opt)
    if (!keys.length) return
    const str = []
    keys.sort()
    for (let i = 0; i < keys.length; i++) {
        if (![undefined, null].includes(opt[keys[i]])) {
            str.push(`${keys[i]}=${opt[keys[i]]}`)
        }
    }

    return signParams(`${str.join('&')}`)
}

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? `${baseUrl}` : `${baseUrl}`
export const ajaxGet = async (url: string, params = {}) => await doAjax(url, params, 'get')
export const ajaxPost = async (url: string, params = {}) => await doAjax(url, params, 'post')
export const ajaxDelete = async (url: string, params = {}) => await doAjax(url, params, 'delete')
export const ajaxPut = async (url: string, params = {}) => await doAjax(url, params, 'put')
