import Icon, { LoadingOutlined } from '@ant-design/icons'
import { MaxUint256 } from '@ethersproject/constants'
import { useWeb3React } from '@web3-react/core'
import { message, Switch, Table, Tabs, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { ajaxPost } from 'api/axios'
import NoData from 'assets/img/no-data.png'
import NoSearch from 'assets/img/no-search.png'
import SearchIcon from 'assets/img/search.png'
import { SVGCom } from 'assets/svg'
import { ButtonConfirmed, ButtonOrange } from 'components/Button'
import { Logo } from 'components/Header'
import Modal, { ModalConfirmed } from 'components/Modal'
import Input from 'components/NumericalInput'
import Title from 'components/Title'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import ModalVote from 'components/Vote'
import Council from 'constants/abis/GSCouncil.json'
import ReferendumsFactor from 'constants/abis/GSReferendumsFactory.json'
import {
    APPROVE_BASE_AMOUNT,
    BLOCK_BROSWER_ADDRESS,
    GS_COUNCIL_ADDRESS,
    GS_REFERENDUMS_FACTORY_ADDRESS,
} from 'constants/index'
import { DEFAULT_CHAINID_TOKEN_LIST } from 'constants/lists'
import { ChainId } from 'gsswap_sdk'
import { useCurrency } from 'hooks/Tokens'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useContract } from 'hooks/useContract'
import useCopyClipboard from 'hooks/useCopyClipboard'
import useTable from 'hooks/useTable'
import type { Tab } from 'rc-tabs/lib/interface'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Text } from 'rebass'
import { tryParseAmount } from 'state/swap/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import styled, { ThemeContext } from 'styled-components'
import { shortenAddress } from 'utils'
import { isBoolean } from 'utils/type'

import { TYPE } from '../../theme'

const TableContainer = styled.div``

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .ant-tabs > .ant-tabs-nav {
        margin: 0;
        height: 49px;
        background: #fff;
        border-radius: 2px;
        padding: 0 10px;
        &::before {
            content: none;
        }
        .ant-tabs-tab {
            height: 41px;
            border: none;
            background: transparent;
            font-size: 16px;
            &:hover {
                color: ${({ theme }) => theme.primary1} !important;
            }
        }
        .ant-tabs-tab-btn {
            &:focus {
                color: ${({ theme }) => theme.primary1} !important;
            }
            &:active {
                color: ${({ theme }) => theme.primary1} !important;
            }
        }
        .ant-tabs-nav-list {
            align-items: center;
        }

        .ant-tabs-tab-active {
            color: ${({ theme }) => theme.primary1} !important;
            background: #f2f3f8;
            border-radius: 2px;
            .ant-tabs-tab-btn {
                color: ${({ theme }) => theme.primary1} !important;
            }
        }
    }

    .ant-tabs-tab-btn:focus {
        color: ${({ theme }) => theme.primary1} !important;
    }
`

const RightContainer = styled.div`
    display: flex;
    align-items: center;
    button {
        margin-right: 30px;

        &:last-child {
            margin-right: 0;
        }
    }
`

const ShowMeWrapper = styled.div`
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    line-height: 24px;
    display: flex;
    align-items: center;
    margin: 0 30px;
`

const InputWrapper = styled.div<{ isMyProposal?: boolean }>`
    width: 312px;
    height: 40px;
    background: #ffffff;
    border-radius: 4px;
    overflow: hidden;

    display: flex;
    align-items: center;
    padding: 0 12px;
    margin-right: ${({ isMyProposal }) => (isMyProposal ? '30px' : '')};
    img {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
`

const TableNoDataStyle = styled.div<{ isNoData: boolean }>`
    width: 100%;
    height: 100%;
    background: #fff;
    display: flex;
    flex-flow: column;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    height: 619px;
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.text6};
    img {
        width: ${({ isNoData }) => (isNoData ? '160' : '157')}px;
        height: ${({ isNoData }) => (isNoData ? '126' : '145')}px;
        margin-bottom: 21px;
    }
`

const UserInfoWrapper = styled.div`
    display: flex;
    align-items: center;
`

const Avatar = styled(Logo)`
    margin-right: 16px;
`

const UserInfoRight = styled.div`
    display: flex;
    flex-flow: column;
`
const StartTime = styled.div`
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
`
const EndTime = styled.div`
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
`
const ProposalStatus = styled(TYPE.black)`
    font-size: 14px;
    font-weight: 400;
    &.is-voting {
        color: #f9ae3f;
    }
    &.is-success {
    }
    &.is-fail {
        color: #fc4141;
    }
`

const TableNoData = ({ isNoData }: { isNoData: boolean }) => {
    const theme = useContext(ThemeContext)
    return (
        <TableNoDataStyle isNoData={isNoData}>
            <img src={isNoData ? NoData : NoSearch} />
            <TYPE.body color={theme.text6}>{isNoData ? '暂无信息' : '搜索结果为空'}</TYPE.body>
        </TableNoDataStyle>
    )
}

const loadingIcon = (color: string) => (
    <LoadingOutlined
        style={{
            fontSize: 30,
            color,
        }}
        spin
    />
)

const useGSApproveCallback = () => {
    const { chainId } = useWeb3React()
    const currencyToken = useCurrency(DEFAULT_CHAINID_TOKEN_LIST[0].address)
    const [approval, approveCallback] = useApproveCallback(
        tryParseAmount(MaxUint256, currencyToken),
        GS_COUNCIL_ADDRESS[chainId as ChainId],
        APPROVE_BASE_AMOUNT
    )

    return {
        approval,
        approveCallback,
    }
}

interface TableInfterface<T> {
    columns: ColumnsType<T>
    className?: string
    width?: string
    onChange?: () => void
    rowKey: string
    url: string
    hasShowMe?: boolean
    tabs?: Tab[]
    pla?: string
    checkStatus?: boolean
    setCheckStatus?: (status: boolean) => void
    setIndex?: (params: Tab) => void
    rightSlot?: React.ReactNode
    isMyProposal?: boolean
    searchParams?: Record<string, any>
    defaultActiveKey?: string
}
interface KudosProps {
    agreeNumber: number | string
    disagreeNumber: number | string
}
interface CouncliRecord {
    address: string
    nickName: string
    headUrl: string
    myVotesNumber: string
}

const OperationWrapper = styled.span`
    .operation-icon:not(:last-of-type) {
        margin-right: 50px;
    }
`
interface OperationIconProps {
    src: string
    tip?: string
    disabled?: boolean
    fill?: string
    style?: React.CSSProperties
    onClick?: (...args: any[]) => any
}
const OperationIcon = ({ src, tip, onClick, disabled, fill = '', style = {} }: OperationIconProps) => {
    const handleIconClick = useCallback(() => {
        !disabled && onClick && onClick()
    }, [])
    return (
        <Tooltip title={tip}>
            <Icon
                className="operation-icon"
                component={SVGCom({ src }) as any}
                onClick={handleIconClick}
                style={{
                    ...style,
                    fill: disabled ? '#cdcdcd' : fill,
                    cursor: disabled ? 'not-allowed' : onClick ? 'pointer' : 'unset',
                }}
            />
        </Tooltip>
    )
}

function CustomTable<T extends Object>({
    columns,
    url,
    tabs,
    hasShowMe,
    pla,
    rightSlot,
    setIndex,
    checkStatus,
    setCheckStatus,
    isMyProposal,
    searchParams,
    defaultActiveKey,
    ...otherConfig
}: TableInfterface<T>) {
    const [isSearch, setIsSearch] = useState(false)
    const [status, setStatus] = useState(checkStatus)
    const [value, setValue] = useState('')
    const { paginationConfig, changePage, doSearch, tableData, loading, setTableData } = useTable<T>({
        url,
        current: 1,
        pageSize: 10,
    })
    const theme = useContext(ThemeContext)
    const changeCheckStatus = useCallback(
        (checked: boolean) => {
            setTableData([])
            setCheckStatus?.(checked)
            setStatus(checked)
            handleSearch({ owner: checked ? 1 : 0 })
        },
        [setCheckStatus, setStatus, doSearch, value, setTableData]
    )

    const history = useHistory()
    const changeTabs = useCallback(
        (activeKey: string) => {
            if (!tabs?.length) return
            if (isMyProposal) {
                history.replace(`/MyProposal/${+activeKey - 1}`)
            }

            setIndex?.(tabs[+activeKey - 1])
        },
        [setIndex, doSearch, value, history]
    )

    const handleSearch = useCallback(
        (params: Record<string, any> = {}) => {
            if (url) {
                doSearch({
                    keyWord: value,
                    owner: status ? 1 : 0,
                    ...params,
                })
                setIsSearch(!!value)
            }
        },
        [doSearch, setIsSearch, value, searchParams, status]
    )

    useEffect(() => {
        changeTabs(String(defaultActiveKey))
    }, [defaultActiveKey])

    return (
        <TableContainer>
            <TopContainer>
                {tabs?.length && (
                    <Tabs
                        type="card"
                        activeKey={defaultActiveKey}
                        hideAdd
                        items={tabs?.map(({ label, key }) => ({ label, key }))}
                        onChange={changeTabs}
                    />
                )}
                <RightContainer>
                    <InputWrapper isMyProposal={isMyProposal}>
                        <Input
                            noValide
                            value={value}
                            fontSize={'16px'}
                            width={'312px'}
                            onUserInput={(v: string) => setValue(v)}
                            placeholder={pla}
                        />
                        <img src={SearchIcon} alt="" onClick={() => handleSearch(undefined)} />
                    </InputWrapper>
                    {hasShowMe && (
                        <ShowMeWrapper>
                            <TYPE.body color="rgba(0,0,0,0.6)" marginRight={'8px'}>
                                仅展示我的投票
                            </TYPE.body>
                            <Switch checked={status} onChange={changeCheckStatus} />
                        </ShowMeWrapper>
                    )}
                    {rightSlot}
                </RightContainer>
            </TopContainer>
            {tableData.length ? (
                <Table<T>
                    columns={columns}
                    dataSource={tableData}
                    pagination={paginationConfig}
                    onChange={changePage}
                    loading={{ size: 'default', indicator: loadingIcon(theme.primary1), spinning: loading }}
                    {...otherConfig}
                />
            ) : (
                <TableNoData isNoData={!isSearch} />
            )}
        </TableContainer>
    )
}

export default CustomTable

export const Kudos = ({ agreeNumber, disagreeNumber }: KudosProps) => {
    const theme = useContext(ThemeContext)
    return (
        <>
            <Text color={theme.primary1} display={'inline-block'}>
                {agreeNumber}
            </Text>
            /{disagreeNumber}
        </>
    )
}

export const RightBtns = ({ isMyProposal, isMP }: { isMyProposal?: boolean; isMP?: boolean }) => {
    const [isOpen, setIsOpen] = useState(false)
    const history = useHistory()
    const btnStyle = {
        width: '98px',
        height: '40px',
        fontSize: '16px',
    }
    const { account } = useWeb3React()
    const handleClick = useCallback(
        (url) => {
            url && history.push(url)
        },
        [history]
    )

    const onClickMyCampaign = useCallback(() => {
        if (typeof isMP === 'undefined') return
        isMP ? handleClick(`/DirectorInfo/${account}`) : setIsOpen(true)
    }, [setIsOpen, isMP, handleClick, account])

    const isOverView = useMemo(() => history.location.pathname === '/Overview', [history])
    const isCouncil = useMemo(() => history.location.pathname === '/Council', [history])

    return isOverView ? (
        <></>
    ) : (
        <>
            {isCouncil ? (
                <>
                    <ButtonOrange {...btnStyle} fontSize={'16px'} onClick={onClickMyCampaign}>
                        我的竞选
                    </ButtonOrange>
                    <ModalConfirmed
                        isOpen={isOpen}
                        onDismiss={() => setIsOpen(false)}
                        confirmText="申请成为议员"
                        onConfirm={() => history.push('/ToBeDirector')}
                    >
                        您还不是议员，快去申请成为议员吧
                    </ModalConfirmed>
                </>
            ) : (
                <>
                    <ButtonConfirmed {...btnStyle} onClick={() => handleClick('/SponsorMotion')}>
                        发起提案
                    </ButtonConfirmed>
                    {!isMyProposal && (
                        <ButtonConfirmed
                            {...btnStyle}
                            backgroundColor={'#02BC9B'}
                            onClick={() => handleClick('MyProposal')}
                        >
                            我的提案
                        </ButtonConfirmed>
                    )}
                </>
            )}
        </>
    )
}

export const AccountInfo = ({ address, nickName, headUrl }: { address: string; nickName: string; headUrl: string }) => {
    return (
        <UserInfoWrapper>
            <Avatar src={headUrl} />
            <UserInfoRight>
                <TYPE.black>{nickName}</TYPE.black>
                <TYPE.black>{address && shortenAddress(address)}</TYPE.black>
            </UserInfoRight>
        </UserInfoWrapper>
    )
}
interface VoteTableProps {
    isMyProposal?: boolean
    hasShowMe?: boolean
    isCouncil?: boolean
    pla?: string
    url: string
    hasTitle?: boolean
    activeKey?: number
    tabChange?: (p: Tab, data: { label: string; index: number }) => any
    searchParams?: Record<string, any>
    defaultCheckStatus?: boolean
    title?: string
    defaultIndex?: number
    rowKey?: string
    isMP?: boolean
}

//
/**
 * index： 0 全民投票阶段 1 理事会投票阶段 2 节点投票阶段
 * 状态：1、提案上链成功 2、失败-反对人数多 3、成功
 */
const proposalStatusClass = ['', 'is-voting', 'is-success', 'is-fail']
const proposalStatusText = [
    ['', '投票中', '成功', '已关闭'],
    ['', '投票中', '失败', '成功'],
    ['', '投票中', '失败', '成功'],
]
export function VoteTable<T extends Record<string, any>>({
    isMP,
    isMyProposal,
    hasShowMe,
    isCouncil,
    pla,
    url,
    hasTitle,
    tabChange,
    searchParams,
    defaultCheckStatus,
    title,
    defaultIndex,
    rowKey = 'id',
}: VoteTableProps) {
    // 是否仅展示我的投票
    // const theme = useContext(ThemeContext)
    const [checkStatus, setCheckStatus] = useState(!!defaultCheckStatus)
    // index： 0 全民投票阶段 1 理事会投票阶段 2 节点投票阶段
    const [index, setIndex] = useState(defaultIndex || 0)

    //是否打开modal
    const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState(false)
    const [isOpen1, setIsOpen1] = useState(false)
    const [canvassIsOpen, setCanvassIsOpen] = useState(false)
    const [voteToDirectorIsOpen, setVoteToDirectorIsOpen] = useState(false)
    const [backVoteIsOpen, setBackVoteIsOpen] = useState(false)

    const [hash, setHash] = useState<string>('')
    const [selectedDirectorInfo, setSelectedDirectorInfo] = useState({
        address: '',
        myVotesNumber: '0',
    })

    const [errorMessage, setErrorMessage] = useState('')
    const addTransaction = useTransactionAdder()
    const { chainId, account } = useWeb3React()
    const [selectedRowInfo, setSelectedRowInfo] = useState<{
        contractAddress?: string
        myVotesNumber?: string | number
        proposalAmount?: string | number
        id?: number
        [key: string]: any
    }>({})

    const referendumsFactorContract = useContract(GS_REFERENDUMS_FACTORY_ADDRESS[chainId as ChainId], ReferendumsFactor)
    const councilContract = useContract(GS_COUNCIL_ADDRESS[chainId as ChainId], Council)

    const histroy = useHistory()
    const handleClick = useCallback(
        (url: string) => {
            histroy.push(url)
        },
        [histroy]
    )
    const [, setCopied] = useCopyClipboard()
    const copyText = useCallback((text: string) => {
        setCopied(text)
        message.success('复制成功')
    }, [])

    /**
     * @description 点击 我的提案-全民投票-取消投票
     */
    const handleWithdrawClick = useCallback((record) => {
        setSelectedRowInfo(record)
        setIsOpen1(true)
    }, [])
    /**
     * @description 提交 我的提案-全民投票-取消投票
     */
    const handleSubmitWithdrawClick = useCallback(async () => {
        if (referendumsFactorContract) {
            try {
                setIsOpen(true)
                setAttemptingTxn(true)
                const { contractAddress, id } = selectedRowInfo
                const response = await referendumsFactorContract.withdraw(contractAddress, id)
                setIsOpen1(false)
                setAttemptingTxn(false)
                setHash(response.hash)
                addTransaction(response, {
                    summary: '取回投票',
                })
            } catch (err: any) {
                setIsOpen(false)
                setAttemptingTxn(false)
                setErrorMessage(err.message)
                console.log(err.message)
            }
        }
    }, [referendumsFactorContract, setHash, addTransaction, selectedRowInfo])

    /**
     * @description 关闭提案
     */
    const handleCloseProposalClick = useCallback((record) => {
        setSelectedRowInfo(record)
        setCanvassIsOpen(true)
    }, [])
    const handleSubmitCloseProposalClick = useCallback(async () => {
        if (referendumsFactorContract) {
            try {
                setIsOpen(true)
                setAttemptingTxn(true)
                const response = await referendumsFactorContract.closeProposal(selectedRowInfo.contractAddress)
                setAttemptingTxn(false)
                setCanvassIsOpen(false)
                setHash(response.hash)
                addTransaction(response, {
                    summary: '关闭提案成功',
                })
            } catch (err: any) {
                setIsOpen(false)
                setAttemptingTxn(false)
                setErrorMessage(err?.data?.message || err.message || '关闭提案失败')
            }
        }
    }, [
        setCanvassIsOpen,
        setAttemptingTxn,
        referendumsFactorContract,
        selectedRowInfo,
        setHash,
        addTransaction,
        setErrorMessage,
    ])

    /**
     * @description 点击 理事会-当选议员、候选议员-投票
     */
    const handleVoteToDirectorClick = useCallback(
        async (record: CouncliRecord) => {
            setSelectedDirectorInfo({ ...selectedDirectorInfo, address: record.address })
            setVoteToDirectorIsOpen(true)
        },
        [councilContract, account]
    )
    const { approval, approveCallback } = useGSApproveCallback()

    /**
     * @description 提交 理事会-当选议员、候选议员-投票
     */
    const handleVoteToDirectorSubmit = useCallback(
        async (count: string) => {
            if (councilContract && !!selectedDirectorInfo.address) {
                try {
                    setAttemptingTxn(true)
                    setIsOpen(true)
                    if (approval === ApprovalState.NOT_APPROVED) {
                        const response = await approveCallback()
                        response && (await response.wait())
                    }

                    const {
                        data: { prevMember1, prevMember2 },
                    } = await ajaxPost('fetchAddressPosition', { address: account, type: 'vote', amount: count })

                    const { hash } = await councilContract?.vote(
                        selectedDirectorInfo.address,
                        [prevMember1, prevMember2],
                        count
                    )
                    setAttemptingTxn(false)
                    setVoteToDirectorIsOpen(false)
                    setHash(hash)
                    addTransaction(
                        {
                            hash,
                        } as any,
                        {
                            summary: '议员投票成功！',
                        }
                    )
                } catch (err: any) {
                    setAttemptingTxn(false)
                    setIsOpen(false)
                    setErrorMessage(err?.data?.mesasge || err?.message || '议员投票失败')
                }
            }
        },
        [
            councilContract,
            setAttemptingTxn,
            approveCallback,
            setIsOpen,
            approval,
            account,
            setHash,
            addTransaction,
            selectedDirectorInfo,
        ]
    )

    /**
     * @description 取消给议员投票modal
     */
    const handleBackVoteClick = useCallback(
        async (record: CouncliRecord) => {
            setSelectedDirectorInfo({ address: record.address, myVotesNumber: record.myVotesNumber })
            setBackVoteIsOpen(true)
        },
        [councilContract, setAttemptingTxn, setIsOpen, account, setHash, addTransaction, selectedDirectorInfo]
    )
    /**
     * @description 确认取消给议员投票
     */
    const handleBackVoteSubmit = useCallback(async () => {
        if (councilContract) {
            try {
                setAttemptingTxn(true)
                setIsOpen(true)
                if (approval === ApprovalState.NOT_APPROVED) {
                    const response = await approveCallback()
                    response && (await response.wait())
                }
                const {
                    data: { prevMember1, prevMember2 },
                } = await ajaxPost('fetchAddressPosition', { address: account, type: 'back', amount: 3 })
                console.log(selectedDirectorInfo.address, [prevMember1, prevMember2])
                const { hash } = await councilContract.back(selectedDirectorInfo.address, [prevMember1, prevMember2])
                setAttemptingTxn(false)
                setBackVoteIsOpen(false)
                setHash(hash)
                addTransaction(
                    {
                        hash,
                    } as any,
                    {
                        summary: '取消议员投票成功！',
                    }
                )
            } catch (err: any) {
                console.log(err.message)
                setAttemptingTxn(false)
                setIsOpen(false)
                setErrorMessage(err.message)
            }
        }
    }, [
        approval,
        approveCallback,
        councilContract,
        setAttemptingTxn,
        setIsOpen,
        account,
        setHash,
        addTransaction,
        selectedDirectorInfo,
        setErrorMessage,
    ])

    /**
     * @description 领取提案金|申请资金
     * @params method 合约方法 : receivePledgeAmount 领取提案金，receiveApplyAmount 领取申请金
     * @params address 合约地址
     */
    const withdrawProposalOrAppliedFund = useCallback(
        async (method: 'receivePledgeAmount' | 'receiveApplyAmount', address: string) => {
            if (!referendumsFactorContract) return
            const msg = {
                receivePledgeAmount: '领取提案金',
                receiveApplyAmount: '领取申请金',
            }[method]

            console.log(method, address)

            try {
                setIsOpen(true)
                setAttemptingTxn(true)
                const response = await referendumsFactorContract[method](address)
                setAttemptingTxn(false)
                setHash(response.hash)
                addTransaction(response, {
                    summary: `${msg}成功`,
                })
            } catch (err: any) {
                setIsOpen(false)
                setAttemptingTxn(false)
                setErrorMessage(err?.data?.message || err?.message || `${msg}失败`)
            }
        },
        [referendumsFactorContract, setIsOpen, setAttemptingTxn, setHash, addTransaction, setErrorMessage]
    )

    const blockBrowserAddress = BLOCK_BROSWER_ADDRESS[chainId as ChainId]
    const handleOpenProposalClick = useCallback(async (contractAddress) => {
        window.open(`${blockBrowserAddress}/address/${contractAddress}`, '__blank')
    }, [])

    const columns: ColumnsType<T> = useMemo(() => {
        const statusCol =
            isMyProposal || checkStatus
                ? [
                      {
                          title: '提案状态',
                          dataIndex: 'status',
                          key: 'status',
                          render: ((text, record) => (
                              <ProposalStatus className={proposalStatusClass[record.status]}>
                                  {proposalStatusText[index][record.status]}
                              </ProposalStatus>
                          )) as (value: any, record: any, index: number) => any,
                      },
                  ]
                : []

        const voteCountCol = isMyProposal
            ? [
                  // {
                  //     title: '票数',
                  //     dataIndex: 'count',
                  //     key: 'count',
                  // },
              ]
            : []

        const actionCol = [
            {
                title: '操作',
                dataIndex: 'action',
                key: 'name',
                // align: 'center',
                render: ((text, record) => (
                    <OperationWrapper>
                        {
                            // 1、仅在所有全民投票时才展示（状态：1、提案上链成功 2、成功-提案进入到理事会投票 3、提案关闭）
                            // 2、仅在理事会投票且状态不为失败时才展示（状态：1、提案上链成功 2、失败-反对人数多 3、成功-提案进入到节点投票合约）
                            // 3、仅在节点投票时才展示（状态：1、投票中 2、失败-反对人数多 3、成功-领取提案金）
                            index === 0 || (index === 1 && record.status !== 2) || index === 2 ? (
                                <OperationIcon
                                    src="VoteSvg"
                                    tip="投票"
                                    // 1、仅在所有全民投票且(状态是提案关闭或提案完成时)才disabled（状态：1、提案上链成功 2、成功-提案进入到理事会投票 3、提案关闭）
                                    // 2、仅在理事会投票且(状态为失败或状态为成功-提案进入到节点投票合约或有了投票反向)时才disabled（状态：1、提案上链成功 2、失败-反对人数多 3、成功-提案进入到节点投票合约）
                                    // 3、仅在节点投票且（状态为失败或状态成功或有了投票反向）时才disabled（状态：1、投票中 2、失败-反对人数多 3、成功-领取提案金）
                                    disabled={
                                        (index === 0 && (record.status === 2 || record.status === 3)) ||
                                        (index === 1 &&
                                            (record.status === 2 || record.status === 3 || record.voterStatus)) ||
                                        (index === 2 &&
                                            (record.status === 2 || record.status === 3 || record.voterStatus))
                                    }
                                    onClick={() => handleClick(`/DoVote/${index}/${record.id}`)}
                                />
                            ) : (
                                <></>
                            )
                        }
                        {/* 仅在所有全民投票时展示 */}
                        {index ? (
                            <></>
                        ) : (
                            <OperationIcon
                                src="CanvassSvg"
                                tip="拉票"
                                // marginRight={'51px'}
                                // 1、仅在所有全民投票且(状态是提案关闭或提案完成时)才disabled（状态：1、提案上链成功 2、成功-提案进入到理事会投票 3、提案关闭）
                                disabled={index === 0 && (record.status === 2 || record.status === 3)}
                                onClick={() => copyText(`${window.location.origin}/#/DoVote/${index}/${record.id}`)}
                            />
                        )}

                        {
                            // 我的提案&&提案者为当前钱包地址
                            // 1、理事会投票&&失败
                            // 2、节点投票&&成功
                            (isMyProposal && index === 1 && record.status === 2) ||
                            (isMyProposal && index === 2 && record.status === 3) ? (
                                <OperationIcon
                                    src={
                                        !!record.isReceiveAmount
                                            ? 'DisableReceiveRequestedAmountSvg'
                                            : 'ReceiveProposalAmountSvg'
                                    }
                                    tip="领取提案金"
                                    fill="#F9AE3F"
                                    disabled={!!record.isReceiveAmount}
                                    onClick={() =>
                                        withdrawProposalOrAppliedFund('receivePledgeAmount', record.contractAddress)
                                    }
                                />
                            ) : (
                                <></>
                            )
                        }
                        {
                            // 仅在我的提案-节点投票且状态成功时才展示（状态：1、投票中 2、失败-反对人数多 3、成功-领取提案金）
                            isMyProposal && index === 2 && record.status === 2 && record.status === 3 ? (
                                <OperationIcon
                                    src="ReceiveRequestedAmountSvg"
                                    tip="领取申请资金"
                                    disabled={!!record.isReceiveApplyAmount}
                                    onClick={() =>
                                        withdrawProposalOrAppliedFund('receiveApplyAmount', record.contractAddress)
                                    }
                                />
                            ) : (
                                <></>
                            )
                        }
                        {
                            // 仅在仅展示我的投票-全民投票或我的提案-全民投票时才展示（状态：1、提案上链成功 2、成功-提案进入到理事会投票 3、提案关闭）
                            (checkStatus || isMyProposal) && index === 0 ? (
                                <OperationIcon
                                    src="CancelVoteSvg"
                                    tip="取消投票"
                                    disabled={!record.myVotesNumber}
                                    onClick={() => handleWithdrawClick(record)}
                                />
                            ) : (
                                <></>
                            )
                        }
                        {
                            // 仅在全民投票且我的提案时才展示
                            index === 0 && isMyProposal ? (
                                <OperationIcon
                                    src="CloseProposal"
                                    tip="关闭提案"
                                    disabled={[2, 3].includes(record.status)}
                                    onClick={() => handleCloseProposalClick(record)}
                                />
                            ) : (
                                <></>
                            )
                        }
                    </OperationWrapper>
                )) as (value: any, record: any, index: number) => any,
            },
        ]

        const voteTypeCol = {
            title: '类型',
            dataIndex: 'originId',
            render: (_: any, record: any) =>
                ({ 0: '小额支出', 1: '中等支出', 2: '大额支出' }[record.originId as 0 | 1 | 2]),
        }

        const universalCol = [
            {
                title: '创建时间',
                dataIndex: 'startTime',
                key: 'startTime',
            },
            ...statusCol,
            {
                title: '票数',
                dataIndex: 'votesNumber',
                key: 'votesNumber',
            },
            voteTypeCol,
            ...voteCountCol,
            ...actionCol,
        ]

        const directionCol = [
            {
                title: '投票方向',
                dataIndex: 'voterStatus',
                key: 'voterStatus',
                render: ((text, record) => (
                    <OperationIcon
                        src={record.voterStatus === 1 ? 'ApproveSvg' : 'DisapproveSvg'}
                        tip={record.voterStatus === 1 ? '赞成' : '反对'}
                    />
                )) as (value: any, record: any, index: number) => any,
            },
        ]

        const councilOrNodeCol: ColumnsType<T> = [
            voteTypeCol,
            {
                title: '开始时间-截止时间',
                dataIndex: 'time',
                render: (text, record) => (
                    <>
                        <StartTime>{(record as any).startTime}</StartTime>
                        <EndTime>{(record as any).endTime}</EndTime>
                    </>
                ),
            },
            ...statusCol,
            ...voteCountCol,
            ...[
                {
                    title: '赞成/反对',
                    dataIndex: 'count',
                    width: 200,
                    key: 'count',
                    render: ((text, record) => (
                        <Kudos agreeNumber={record.agreeNumber} disagreeNumber={record.disagreeNumber} />
                    )) as (value: any, record: any, index: number) => any,
                },
            ],
            ...(checkStatus ? directionCol : []),
            ...(checkStatus ? [] : actionCol),
        ]

        // 是否是理事会页面（议员列表）
        return isCouncil
            ? [
                  {
                      title: '实时排名',
                      dataIndex: 'rank',
                      key: 'rank',
                      width: 100,
                      render: ((text, record, index) => <>{index + 1}</>) as (
                          value: any,
                          record: any,
                          index: number
                      ) => any,
                  },
                  {
                      title: '昵称',
                      dataIndex: 'name',
                      key: 'name',
                      width: 400,
                      render: ((text, record) => (
                          <AccountInfo address={record.address} nickName={record.nickName} headUrl={record.headUrl} />
                      )) as (value: any, record: any, index: number) => any,
                  },
                  {
                      title: '当前票数',
                      dataIndex: 'voteNumber',
                      key: 'voteNumber',
                  },
                  // 是否是仅展示我的投票
                  ...(checkStatus
                      ? [
                            {
                                title: '我的投票',
                                dataIndex: 'myVoteNumber',
                                key: 'myVoteNumber',
                            },
                        ]
                      : []),
                  {
                      title: '操作',
                      dataIndex: 'action',
                      key: 'name',
                      render: ((text, record) => (
                          <OperationWrapper>
                              <OperationIcon
                                  src="VoteSvg"
                                  tip="投票"
                                  onClick={() => handleVoteToDirectorClick(record)}
                              />
                              {!isMyProposal ? (
                                  <OperationIcon
                                      src="CancelVoteSvg"
                                      tip="取消投票"
                                      disabled={!record.myVotesNumber}
                                      onClick={() => handleBackVoteClick(record)}
                                  />
                              ) : (
                                  <></>
                              )}
                              <OperationIcon
                                  src="PreviewDirectorSvg"
                                  tip="查看议员"
                                  onClick={() => handleClick('/DirectorInfo/' + record.address)}
                              />
                          </OperationWrapper>
                      )) as (value: any, record: any, index: number) => any,
                  },
              ]
            : [
                  {
                      title: '编号',
                      dataIndex: 'id',
                      key: 'id',
                  },
                  {
                      title: '提案者',
                      dataIndex: 'address',
                      key: 'address',
                      render: ((text, record) => (
                          <TYPE.black
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleOpenProposalClick(record.contractAddress)}
                          >
                              {record.address && shortenAddress(record.address, 6)}
                          </TYPE.black>
                      )) as (value: any, record: any, index: number) => any,
                  },
                  {
                      title: '提案内容',
                      dataIndex: 'title',
                      key: 'title',
                      render: ((text, record) => (
                          <TYPE.black
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleClick(`/DoVote/${index}/${record.id}/1`)}
                          >
                              {record.title}
                          </TYPE.black>
                      )) as (value: any, record: any, index: number) => any,
                  },
                  ...(!index ? universalCol : councilOrNodeCol),
              ]
    }, [index, checkStatus, isMyProposal])

    const tabs = useMemo<Array<Tab>>(() => {
        return isCouncil
            ? [
                  {
                      label: '当选议员',
                      key: '1',
                  },
                  {
                      label: '候选议员',
                      key: '2',
                  },
              ]
            : [
                  {
                      label: '全民投票',
                      key: '1',
                  },
                  {
                      label: '理事会投票',
                      key: '2',
                  },
                  {
                      label: '节点投票',
                      key: '3',
                  },
              ]
    }, [isCouncil])

    const handleTabIndexChange = useCallback(
        (p: Tab) => {
            setIndex((+p.key - 1) as number)
            tabChange &&
                tabChange(p, {
                    index: +p.key - 1,
                    label: tabs[+p.key - 1].label as string,
                })
        },
        [tabChange, setIndex]
    )

    useEffect(() => {
        if (defaultIndex !== index) {
            setIndex(defaultIndex || 0)
        }
    }, [defaultIndex])

    return (
        <>
            {(!isBoolean(hasTitle) || hasTitle === true) && !isMyProposal && !isCouncil && (
                <Title>{title || (tabs[index].label as string)}</Title>
            )}
            <CustomTable
                pla={pla}
                checkStatus={checkStatus}
                setCheckStatus={(status) => setCheckStatus(status)}
                hasShowMe={hasShowMe}
                setIndex={handleTabIndexChange}
                defaultActiveKey={String(index + 1)}
                columns={columns}
                url={url}
                rowKey={rowKey}
                tabs={tabs}
                isMyProposal={isMyProposal}
                searchParams={searchParams}
                rightSlot={<RightBtns isMyProposal={isMyProposal} isMP={isMP} />}
            />
            <TransactionConfirmationModal
                isOpen={isOpen}
                attemptingTxn={attemptingTxn}
                hash={hash}
                onDismiss={() => setIsOpen(false)}
                content={() => <>在你的钱包中确认这笔交易</>}
            />
            {/* 取消全民投票 */}
            <ModalConfirmed isOpen={isOpen1} onDismiss={() => setIsOpen1(false)} onConfirm={handleSubmitWithdrawClick}>
                <TYPE.subHeader>投票数量：{selectedRowInfo.myVotesNumber}</TYPE.subHeader>
                <TYPE.subHeader>此操作将取消给提案的全部投票，是否继续</TYPE.subHeader>
            </ModalConfirmed>
            {/* 取消议员投票 */}
            <ModalConfirmed
                isOpen={backVoteIsOpen}
                onDismiss={() => setBackVoteIsOpen(false)}
                onConfirm={handleBackVoteSubmit}
            >
                <TYPE.subHeader>投票数量：{selectedDirectorInfo.myVotesNumber}</TYPE.subHeader>
                <TYPE.subHeader>此操作将取消给此议员的全部投票，是否继续？</TYPE.subHeader>
            </ModalConfirmed>
            {/* 取消提案 */}
            <ModalConfirmed
                isOpen={canvassIsOpen}
                onDismiss={() => setCanvassIsOpen(false)}
                onConfirm={handleSubmitCloseProposalClick}
            >
                <TYPE.subHeader>提案金：{selectedRowInfo.proposalAmount}</TYPE.subHeader>
                <TYPE.subHeader>此操作将关闭提案取回提案金，提案关闭后，其他用户将无法投票，是否继续？</TYPE.subHeader>
            </ModalConfirmed>
            <ModalVote
                isModal
                token="GS"
                submit={handleVoteToDirectorSubmit}
                isOpen={voteToDirectorIsOpen}
                onDismiss={() => setVoteToDirectorIsOpen(false)}
            />
            <Modal isOpen={!!errorMessage} onDismiss={() => setErrorMessage('')} maxHeight={90}>
                <TransactionErrorContent message={errorMessage} onDismiss={() => setErrorMessage('')} />
            </Modal>
        </>
    )
}
