import { darken, lighten } from 'polished'
import React from 'react'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'
import styled from 'styled-components'

import { RowBetween } from '../Row'

export const Base = styled(RebassButton)<{
    height?: string
    width?: string
    borderRadius?: string
    altDisabledStyle?: boolean
    fontSize?: string
}>`
    height: ${({ height }) => height || '48px'};
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ width }) => (width ? width : '100%')};
    font-weight: 500;
    text-align: center;
    border-radius: 4px;
    border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
    outline: none;
    font-size: ${({ fontSize }) => fontSize || '18px'};
    font-weight: 400;
    line-height: 1;
    border: 1px solid transparent;
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
    transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
    &:disabled {
        background-color: ${({ theme }) => theme.bg3};
        border: 1px solid transparent;
        color: white;
        cursor: auto;
        /* box-shadow: none;
    border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.7' : '1')}; */
    }

    > * {
        user-select: none;
    }
`

export const ButtonPrimary = styled(Base)<{ backgroundColor?: string; color?: string; border?: string }>`
    background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.primary1};
    color: ${({ color }) => color || 'white'};
    border: ${({ border }) => border};
    transition: all 0.2s ease;
    &:disabled {
        opacity: 0.65;
        cursor: auto;
    }
    &:focus {
        background-color: ${({ theme, backgroundColor }) => darken(0.05, backgroundColor || theme.primary1)};
    }
    &:hover {
        background-color: ${({ theme, backgroundColor }) => darken(0.05, backgroundColor || theme.primary1)};
    }
    &:active:enabled {
        background-color: ${({ theme, backgroundColor }) => darken(0.1, backgroundColor || theme.primary1)};
    }
    &:disabled {
        background-color: ${({ theme, altDisabledStyle, backgroundColor }) =>
            altDisabledStyle ? backgroundColor || theme.primary1 : theme.bg3};
        color: ${({ color }) => color || 'white'};
        cursor: auto;
        box-shadow: none;
        border: 1px solid transparent;
        outline: none;
        opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.65' : '1')};
    }
`

export const ButtonLight = styled(Base)`
    background-color: ${({ theme }) => theme.primary1};
    color: ${({ theme }) => theme.primaryText1};
    font-size: 16px;
    font-weight: 500;
    &:focus {
        opacity: 0.65;
    }
    &:hover {
        opacity: 0.65;
    }
    &:active:enabled {
        opacity: 0.65;
    }
    :disabled {
        /* opacity: 0.4; */
        :hover {
            cursor: auto;
            background-color: ${({ theme }) => theme.primary1};
            box-shadow: none;
            border: 1px solid transparent;
            outline: none;
        }
    }
`

export const ButtonGray = styled(Base)`
    background-color: ${({ theme }) => theme.bg3};
    color: ${({ theme }) => theme.text2};
    font-weight: 500;
    &:focus {
        background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
    }
    &:hover {
        background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg2)};
    }
    &:active:enabled {
        background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg2)};
    }
`

export const ButtonSecondary = styled(Base)`
    background-color: ${({ theme }) => theme.primary2};
    color: ${({ theme }) => theme.primaryText1};
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.primary1};
    &:focus {
        /* background-color: ${({ theme }) => theme.primary3}; */
    }
    &:hover {
        background-color: ${({ theme }) => theme.primary3};
    }
    &:active:enabled {
        background-color: ${({ theme }) => theme.primary3};
    }
    &:disabled {
        :hover {
            cursor: auto;
            background-color: ${({ theme }) => theme.bg3};
        }
        cursor: auto;
    }
`

export const ButtonOrange = styled(Base)`
    background-color: ${({ theme }) => theme.primary4};
    color: white;

    &:focus {
        background-color: ${({ theme }) => darken(0.05, theme.primary4)};
    }
    &:hover {
        background-color: ${({ theme }) => darken(0.05, theme.primary4)};
    }
    &:active:enabled {
        background-color: ${({ theme }) => darken(0.1, theme.primary4)};
    }
    &:disabled {
        background-color: ${({ theme }) => theme.primary4};
        opacity: 0.65;
        cursor: auto;
    }
`

export const ButtonOutlined = styled(Base)`
    border: 1px solid ${({ theme }) => theme.bg2};
    background-color: transparent;
    color: ${({ theme }) => theme.text1};

    &:focus {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
    }
    &:hover {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
    }
    &:active:enabled {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.bg4};
    }
    &:disabled {
        opacity: 0.65;
        cursor: auto;
    }
`

export const ButtonEmpty = styled(Base)`
    background-color: transparent;
    color: ${({ theme }) => theme.primary1};
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus {
        background-color: ${({ theme }) => theme.advancedBG};
    }
    &:hover {
        background-color: ${({ theme }) => theme.advancedBG};
    }
    &:active:enabled {
        background-color: ${({ theme }) => theme.advancedBG};
    }
    &:disabled {
        opacity: 0.65;
        cursor: auto;
    }
`

export const ButtonWhite = styled(Base)`
    border: 1px solid #edeef2;
    background-color: ${({ theme }) => theme.bg1};
    color: black;

    &:focus {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        box-shadow: 0 0 0 1pt ${darken(0.05, '#edeef2')};
    }
    &:hover {
        box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
    }
    &:active:enabled {
        box-shadow: 0 0 0 1pt ${darken(0.1, '#edeef2')};
    }
    &:disabled {
        opacity: 0.65;
        cursor: auto;
    }
`

const ButtonConfirmedStyle = styled(Base)`
    background-color: ${({ theme }) => lighten(0.5, theme.green1)};
    color: ${({ theme }) => theme.green1};
    border: 1px solid ${({ theme }) => theme.green1};
    transition: all 0.2s ease;
    &:disabled {
        opacity: 0.65;
        cursor: auto;
    }
    &:hover {
        transform: scale(0.7);
    }
`

const ButtonErrorStyle = styled(Base)`
    background-color: ${({ theme }) => theme.red1};
    border: 1px solid ${({ theme }) => theme.red1};

    &:focus {
        box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.red1)};
        background-color: ${({ theme }) => darken(0.05, theme.red1)};
    }
    &:hover {
        background-color: ${({ theme }) => darken(0.05, theme.red1)};
    }
    &:active:enabled {
        box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red1)};
        background-color: ${({ theme }) => darken(0.1, theme.red1)};
    }
    &:disabled {
        opacity: 0.65;
        cursor: auto;
        box-shadow: none;
        background-color: ${({ theme }) => theme.red1};
        border: 1px solid ${({ theme }) => theme.red1};
    }
`

export const ButtonText = styled(Base)<{ color?: string; time?: number; disabled?: boolean }>`
    background: transparent;
    color: ${({ color, theme }) => color || theme.primary1};
    transition: all ${({ time }) => time || 0.2}s linear;
    width: auto;
    padding: 0;
    display: inline-block;
    height: auto;
    &:hover:enabled {
        opacity: 0.75;
        transform: scale(0.95);
    }
    &:disabled {
        background: transparent;
        color: ${({ theme }) => theme.text6};
        cursor: not-allowed;
    }
`

export function ButtonConfirmed({
    confirmed,
    altDisabledStyle,
    ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean; borderRadius?: string } & ButtonProps): JSX.Element {
    if (confirmed) {
        return <ButtonConfirmedStyle {...rest} />
    } else {
        return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
    }
}

export function ButtonError({ error, ...rest }: { error?: boolean; borderRadius?: string } & ButtonProps) {
    if (error) {
        return <ButtonErrorStyle {...rest} />
    } else {
        return <ButtonPrimary {...rest} />
    }
}

export function ButtonDropdown({
    disabled = false,
    children,
    ...rest
}: { disabled?: boolean; borderRadius?: string } & ButtonProps) {
    return (
        <ButtonPrimary {...rest} disabled={disabled}>
            <RowBetween>
                <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                <ChevronDown size={24} />
            </RowBetween>
        </ButtonPrimary>
    )
}

export function ButtonDropdownLight({
    disabled = false,
    children,
    ...rest
}: { disabled?: boolean; borderRadius?: string } & ButtonProps) {
    return (
        <ButtonOutlined {...rest} disabled={disabled}>
            <RowBetween>
                <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
                <ChevronDown size={24} />
            </RowBetween>
        </ButtonOutlined>
    )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
    if (!active) {
        return <ButtonWhite {...rest} />
    } else {
        return <ButtonPrimary {...rest} />
    }
}
