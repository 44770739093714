/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable unused-imports/no-unused-imports */
import { useWeb3React } from '@web3-react/core'
import { ButtonPrimary } from 'components/Button'
import type { ModalProps } from 'components/Modal'
import Modal from 'components/Modal'
import Input from 'components/NumericalInput'
import { DEFAULT_CHAINID_TOKEN_LIST } from 'constants/lists'
// import type { CurrencyAmount } from 'gsswap_sdk'
import React, { useEffect, useState } from 'react'
import { useAllTokenBalances, useETHBalances } from 'state/wallet/hooks'
import styled from 'styled-components'
import { TYPE } from 'theme'

type CommonVoteProps = {
    token?: 'GS' | 'GC'
    submit: (count: string) => any
}
type ModalVoteProps = {
    isModal: true
} & ModalProps
type DefaultVoteProps = {
    isModal?: false
}
const VoteBox = styled.div<{ isModal?: boolean }>`
    background-color: #fff;
    margin: ${({ isModal }) => (isModal ? '' : '100px auto 0')};
    border-radius: ${({ isModal }) => (isModal ? '' : '6px')};
    padding-top: 40px;
    /* width: 540px; */
    height: 348px;
    border: ${({ isModal }) => (isModal ? '' : '1px solid rgba(0,0,0,0.1)')};
    display: flex;
    flex-direction: column;
    align-items: center;
`
type VoteProps = CommonVoteProps & (ModalVoteProps | DefaultVoteProps)
const StyledDemocraticOrNodeVote = styled.div`
    input {
        width: 368px;
        height: 48px;
        background: #eef5f1;
        border-radius: 6px;
        padding: 10px 12px;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${({ theme }) => theme.text1};

        &::placeholder {
            font-size: 14px;
            font-weight: 400;
            color: #b1bab5;
        }
    }
`

export default ({ token = 'GC', submit, isModal, ...rest }: VoteProps) => {
    const [num, setNum] = useState('')
    const { account } = useWeb3React()
    // let balance!: CurrencyAmount | undefined
    // const balance = useETHBalances(account ? [account] : [])?.[account ?? '']?.toFixed(0) || 0
    let balance: string
    if (token === 'GC') {
        balance = useETHBalances(account ? [account] : [])?.[account ?? '']?.toFixed(0) || '0'
    } else {
        balance = useAllTokenBalances()[DEFAULT_CHAINID_TOKEN_LIST[0].address]?.toFixed(0) || '0'
    }
    const Body = () => (
        <VoteBox isModal={isModal}>
            <h1>投出您的票</h1>
            <StyledDemocraticOrNodeVote>
                <Input value={num} onUserInput={setNum} placeholder="请输入你的投票数量"></Input>
                <TYPE.body marginTop="10px" marginBottom="3px">
                    钱包余额：{balance} {token}
                </TYPE.body>
                <TYPE.subHeader color="#F9AE3F" marginBottom="33px">
                    注：1{token}=1票
                </TYPE.subHeader>
                <ButtonPrimary
                    width={'368px'}
                    onClick={() => submit(num)}
                    disabled={!balance || !num || +num > +balance}
                >
                    投票
                </ButtonPrimary>
            </StyledDemocraticOrNodeVote>
        </VoteBox>
    )
    useEffect(() => {
        if ((rest as ModalProps).isOpen === false) {
            setNum('')
        }
    }, [(rest as ModalProps).isOpen])
    // userEthBalance?.toFixed(0)

    return !!isModal ? (
        <Modal {...(rest as ModalProps)} maxWidth={540}>
            <Body key="body" />
        </Modal>
    ) : (
        <Body />
    )
}
