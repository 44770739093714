import type { ReactNode } from 'react'
import React, { useMemo } from 'react'
import { Box, BoxProps } from 'rebass'
import styled from 'styled-components'
import { isString } from 'utils/type'

interface ICommonStyle {
    textAlign?: string
    padding?: string
    width?: string
    divider?: boolean
    inlineDivider?: boolean
}
type IHeaderStyle = ICommonStyle
type IBodyStyle = ICommonStyle
type IFooterStyle = ICommonStyle
type IAlign = 'left' | 'center' | 'right' | 'top' | 'bottom'
export type ILayout = 'horizontal' | 'vertical'
export interface ICardProps extends BoxProps {
    className?: string
    layout?: ILayout
    backgroundColor?: string
    width?: string | number
    radius?: string
    header?: ReactNode
    headerStyle?: IHeaderStyle
    body?: ReactNode
    bodyStyle?: IBodyStyle
    footer?: ReactNode
    footerStyle?: IFooterStyle
    padding?: string
    children?: ReactNode
    border?: boolean | string
    align?: IAlign
}
interface ICardStyleProps {
    padding?: string
    radius?: string
    border?: string
    justifyContent?: string
    alignItems?: string
}
const flexAlignMapper: Record<IAlign, string> = {
    left: 'start',
    top: 'start',
    right: 'end',
    bottom: 'end',
    center: 'center',
}
const Card = styled(Box)<ICardStyleProps>`
    display: flex;
    padding: ${({ padding = '20px 8px' }) => padding};
    border-radius: ${({ radius = '10px' }) => radius};
    border: ${({ border }) => border};
    opacity: 1;
    justify-content: ${({ justifyContent }) => justifyContent};
    align-items: ${({ alignItems }) => alignItems};
    &.zc-card-layout {
        &--horizontal {
            flex-direction: column;
        }
        &--vertical {
            flex-direction: row;
        }
    }
`
const CommonElem = styled.div<ICommonStyle>`
    text-align: ${({ textAlign }) => textAlign};
    border-bottom: ${({ divider = false, inlineDivider }) =>
        divider || inlineDivider ? '1px solid rgba(0,0,0,0.05)' : 'none'};
    display: ${({ inlineDivider }) => (inlineDivider ? 'inline-block' : 'block')};
    width: ${({ width }) => width};
    padding: ${({ padding }) => padding};
`
const Header = styled(CommonElem)``
const Body = styled(CommonElem)``
const Footer = styled(CommonElem)``

export default ({
    headerStyle,
    bodyStyle,
    body,
    header,
    children,
    footer,
    footerStyle,
    className,
    layout,
    align,
    border,
    backgroundColor,
    width,
    ...style
}: ICardProps) => {
    layout = layout || 'horizontal'
    width = width ? (isString(width) ? width : width + 'px') : width
    border = border ? (isString(border) ? border : '1px solid rgba(0,0,0,0.05)') : 'none'
    backgroundColor = backgroundColor || '#FFF'

    const cardStyle = useMemo(() => {
        const newStyle = {
            ...style,
            border: border as string,
            width,
            alignItems: 'none',
            justifyContent: 'none',
            backgroundColor,
        }
        if (align) {
            if (layout === 'horizontal') {
                // 纵向布局
                newStyle.alignItems = flexAlignMapper[align]
            } else if (layout === 'vertical') {
                // 横向布局
                newStyle.alignItems = flexAlignMapper[align]
            }
        }
        return newStyle
    }, [layout, border, width, backgroundColor, style])

    return (
        <Card className={`zc-card ${className} zc-card-layout--${layout}`} {...cardStyle}>
            {header ? <Header {...headerStyle}>{header}</Header> : <></>}
            {children || body ? (
                <Body className="zc-card__body" {...bodyStyle}>
                    {children ? children : body}
                </Body>
            ) : (
                <></>
            )}
            {footer ? <Footer {...footerStyle}>{footer}</Footer> : <></>}
        </Card>
    )
}
