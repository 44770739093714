import styled from 'styled-components'

const TitleWrapper = styled.div`
    font-size: 24px;
    font-weight: 400;
    color: ${({ theme }) => theme.text1};
    line-height: 28px;
    margin-bottom: 20px;
`

export default TitleWrapper
