import Icon from '@ant-design/icons'
import { useWeb3React } from '@web3-react/core'
import { Avatar, Descriptions } from 'antd'
import DescriptionsItem from 'antd/lib/descriptions/Item'
import { ajaxPost } from 'api/axios'
import avatar from 'assets/img/avatar.png'
import { SVGCom } from 'assets/svg'
import Back from 'components/Back'
import { ButtonPrimary } from 'components/Button'
import MotionDetail from 'components/MotionDetail'
import Progress from 'components/Progress'
import { BLOCK_BROSWER_ADDRESS } from 'constants/index'
import { ChainId } from 'gsswap_sdk'
import { useGsNodesBaseContract } from 'hooks/useContract'
import Card from 'pages/Vote/components/Card'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Flex, Link } from 'rebass'
import styled, { ThemeContext } from 'styled-components'
import { TYPE } from 'theme'
import { shortenAddress } from 'utils'
import { isUndefined } from 'utils/type'

interface RecordItem {
    voterAddress: string
    voterNumber: string
    hash: string
    voterStatus: number
}

const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 20px;
`
const VoteLabel = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    .anticon {
        margin-right: 8px;
    }
`
const VoteCount = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px; ;
`
const VoteListItem = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.9);
    width: 100%;
    margin-top: 20px;
    .ant-avatar {
        margin-right: 12px;
        width: 32px;
        height: 32px;
    }
    .anticon {
        margin-left: 18px;
    }
`

const StyledDoVoteContainer = styled.div`
    position: relative;
    .motion-detail {
        margin-left: 97px;
    }
`

const StyledCardsWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 48px;
    .vote-card {
        background-color: #fff;
        margin-bottom: 20px;
    }
`
const CardTitle = styled.div`
    width: 100%;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.9);
    margin-bottom: 27px;
`
const ProgressItem = styled.div<{ marginTop?: string }>`
    margin-top: ${({ marginTop }) => marginTop};
`
const proposalDetailUrl = ['getProposalById', 'getCouncilById', 'getNodeById']
const proposalRecordUrl = ['getVoteAddressList', 'getCouncilVoteAddressList', 'getNodeVoteAddressList']
export default function DoVote() {
    const commonCardStyle = useMemo(() => ({ width: 306, border: true, backgroundColor: 'transparent' }), [])
    const params = useParams<{ id: string; type: string; isDetail?: string }>()
    const theme = useContext(ThemeContext)
    const { chainId, account } = useWeb3React()

    const [totalCount, setTotalCount] = useState(0)
    const [recordList, setRecordList] = useState<RecordItem[]>([])
    const blockBrowserAddress = BLOCK_BROSWER_ADDRESS[chainId as ChainId]

    const [proposalInfo, setProposalInfo] = useState({
        address: '',
        agreeNumber: 0,
        disagreeNumber: 0,
        applyAmount: 0,
        contractAddress: '',
        describeContent: '',
        endTime: '',
        fileUrl: '',
        hash: '',
        id: 0,
        proposalAmount: 0,
        receiveAddress: '',
        startTime: '',
        title: '',
        type: undefined,
        votesNumber: 0,
    })

    const [total, setTotal] = useState(0)
    const nodesBaseContract = useGsNodesBaseContract()
    const getCouncilInfo = useCallback(async () => {
        if (!account) return
        if (params.type === '1') {
            const { data } = await ajaxPost('getCouncilInfo', {
                address: account,
            })
            setTotal(+data.currentCount)
            return
        }
        if (!nodesBaseContract) return
        const { length } = await nodesBaseContract.electeds()
        setTotal(length)
    }, [setTotal, account, params, nodesBaseContract])

    const requestGetProposalInfo = useCallback(async () => {
        const res = await ajaxPost(proposalDetailUrl[Number(params.type)], { id: params.id })
        if (res.code === '200') {
            setProposalInfo(res.data)
        }
    }, [params.id])
    const requestGetVoteAddressList = useCallback(async () => {
        const res = await ajaxPost(proposalRecordUrl[Number(params.type)], { proposalId: params.id, address: account })
        if (res.code === '200') {
            // debugger
            setTotalCount(res.data.count)
            setRecordList(res.data.list)
        }
    }, [params.id, account])

    const refreshData = useCallback(async () => {
        await Promise.allSettled([
            requestGetProposalInfo(),
            requestGetVoteAddressList(),
            ['1', '2'].includes(params.type) && getCouncilInfo(),
        ])
    }, [getCouncilInfo, requestGetProposalInfo, requestGetVoteAddressList, params])
    const handleMoreClick = useCallback(async () => {
        window.open(`${blockBrowserAddress}/address/${proposalInfo.contractAddress}`, '__blank')
    }, [proposalInfo.contractAddress])
    // TODO 改为轮询
    useEffect(() => {
        refreshData()
        const timer = setInterval(() => refreshData(), 10000)
        return () => clearInterval(timer)
    }, [])
    return (
        <StyledDoVoteContainer>
            <Back></Back>
            {!!proposalInfo.address && (
                <MotionDetail
                    id={params.id}
                    isNotPreview={!params.isDetail}
                    title={proposalInfo.title}
                    content={proposalInfo.describeContent}
                    motionSponsor={proposalInfo.address}
                    motionProcess={Number(params.type)}
                    referendumAddress={proposalInfo.contractAddress}
                    isDemocraticMotion={isUndefined(proposalInfo.type) ? proposalInfo.type : proposalInfo.type === 0}
                    summary="提案投票成功"
                />
            )}
            <StyledCardsWrapper>
                <Card {...commonCardStyle} padding="20px 10px" header={<CardTitle>链上信息</CardTitle>}>
                    <Descriptions
                        column={1}
                        labelStyle={{
                            color: 'rgba(0, 0, 0, 0.6)',
                            fontSize: '16px',
                            width: 80,
                        }}
                        contentStyle={{
                            color: 'rgba(0, 0, 0, 0.9)',
                            fontSize: '16px',
                            width: '100%',
                        }}
                    >
                        <DescriptionsItem label="金额">
                            <TYPE.blue>{proposalInfo.applyAmount} GC</TYPE.blue>
                        </DescriptionsItem>
                        <DescriptionsItem label="提案人">
                            <Link
                                href={`${blockBrowserAddress}/address/${proposalInfo.address}`}
                                target="__blank"
                                display="flex"
                                alignItems="center"
                                height="100%"
                            >
                                <TYPE.black marginRight="6px">
                                    {proposalInfo.address && shortenAddress(proposalInfo.address)}
                                </TYPE.black>
                                <Icon component={SVGCom({ src: 'ShareSvg' }) as any} />
                            </Link>
                        </DescriptionsItem>
                        <DescriptionsItem label="受益人">
                            <Link
                                href={`${blockBrowserAddress}/address/${proposalInfo.receiveAddress}`}
                                target="__blank"
                                display="flex"
                                alignItems="center"
                                height="100%"
                            >
                                <TYPE.black marginRight="6px">
                                    {proposalInfo.receiveAddress && shortenAddress(proposalInfo.receiveAddress)}
                                </TYPE.black>
                                <Icon component={SVGCom({ src: 'ShareSvg' }) as any} />
                            </Link>
                        </DescriptionsItem>
                        <DescriptionsItem label="提案合约">
                            <Link
                                href={`${blockBrowserAddress}/address/${proposalInfo.contractAddress}`}
                                target="__blank"
                                display="flex"
                                alignItems="center"
                                height="100%"
                            >
                                <TYPE.black marginRight="6px">
                                    {proposalInfo.contractAddress && shortenAddress(proposalInfo.contractAddress)}
                                </TYPE.black>
                                <Icon component={SVGCom({ src: 'ShareSvg' }) as any} />
                            </Link>
                        </DescriptionsItem>
                        <DescriptionsItem label="创建日期">
                            <TYPE.subHeader color="rgba(0, 0, 0, 0.6)" lineHeight="26px">
                                {proposalInfo.startTime}
                            </TYPE.subHeader>
                        </DescriptionsItem>
                    </Descriptions>
                </Card>
                {params.type === '0' ? (
                    <></>
                ) : (
                    <Card
                        {...commonCardStyle}
                        padding="20px 43px"
                        marginTop={20}
                        header={<CardTitle>投票详情</CardTitle>}
                    >
                        <ProgressItem>
                            <TYPE.black fontSize={16}>赞成：</TYPE.black>
                            <Progress
                                info={`${proposalInfo.agreeNumber}票`}
                                pace={proposalInfo.agreeNumber}
                                total={total}
                                strokeColor="#0080D0"
                                trailColor="#E7E7E7"
                                strokeWidth={20}
                            />
                        </ProgressItem>
                        <ProgressItem marginTop="14px">
                            <TYPE.black fontSize={16}>反对：</TYPE.black>
                            <Progress
                                info={`${proposalInfo.disagreeNumber}票`}
                                pace={proposalInfo.disagreeNumber}
                                total={total}
                                strokeColor="#48C79C"
                                trailColor="#E7E7E7"
                                strokeWidth={20}
                            />
                        </ProgressItem>
                    </Card>
                )}
                <Card
                    {...commonCardStyle}
                    padding="20px 33px"
                    marginTop={20}
                    header={<CardTitle>获得票数</CardTitle>}
                    footerStyle={{ padding: '16px 0 0 0' }}
                    footer={
                        <ButtonPrimary
                            height={'40px'}
                            fontSize={'16px'}
                            backgroundColor="transparent"
                            color="rgba(0, 0, 0, 0.9000)"
                            border={`1px solid ${theme.text6}`}
                            onClick={handleMoreClick}
                        >
                            <span style={{ marginRight: '10px' }}>查看更多</span>
                            <Icon component={SVGCom({ src: 'MoreSvg' }) as any} />
                        </ButtonPrimary>
                    }
                >
                    <Header>
                        <VoteLabel>
                            <Icon component={SVGCom({ src: 'CircleSvg' }) as any} />
                            <div>投票总数</div>
                        </VoteLabel>
                        <VoteCount>{params.type === '0' ? +proposalInfo.votesNumber : totalCount}</VoteCount>
                    </Header>
                    {recordList.map((recordItem, index) => {
                        return (
                            <VoteListItem key={index}>
                                <Avatar src={avatar}></Avatar>
                                <Link
                                    href={`${blockBrowserAddress}/tx/${recordItem.hash}`}
                                    target="__blank"
                                    display="flex"
                                    flexGrow={1}
                                    alignItems="center"
                                >
                                    <Flex justifyContent="space-between" flexGrow={1}>
                                        <TYPE.black>
                                            {recordItem.voterAddress && shortenAddress(recordItem.voterAddress)}
                                        </TYPE.black>

                                        <TYPE.black>
                                            {params.type === '0'
                                                ? recordItem.voterNumber
                                                : recordItem.voterStatus === 1
                                                ? '赞成'
                                                : '反对'}
                                        </TYPE.black>
                                    </Flex>
                                    <Icon component={SVGCom({ src: 'ShareSvg' }) as any} />
                                </Link>
                            </VoteListItem>
                        )
                    })}
                </Card>
            </StyledCardsWrapper>
        </StyledDoVoteContainer>
    )
}
