import { AbstractConnector } from '@web3-react/abstract-connector'
import { ChainId, JSBI, Percent } from 'gsswap_sdk'

import { injected, walletconnect, walletlink } from '../connectors'

// export const ROUTER_ADDRESS = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'

type PartialChainId = { [chainId in ChainId]?: string }

export const SIGN_DATA = `Welcome to Genesis治理!

Click to sign in and accept the Genesis Terms of Service: https://Genesis.io/

This request will not trigger a blockchain transaction or cost any gas fees.

Your authentication status will reset after 24 hours.`

export const BLOCK_BROSWER_ADDRESS: { [chainId in ChainId]?: string } = {
    [ChainId.GSTESTNET]: 'https://scan.genesischain.io/#',
}

// 来源轨道合约地址
export const GS_ORIGIN_TRACK_ADDRESS: { [chainId in ChainId]?: string } = {
    [ChainId.GSTESTNET]: '0xEb0E40CC47f97AF8b7D03CF482189128fCb3e8A4',
}

// 质押锁定管理合约地址
export const GS_STAKING_LOCK_ADMIN_ADDRESS: { [chainId in ChainId]?: string } = {
    [ChainId.GSTESTNET]: '0x017814E09a62314EFFaB5ec0ebDE2bADDc140D76',
}

// 理事会合约地址
export const GS_COUNCIL_ADDRESS: { [chainId in ChainId]?: string } = {
    [ChainId.GSTESTNET]: '0x26EAf2408bDC421D08a48c352E85dC21D6dc54dF',
}

// 国库合约地址
export const GS_TREASURY_ADDRESS: { [chainId in ChainId]?: string } = {
    [ChainId.GSTESTNET]: '0xbCF7aEe022b81110165F29b77993aE812B055bCc',
}

// 工厂合约地址
export const GS_REFERENDUMS_FACTORY_ADDRESS: { [chainId in ChainId]?: string } = {
    [ChainId.GSTESTNET]: '0x5d6BF4B0cD77D6C664b5885523ce66dFEE850470',
}

// 议员投票合约地址
export const GS_COUNCILLORS_VOTING_ADDRESS: { [chainId in ChainId]?: string } = {
    [ChainId.GSTESTNET]: '0xBE43aD6eF75603F8da754D7b83E9Cb3f82b4Ab62',
}

// 节点投票合约地址
export const GS_NODES_VOTING_ADDRESS: { [chainId in ChainId]?: string } = {
    [ChainId.GSTESTNET]: '0x8042cBd7885067f4c1FEAEF3dDE56d4d6CDBF0B0',
}

export const GS_NODES_BASE_ADDRESS: { [chainId in ChainId]?: string } = {
    [ChainId.GSTESTNET]: '0x25201e6ba6E025eF496eDFD9A5AFe501CDc308e3',
}

export const APPROVE_BASE_AMOUNT = JSBI.BigInt(1000000000000000000000000)

export const ROUTER_ADDRESS: PartialChainId = {
    [ChainId.MAINNET]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
    [ChainId.BSCTESTNET]: '0xEed53A8386897bAb0da3deFf0D9a5Af3BAC4BeFE',
    [ChainId.OKEX_TESTNET]: '0x493BCa9C57Fb730D799cd48e2B89bF1Ed77af667',
    [ChainId.GSTESTNET]: '0x6dD789b19138CbEEBc48c542f6FF6FD26627A2f0',
}

export const nameConfig: PartialChainId = {
    [ChainId.MAINNET]: 'BNB',
    [ChainId.BSCTESTNET]: 'BNB',
    [ChainId.OKEX]: 'OKT',
    [ChainId.OKEX_TESTNET]: 'OKT',
    [ChainId.GSTESTNET]: 'GC',
}

export interface WalletInfo {
    connector?: AbstractConnector
    name: string
    iconName: string
    description: string
    href: string | null
    color: string
    primary?: true
    mobile?: true
    mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
    INJECTED: {
        connector: injected,
        name: 'Injected',
        iconName: 'arrow-right.svg',
        description: 'Injected web3 provider.',
        href: null,
        color: '#010101',
        primary: true,
    },
    METAMASK: {
        connector: injected,
        name: 'MetaMask',
        iconName: 'metamask.png',
        description: 'Easy-to-use browser extension.',
        href: null,
        color: '#E8831D',
    },
    WALLET_CONNECT: {
        connector: walletconnect,
        name: 'WalletConnect',
        iconName: 'walletConnectIcon.svg',
        description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
        href: null,
        color: '#4196FC',
        mobile: true,
    },
    WALLET_LINK: {
        connector: walletlink,
        name: 'Coinbase Wallet',
        iconName: 'coinbaseWalletIcon.svg',
        description: 'Use Coinbase Wallet app on mobile device',
        href: null,
        color: '#315CF5',
    },
    COINBASE_LINK: {
        name: 'Open in Coinbase Wallet',
        iconName: 'coinbaseWalletIcon.svg',
        description: 'Open in Coinbase Wallet app.',
        href: 'https://go.cb-w.com/mtUDhEZPy1',
        color: '#315CF5',
        mobile: true,
        mobileOnly: true,
    },
    // FORTMATIC: {
    //   connector: fortmatic,
    //   name: 'Fortmatic',
    //   iconName: 'fortmaticIcon.png',
    //   description: 'Login using Fortmatic hosted wallet',
    //   href: null,
    //   color: '#6748FF',
    //   mobile: true
    // },
    // Portis: {
    //   connector: portis,
    //   name: 'Portis',
    //   iconName: 'portisIcon.png',
    //   description: 'Login using Portis hosted wallet',
    //   href: null,
    //   color: '#4A6C9B',
    //   mobile: true
    // }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
