import { useWeb3React } from '@web3-react/core'
import { ajaxPost } from 'api/axios'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

interface TableProps {
    url: string
    current?: number
    pageSize?: number
}

export default <T>({ url, current, pageSize }: TableProps) => {
    const { account } = useWeb3React()
    const params = useParams<{ address?: string }>()
    const [tableData, setTableData] = useState<T[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [paginationConfig, setPaginationConfig] = useState({
        current,
        pageSize,
        total: 0,
    })

    const [serchParams, setSerchParams] = useState({})

    const getTableData = useCallback(async () => {
        try {
            setLoading(true)
            let _url!: string
            let type: number | undefined
            if (url.includes(',')) {
                _url = url.split(',')[0]
                type = +url.split(',')[1] ^ 1
            } else {
                _url = url
            }

            const res = await ajaxPost(_url, {
                pageNum: paginationConfig.current,
                pageSize: paginationConfig.pageSize,
                type,
                ...serchParams,
                address: params?.address || account,
            })
            setLoading(false)

            if (res.code === '200') {
                setTableData(res.data.list || res.data)
                setPaginationConfig({
                    ...paginationConfig,
                    total: res.data.count,
                })
            }
        } catch (error) {
            setLoading(false)
        }
    }, [url, serchParams, paginationConfig, account, setLoading, params])

    const changePage = useCallback(
        (pagination) => {
            setPaginationConfig(pagination)
        },
        [setPaginationConfig]
    )

    const doSearch = useCallback(
        (params: { [key: string]: any } = {}) => {
            setPaginationConfig({
                ...paginationConfig,
                current: 1,
            })
            setSerchParams({ ...serchParams, ...params })
        },
        [getTableData, setSerchParams, setPaginationConfig, paginationConfig]
    )

    useEffect(() => {
        if (!url) return
        getTableData()
        // const timer = setInterval(() => getTableData(), 10000)
        // return () => clearInterval(timer)
    }, [paginationConfig.current, serchParams, url])

    return {
        paginationConfig,
        serchParams,
        tableData,
        setPaginationConfig,
        getTableData,
        changePage,
        doSearch,
        loading,
        setTableData,
    }
}
