export function isArray<T = any>(val: any): val is T[] {
    return Array.isArray ? Array.isArray(val) : val instanceof Array
}
export function isString(val: any): val is string {
    return typeof val === 'string'
}
export function isUndefined(val: any): val is undefined {
    return val === void 0
}
export function isBoolean(val: any): val is boolean {
    return typeof val === 'boolean'
}
