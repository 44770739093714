import { ChainId, Currency } from 'gsswap_sdk'
import { useEffect, useMemo, useState } from 'react'

import { useActiveWeb3React } from './index'

const useWNATIVE = (): { ETHER: Currency } => {
    const { chainId } = useActiveWeb3React()
    const chainId1: any = chainId
    const [ETHER, setETHER] = useState<Currency>(Currency.ENATIVE[chainId1 as ChainId])
    useEffect(() => {
        setETHER(Currency.ENATIVE[chainId as ChainId])
    }, [chainId])

    return { ETHER }
}
export default useWNATIVE

export const getWNATIVE = (chainId: ChainId) => {
    return Currency.ENATIVE[chainId as ChainId]
}

export const useViewText = () => {
    const { chainId } = useActiveWeb3React()
    const strConfig = useMemo<{ [chainId in ChainId]?: string }>(() => {
        return {
            [ChainId.MAINNET]: 'BscScan',
            [ChainId.BSCTESTNET]: 'BscScan',
            [ChainId.OKEX]: 'OKExChain',
            [ChainId.OKEX_TESTNET]: 'OKExChain',
            [ChainId.GSTESTNET]: 'GSChain',
        }
    }, [])
    const [str, setStr] = useState('GS')
    useEffect(() => {
        setStr(`View on ${strConfig[chainId as ChainId]}`)
    }, [chainId, strConfig])
    return str
}
