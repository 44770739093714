import { presetPrimaryColors } from '@ant-design/colors'
import * as React from 'react'
import styled from 'styled-components'

import type { ProgressGradient, ProgressProps, StringGradients } from './Progress'

const ProgressOuter = styled.div`
    display: inline-block;
    width: 2em;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 1em;
    line-height: 1;
    white-space: nowrap;
    text-align: left;
    vertical-align: middle;
    word-break: normal;
    position: relative;
    padding-right: 0 !important;
    width: 100%;
    margin-right: 0;
    padding-right: 0;
`
const ProgressInner = styled.div<{ backgroundColor?: string; borderRadius?: number }>`
    position: relative;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    vertical-align: middle;
    background-color: ${({ backgroundColor = '#f5f5f5' }) => backgroundColor};
    border-radius: ${({ borderRadius = '100px' }) => borderRadius};
`
const ProgressBg = styled.div`
    position: relative;
    background-color: #1890ff;
    border-radius: 100px;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    text-align: right;
`
function validProgress(progress: number | undefined) {
    if (!progress || progress < 0) {
        return 0
    }
    if (progress > 100) {
        return 100
    }
    return progress
}

// function getSuccessPercent({
//     success,
//     successPercent,
// }: {
//     success?: {
//         progress?: number;
//         percent?: number;
//     };
//     successPercent?: number;
// }) {
//     let percent = successPercent;
//     /** @deprecated Use `percent` instead */
//     if (success && 'progress' in success) {

//         percent = success.progress;
//     }
//     if (success && 'percent' in success) {
//         percent = success.percent;
//     }
//     return percent;
// }

export type DirectionType = 'ltr' | 'rtl' | undefined
interface LineProps extends ProgressProps {
    prefixCls: string
    direction?: DirectionType
    children: React.ReactNode
    strokeColor?: string | ProgressGradient
}

/**
 * @example
 *   {
 *     "0%": "#afc163",
 *     "75%": "#009900",
 *     "50%": "green", // ====> '#afc163 0%, #66FF00 25%, #00CC00 50%, #009900 75%, #ffffff 100%'
 *     "25%": "#66FF00",
 *     "100%": "#ffffff"
 *   }
 */
export const sortGradient = (gradients: StringGradients) => {
    let tempArr: any[] = []
    Object.keys(gradients).forEach((key) => {
        const formattedKey = parseFloat(key.replace(/%/g, ''))
        if (!isNaN(formattedKey)) {
            tempArr.push({
                key: formattedKey,
                value: gradients[key],
            })
        }
    })
    tempArr = tempArr.sort((a, b) => a.key - b.key)
    return tempArr.map(({ key, value }) => `${value} ${key}%`).join(', ')
}

/**
 * Then this man came to realize the truth: Besides six pence, there is the moon. Besides bread and
 * butter, there is the bug. And... Besides women, there is the code.
 *
 * @example
 *   {
 *     "0%": "#afc163",
 *     "25%": "#66FF00",
 *     "50%": "#00CC00", // ====>  linear-gradient(to right, #afc163 0%, #66FF00 25%,
 *     "75%": "#009900", //        #00CC00 50%, #009900 75%, #ffffff 100%)
 *     "100%": "#ffffff"
 *   }
 */
export const handleGradient = (strokeColor: ProgressGradient, directionConfig?: DirectionType) => {
    const {
        from = presetPrimaryColors.blue,
        to = presetPrimaryColors.blue,
        direction = directionConfig === 'rtl' ? 'to left' : 'to right',
        ...rest
    } = strokeColor
    if (Object.keys(rest).length !== 0) {
        const sortedGradients = sortGradient(rest as StringGradients)
        return { backgroundImage: `linear-gradient(${direction}, ${sortedGradients})` }
    }
    return { backgroundImage: `linear-gradient(${direction}, ${from}, ${to})` }
}

const Line = (props: LineProps) => {
    const {
        // prefixCls,
        direction: directionConfig,
        strokeWidth,
        size,
        strokeColor,
        strokeLinecap = 'round',
        children,
        trailColor = null,
        // success,
        pace = 0,
        total = 100,
    } = props

    const backgroundProps =
        strokeColor && typeof strokeColor !== 'string'
            ? handleGradient(strokeColor, directionConfig)
            : {
                  background: strokeColor,
              }

    const borderRadius = strokeLinecap === 'square' || strokeLinecap === 'butt' ? 0 : undefined
    const trailStyle = {
        backgroundColor: trailColor || undefined,
        borderRadius,
    }

    const percent = validProgress((pace / total) * 100)

    const percentStyle = {
        width: `${percent}%`,
        height: strokeWidth || (size === 'small' ? 6 : 8),
        borderRadius,
        ...backgroundProps,
    }

    // const successPercent = getSuccessPercent(props);

    // const successPercentStyle = {
    //     width: `${validProgress(successPercent)}%`,
    //     height: strokeWidth || (size === 'small' ? 6 : 8),
    //     borderRadius,
    //     backgroundColor: success?.strokeColor,
    // };

    // const successSegment =
    //     successPercent !== undefined ? (
    //         <div className={`${prefixCls}-success-bg`} style={successPercentStyle} />
    //     ) : null;

    let ProgressText!: React.FC
    let SuffixProgressText!: React.FC

    if (percent <= 10) {
        ProgressText = () => <></>
        SuffixProgressText = () => <>{children}</>
    } else {
        ProgressText = () => <>{children}</>
        SuffixProgressText = () => <></>
    }

    return (
        <>
            <ProgressOuter>
                <ProgressInner {...trailStyle}>
                    <ProgressBg style={percentStyle}>
                        <ProgressText />
                    </ProgressBg>
                    {/* {successSegment} */}
                </ProgressInner>
                <SuffixProgressText />
            </ProgressOuter>
        </>
    )
}

export default Line
