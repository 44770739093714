import { useWeb3React } from '@web3-react/core'
import ConnectWallet, { ConnectWalletBtn } from 'components/ConnectWallet'
import Loader from 'components/Loader'
// import { ButtonSecondary } from 'components/Button'
import Menu from 'components/Menu'
import { useLoginStatus } from 'hooks'
import React, { useCallback, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useETHBalances } from 'state/wallet/hooks'
import styled, { ThemeContext } from 'styled-components'

import LogoImg from '../../assets/img/logo.png'

const HeaderWrapper = styled.div`
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 2;
    padding: 0 40px;
    background: #fff;
`

const LogoWrapper = styled.a`
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 26px;
    text-decoration: none;
    span {
        cursor: pointer;
    }
    &:hover {
        color: rgba(0, 0, 0, 0.9);
    }
`

export const Logo = styled.img<{ size?: number }>`
    width: ${({ size }) => size || 32}px;
    height: ${({ size }) => size || 32}px;
    border-radius: 50%;
    margin-right: 11px;
    cursor: pointer;
`

const RightContainer = styled.div`
    display: flex;
`

const BalanceWrapper = styled.div`
    ${({ theme }) => theme.mediaWidth.upToSmall`
        display:none;
    `}
`

export default (): JSX.Element => {
    const theme = useContext(ThemeContext)
    const history = useHistory()
    const { account } = useWeb3React()
    const loginStatus = useLoginStatus()
    const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
    const goHome = useCallback(() => {
        history.replace('/')
    }, [history])

    return (
        <HeaderWrapper>
            <LogoWrapper>
                <Logo onClick={goHome} src={LogoImg} size={54} />
                <span onClick={goHome}>Genesis治理</span>
                <Menu />
            </LogoWrapper>

            <RightContainer>
                {account && loginStatus && (
                    <BalanceWrapper>
                        <ConnectWalletBtn width={'158px'} height={'54px'} marginRight={'32px'}>
                            <Logo src={LogoImg} />{' '}
                            {userEthBalance ? userEthBalance?.toSignificant(4) : <Loader stroke={theme.primary1} />} GC
                        </ConnectWalletBtn>
                    </BalanceWrapper>
                )}
                <ConnectWallet />
            </RightContainer>
        </HeaderWrapper>
    )
}
