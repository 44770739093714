import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

/**
 * 解析毫秒为天、时、分、秒
 * @param milliseconds 毫秒
 */
const parseMs = (milliseconds: number) => {
    return {
        days: Math.floor(milliseconds / 86400),
        hours: Math.floor(milliseconds / 3600) % 24,
        minutes: Math.floor(milliseconds / 60) % 60,
        seconds: Math.floor(milliseconds / 1) % 60,
    }
}

/**
 * 倒计时
 * @param endTimeStamp 结束时间的时间戳
 */
export default (endTimeStamp: number) => {
    const timer = useRef(0)
    const [state, setState] = useState(endTimeStamp)

    // 计算时间的差值
    const calcTimeDiff = useCallback(() => {
        // 获取当前时间戳
        const currentTime = ~~(+new Date() / 1000)
        // console.log(currentTime)

        // 计算当前时间和结束时间的差值
        const seconds = Math.floor((endTimeStamp || 0) - currentTime)
        // 如果是负数 就清空定时器
        if (seconds <= 0) {
            clearInterval(timer.current)
            return setState(0)
        }
        setState(seconds)
    }, [endTimeStamp])

    useEffect(() => {
        clearInterval(timer.current)
        setState(endTimeStamp)
        calcTimeDiff()
        timer.current = window.setInterval(() => {
            calcTimeDiff()
        }, 1000)
        return () => {
            clearInterval(timer.current)
        }
    }, [endTimeStamp])

    return useMemo(() => {
        const { days, hours, minutes, seconds } = parseMs(state)
        return `${days}天${hours}时${minutes}分${seconds}秒`
    }, [state])
}
