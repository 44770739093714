import AIcon from '@ant-design/icons'
import { useWeb3React } from '@web3-react/core'
import { Tooltip } from 'antd'
import { ajaxPost } from 'api/axios'
import RightIcon from 'assets/img/right.png'
import { SVGCom } from 'assets/svg'
import { VoteTable } from 'components/Table'
import Title from 'components/Title'
import useCountDown from 'hooks/useCountDown'
import useGetUserVotingInfo from 'hooks/useGetUserVotingInfo'
import Card from 'pages/Vote/components/Card'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

export interface VoteProps {
    number: number
    avatar: string
    content: string
    time: string
    count: number
}

const TopWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ApplyWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.primary1};
    line-height: 23px;
    cursor: pointer;
    img {
        width: 20px;
        height: 20px;
        margin-left: 14px;
    }
`
const Icon = styled(AIcon)`
    cursor: pointer;
`

const Header = styled.div`
    margin-bottom: 20px;
`
const DataOverview = styled.div``

export default (): JSX.Element => {
    const { account } = useWeb3React()
    // console.log(days, hours, minutes, seconds);

    const [councliInfo, setCouncliInfo] = useState({
        totalCount: 0,
        currentCount: 0,
        permanentCount: 0,
        electionCount: 0,
        candidateCount: 0,
        votes: 0,
        myVotes: 0,
        endTime: 0,
    })

    const history = useHistory()
    const handleClick = useCallback((url: string) => history.push(url), [history])
    const getCouncilStat = useCallback(async () => {
        if (!account) return
        const councilInfoRes = await ajaxPost('getCouncilInfo', { address: account })
        if (councilInfoRes.code === '200') setCouncliInfo({ ...councilInfoRes.data })
    }, [account])
    const { isMP, getUserVotingInfo } = useGetUserVotingInfo()
    const countdown = useCountDown(councliInfo.endTime)

    const list = useMemo(() => {
        return [
            {
                title: '席位',
                tip: `理事会共${councliInfo.totalCount}个席位`,
                body: councliInfo.totalCount || 0,
            },
            {
                title: '常任',
                tip: '常任席位自动连任',
                body: councliInfo.permanentCount || 0,
            },
            {
                title: '当选',
                tip: '竞选成功的当选议员',
                body: councliInfo.electionCount || 0,
            },
            {
                title: '候选',
                tip: '全网参与竞选的候选理事数量',
                body: councliInfo.candidateCount || 0,
            },
            {
                title: '获得票数',
                tip: '统计当前钱包创建的理事获得的总票数',
                body: councliInfo.votes || 0,
            },
            {
                title: '我的投票',
                tip: '统计我投票给其他理事的票数',
                body: councliInfo.myVotes || 0,
            },
            {
                title: '本轮竞选倒计时',
                tip: '投票统计数据每6小时更新一次，理事竞选根据投票结果而变化',
                body: countdown,
                headerStyle: {
                    color: '#999F9C',
                },
                bodyStyle: {
                    color: '#24BA62',
                },
            },
        ]
    }, [councliInfo, countdown])

    useEffect(() => {
        getCouncilStat()
        getUserVotingInfo()
        const timer = setInterval(() => {
            getCouncilStat()
            getUserVotingInfo()
        }, 21600000)
        return () => clearInterval(timer)
    }, [])

    const urlList = ['getTopMembers,0', 'getTopMembers,1']
    const [url, setUrl] = useState('getTopMembers,0')

    return (
        <>
            <TopWrapper>
                <Title>理事会</Title>
                {isMP === false && (
                    <ApplyWrapper onClick={() => handleClick('/ToBeDirector')}>
                        申请成为议员
                        <img src={RightIcon} />
                    </ApplyWrapper>
                )}
            </TopWrapper>
            <Header>
                <DataOverview>
                    <Card.GridCard
                        padding="10px 20px"
                        list={list}
                        headerTip={({ tip }) => (
                            <Tooltip title={tip}>
                                <Icon component={SVGCom({ src: 'InfoCircleSvg' }) as any} />
                            </Tooltip>
                        )}
                    ></Card.GridCard>
                </DataOverview>
            </Header>
            <VoteTable<VoteProps>
                isMP={isMP}
                isCouncil
                hasShowMe
                pla="搜索议员昵称/议员账户"
                url={url}
                tabChange={(p, { index }) => {
                    setUrl(urlList[index])
                }}
            />
        </>
    )
}
