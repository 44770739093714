import Icon from '@ant-design/icons'
import { Tooltip } from 'antd'
import FinanceImage from 'assets/img/finance.png'
import LiquidityRatioImage from 'assets/img/liquidity_ratio.png'
import ProportionProposalsImage from 'assets/img/proportion_proposals.png'
import { SVGCom } from 'assets/svg'
import { VoteTable } from 'components/Table'
import { useTreasury } from 'hooks/useGSTreasury'
import Card from 'pages/Vote/components/Card'
import { ICardProps } from 'pages/Vote/components/Card/Card'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

const Header = styled.div``
const Body = styled.div`
    margin-top: 20px;
`
const DataOverview = styled.div`
    display: flex;
    justify-content: space-between;
`

const DataOverviewItemHeader = styled.div`
    display: flex;
    align-items: center;
`
const DataOverviewItemTitle = styled.div`
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    margin-right: 4px;
`
const DataOverviewItemDesc = styled.div`
    color: rgba(0, 0, 0, 0.9);
    font-size: 28px;
    font-weight: 500;
    margin-top: 20px;
`
const DataOverviewItemFooter = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.text6};
    margin-top: 16px;
`

export interface VoteProps {
    number: number
    avatar: string
    content: string
    time: string
    count: number
}

export default () => {
    const commonCardStyle = useMemo(
        () =>
            ({
                width: 414,
                layout: 'vertical',
                align: 'center',
                padding: '37px 66px',
                headerStyle: { padding: '0 20px 0 0' },
            } as ICardProps),
        []
    )
    const DataOverviewItem = ({
        title,
        tip,
        desc,
        footer,
    }: {
        title: string
        tip: string
        desc: string
        footer: string
    }) => {
        return (
            <>
                <DataOverviewItemHeader>
                    <DataOverviewItemTitle>{title}</DataOverviewItemTitle>
                    <Tooltip title={tip}>
                        <Icon component={SVGCom({ src: 'InfoCircleSvg' }) as any} />
                    </Tooltip>
                </DataOverviewItemHeader>
                <DataOverviewItemDesc>{desc}</DataOverviewItemDesc>
                <DataOverviewItemFooter>{footer}</DataOverviewItemFooter>
            </>
        )
    }

    const [requestUrl, setRequestUrl] = useState('getProposalList')
    const urlList = useMemo(() => ['getProposalList', 'getCouncilList', 'getNodeList'], [])
    const onTabChange = useCallback(
        ({ key }) => {
            setRequestUrl(urlList[+key - 1])
        },
        [setRequestUrl]
    )

    const [treasuryAsset, proposalAsset, marketAsset] = useTreasury()
    return (
        <>
            <Header>
                <DataOverview>
                    <Card
                        {...commonCardStyle}
                        header={<img src={FinanceImage} width={64} />}
                        body={
                            <DataOverviewItem
                                title="财政部可用"
                                tip="财政部合约可用GC数量"
                                desc={`${treasuryAsset} GC`}
                                footer={`≈$${treasuryAsset}`}
                            />
                        }
                    />
                    <Card
                        {...commonCardStyle}
                        header={<img src={ProportionProposalsImage} width={64} />}
                        body={
                            <DataOverviewItem
                                title="提案可用"
                                tip="提案能申请的资金总数"
                                desc={`${proposalAsset}GC`}
                                footer={`≈$${proposalAsset}`}
                            />
                        }
                    />
                    <Card
                        {...commonCardStyle}
                        header={<img src={LiquidityRatioImage} width={64} />}
                        body={
                            <DataOverviewItem
                                title="流动性可用"
                                tip="可以用来增加Swap流动性的资金"
                                desc={`${marketAsset}GC`}
                                footer={`≈$${marketAsset}`}
                            />
                        }
                    />
                </DataOverview>
            </Header>
            <Body>
                <VoteTable<VoteProps>
                    pla="搜索提案标题/提案者账户"
                    url={requestUrl}
                    tabChange={onTabChange}
                    title="Genesis提案"
                />
            </Body>
        </>
    )
}
