import { useWeb3React } from '@web3-react/core'
import { Form, Input, message, Upload, UploadProps } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { ajaxPost, RSASignData } from 'api/axios'
import baseUrl from 'api/env'
import BigNumber from 'bignumber.js'
import Back from 'components/Back'
import { Base as BaseBtn } from 'components/Button'
import Loader from 'components/Loader'
import Modal, { ModalConfirmed } from 'components/Modal'
import MotionDetail from 'components/MotionDetail'
import StyledSelect from 'components/StyledSelect'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import WangEditor from 'components/WangEditor'
import { parseEther } from 'ethers/lib/utils'
import { useGsReferendumsFactoryContract } from 'hooks/useContract'
import useGetUserVotingInfo from 'hooks/useGetUserVotingInfo'
import { useTreasury } from 'hooks/useGSTreasury'
import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useUserToken } from 'state/application/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { useETHBalances } from 'state/wallet/hooks'
import styled, { css, ThemeContext } from 'styled-components'
import { TYPE } from 'theme'
import { isAddress } from 'utils'
import { isUndefined } from 'utils/type'

const PseudoClassCss = css`
    &:focus {
        border-color: ${({ theme }) => theme.primary1};
        box-shadow: none;
    }
    &:hover {
        border-color: ${({ theme }) => theme.primary1};
        box-shadow: none;
    }
`

const InputCss = css`
    line-height: 19px;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid transparent;
    box-shadow: none;
    color: ${({ theme }) => theme.text1};

    &::placeholder {
        color: #999f9c;
    }

    &.ant-input-affix-wrapper-disabled {
        &:hover {
            border-color: transparent;
        }
    }
    &.applyAmount {
        padding-right: 52px;
    }
`

const StyledFormItem = styled(Form.Item)`
    margin-bottom: 28px;
    &:last-child {
        margin-bottom: 26px;
    }
    .ant-form-item-label {
        padding-bottom: 10px;
        label {
            font-size: 18px;
            font-weight: 400;
            color: ${({ theme }) => theme.text1};
            line-height: 21px;
        }
    }

    .ant-form-item-required {
        display: inline-block;
        &::before {
            display: none !important;
        }
        &::after {
            line-height: 21px;
            font-size: 18px;
            font-weight: 400;
            display: inline-block !important;
            content: '*';
            color: #ff4d4f;
            vertical-align: middle;
        }
    }
    .ant-upload-list.ant-upload-list-text {
        width: 537px;

        &::before {
            margin-top: 8px;
            font-size: 18px;
            font-weight: 400;
            color: #999f9c;
            line-height: 21px;
            content: '支持扩展名：rar,zip,doc,docx,pdf,jpg';
        }
    }
`

const StyledInput = styled(Input)`
    outline: none;
    border-radius: 6px;
    ${InputCss};
    ${PseudoClassCss};
    padding: 18px 28px;

    &::-webkit-inner-spin-button {
        display: none;
    }
    .ant-input-suffix {
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.text1};
        line-height: 19px;
    }
    &.ant-input ant-input-disabled {
        border-color: transparent;
    }
`

const StyledSuffix = styled.span`
    position: absolute;
    right: 28px;
    top: 50%;
    transform: translateY(-50%);

    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.text1};
`

const WithSuffixInput = styled.div`
    position: relative;
    ${StyledInput} {
        padding-right: 52px;
    }
    ${StyledSuffix};
`

const { Dragger } = Upload

const StyledDragger = styled(Dragger)`
    width: 175px !important;
    height: 140px !important;
    background: #fff !important;
    .upload-inner-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 38px;
        span {
            &:first-child {
                position: relative;
                margin-bottom: 8px;
                &::before {
                    content: ' ';
                    display: inline-block;
                    width: 4px;
                    height: 26px;
                    background-color: #999f9c;
                }
                &::after {
                    content: ' ';
                    display: inline-block;
                    width: 4px;
                    height: 26px;
                    background-color: #999f9c;
                    position: absolute;
                    transform: rotate(90deg);
                    left: 0;
                }
            }
            &:last-child {
                font-size: 14px;
                font-weight: 400;
                color: #999f9c;
                line-height: 16px;
            }
        }
    }
`

const StyledSponsorMotion = styled.div`
    position: relative;
`

interface MotionFormProps {
    title: string
    fileUrl:
        | [
              {
                  uid: string
                  name: string
                  status: string
                  url: string
              }
          ]
        | undefined
    applyAmount: string
    motionFund: string
    receiveAddress: string
    describeContent: string
    originId: '0' | '1' | '2' | undefined
}

const CustomInput = ({
    value = '',
    onChange,
    placeholder,
    disabled = false,
    setIsProcessing,
    formatAmmountValue,
    children,
}: {
    placeholder: string
    onChange?: () => void
    setIsProcessing: (flag: boolean) => void
    disabled?: boolean
    value?: string
    formatAmmountValue: Function
    children?: ReactNode
}) => {
    return (
        <>
            <WithSuffixInput>
                <StyledInput
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={onChange}
                    onCompositionStart={() => setIsProcessing(true)}
                    onCompositionEnd={() => {
                        setIsProcessing(false)
                        formatAmmountValue(value)
                    }}
                />
                <StyledSuffix>GC</StyledSuffix>
            </WithSuffixInput>
            {children}
        </>
    )
}

const MotionForm = ({
    setErrorMessage,
    setAttemptingTxn,
    setIsActionDisabled,
    setIsOpenTransitionModal,
    setIsPublishing,
    setHash,
    form,
}: {
    setIsActionDisabled: (flag: boolean) => void
    setAttemptingTxn: (flag: boolean) => void
    setIsOpenTransitionModal: (flag: boolean) => void
    setIsPublishing: (flag: boolean) => void
    setHash: (hash: string) => void
    form: FormInstance<MotionFormProps>
    setErrorMessage: (message: any) => void
}) => {
    const [isProcessing, setIsProcessing] = useState(false)
    const addTransaction = useTransactionAdder()
    const userToken = useUserToken()
    const treasury = useTreasury()

    const { account } = useWeb3React() as { account: string }

    const uploadProps: UploadProps = useMemo(
        () => ({
            maxCount: 1,
            accept: '.doc,.docx,.jpg,.pdf,.rar,.zip',
            name: 'imgs',
            action: `${baseUrl}/common/uploadFile`,
            headers: {
                Authorization: userToken,
            },
            data: {
                address: account,
                sign: RSASignData({ address: account }),
            },
            onChange(info) {
                const { status, response } = info.file
                if (status === 'done') {
                    if (response?.code === '200') {
                        form.setFieldValue('fileUrl', [{ url: response.data[0], name: info.file.name }])
                        message.success('文件上传成功')
                    }
                } else if (status === 'error') {
                    message.error('文件上传失败')
                }
            },
        }),
        [account]
    )

    const referendumsFactoryContract = useGsReferendumsFactoryContract()
    const onFinish = useCallback(
        async (values: MotionFormProps) => {
            if (!referendumsFactoryContract) return
            if (+treasury[1] < +values.applyAmount) {
                message.warning('国库提案可用GC不足')
                return
            }

            setIsPublishing(true)
            const { fileUrl, describeContent, motionFund, ...otherValues } = values
            const _fileUrl = fileUrl ? fileUrl[0]?.url : undefined
            const params = { ...otherValues, fileUrl: _fileUrl }
            const sign = RSASignData({ ...otherValues, fileUrl: _fileUrl })

            try {
                const { data } = await ajaxPost('doPublishMotion', { ...params, sign, describeContent })

                setAttemptingTxn(true)
                setIsOpenTransitionModal(true)

                const { hash } = await referendumsFactoryContract.newReferendum(
                    params.receiveAddress,
                    parseEther(params.applyAmount),
                    data,
                    params.originId,
                    {
                        value: parseEther(motionFund),
                    }
                )

                setIsPublishing(false)
                setAttemptingTxn(false)
                setHash(hash)
                addTransaction(
                    {
                        hash,
                    } as any,
                    {
                        summary: '发布提案成功',
                    }
                )
            } catch (err: any) {
                setIsPublishing(false)
                setIsOpenTransitionModal(false)
                setAttemptingTxn(false)
                setErrorMessage(err?.data?.message || err.message || '发布提案失败')
            }
        },
        [setAttemptingTxn, setIsOpenTransitionModal, setHash, addTransaction, setIsPublishing, treasury]
    )

    const gcBalance = BigNumber(useETHBalances([account])?.[account]?.toSignificant() || '0')

    const [applyAmountLimit, setApplyAmountLimit] = useState<number[]>()

    const triggersSetActionStatus = useCallback(
        (values: MotionFormProps, _applyAmountLimit?: number[]) => {
            const allValuesKeys = Object.keys(values)
            const isActionDisabled = allValuesKeys.some((item) => {
                if (item === 'fileUrl') return false
                if (!values[item as keyof MotionFormProps]) {
                    return true
                }

                const actualApplyAmountLimit = _applyAmountLimit || applyAmountLimit
                if (['applyAmount', 'originId'].includes(item) && actualApplyAmountLimit) {
                    const applyAmount = +values.applyAmount
                    if (applyAmount < actualApplyAmountLimit[0] || applyAmount > actualApplyAmountLimit[1]) {
                        return true
                    }
                }
                if (item === 'motionFund') {
                    return BigNumber(values[item]).gt(gcBalance)
                }
                if (item === 'receiveAddress') {
                    return !isAddress(values[item])
                }
                if (item === 'describeContent') return values[item] === '<p><br></p>'
                return false
            })

            setIsActionDisabled(isActionDisabled)
        },
        [gcBalance]
    )

    const formatAmmountValue = useCallback(
        (val: string) => {
            val = val.replace(/[^\d]/g, '')
            val = val.replace(/^0+/, '')

            form.setFieldValue('applyAmount', val)
            form.setFieldValue('motionFund', +val ? BigNumber(val).multipliedBy(0.05).toString(10) : '')
            form.validateFields(['motionFund'])
            form.validateFields(['applyAmount'])
            triggersSetActionStatus(form.getFieldsValue())
        },
        [triggersSetActionStatus]
    )

    const onValuesChange = useCallback(
        (changedValues: any, allValues: MotionFormProps) => {
            const [field] = Object.keys(changedValues)
            if (field === 'applyAmount' && !isProcessing) formatAmmountValue(changedValues[field])
            if (field === 'originId') {
                const _applyAmountLimit = {
                    0: [1000, 9999],
                    1: [10000, 49999],
                    2: [50000, 99999],
                }[changedValues[field] as '0' | '1' | '2']
                setApplyAmountLimit(_applyAmountLimit)

                triggersSetActionStatus(allValues, _applyAmountLimit)
                return
            }

            triggersSetActionStatus(allValues)
        },
        [isProcessing, triggersSetActionStatus, setApplyAmountLimit]
    )

    return (
        <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish} onValuesChange={onValuesChange}>
            <StyledFormItem name="originId" label="提案类型" rules={[{ required: true, message: '请选择提案类型' }]}>
                <StyledSelect
                    placeholder="请选择提案类型"
                    popupClassName="custom-select-dropdown"
                    options={[
                        { label: '小额支出提案', value: '0' },
                        { label: '中额支出提案', value: '1' },
                        { label: '大额支出提案', value: '2' },
                    ]}
                />
            </StyledFormItem>
            <StyledFormItem name="title" label="标题" rules={[{ required: true, message: '请输入标题' }]}>
                <StyledInput maxLength={20} placeholder="请输入标题" />
            </StyledFormItem>
            <StyledFormItem name="applyAmount" label="申请金额" rules={[{ required: true, message: '请输入申请金额' }]}>
                <CustomInput
                    disabled={!form.getFieldValue('originId')}
                    setIsProcessing={setIsProcessing}
                    placeholder="请输入申请金额"
                    formatAmmountValue={formatAmmountValue}
                >
                    {applyAmountLimit && (
                        <div
                            style={{
                                height: '22px',
                                fontSize: '16px',
                                color: '#fc4141',
                                lineHeight: '19px',
                            }}
                        >
                            限额{`${applyAmountLimit[0]}-${applyAmountLimit[1]}`}
                        </div>
                    )}
                </CustomInput>
            </StyledFormItem>
            <StyledFormItem
                name="motionFund"
                label="提案金"
                rules={[
                    {
                        message: '您的GC余额不足，无法发布提案',
                        validator: async (_, val) => {
                            triggersSetActionStatus(form.getFieldsValue())
                            if (BigNumber(val).gt(gcBalance)) throw new Error('您的GC余额不足，无法发布提案')
                        },
                    },
                ]}
            >
                <CustomInput
                    disabled
                    setIsProcessing={setIsProcessing}
                    placeholder="需要锁定申请金额的5%作为提案金"
                    formatAmmountValue={formatAmmountValue}
                />
            </StyledFormItem>
            <StyledFormItem
                name="receiveAddress"
                label="接受钱包地址"
                rules={[
                    {
                        required: true,
                        validator: async (_, val) => {
                            triggersSetActionStatus(form.getFieldsValue())
                            if (!val) throw new Error('请输入接收钱包地址')
                            if (!isAddress(val)) throw new Error('钱包地址格式不正确')
                        },
                    },
                ]}
            >
                <StyledInput placeholder="请输入接受钱包地址" />
            </StyledFormItem>
            <StyledFormItem
                name="fileUrl"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                label="上传文件"
            >
                <StyledDragger {...uploadProps}>
                    <div className="upload-inner-box">
                        <span></span>
                        <span>点击或将文件拖拽到这里上传</span>
                    </div>
                </StyledDragger>
            </StyledFormItem>
            <StyledFormItem
                name="describeContent"
                label="描述"
                rules={[
                    {
                        required: true,
                        message: '请输入提案描述内容',
                        validator: async (_, val) => {
                            if (!val || val === '<p><br></p>') throw new Error('请输入提案描述内容')
                        },
                    },
                ]}
            >
                <WangEditor value={form.getFieldValue('describeContent')} placeholder="请输入提案描述内容" />
            </StyledFormItem>
        </Form>
    )
}

const MotionContentWrapper = styled.div`
    display: flex;
    justify-content: center;
`

const BtnCss = css`
    transition: all 0.2s ease;
    width: 240px;
    height: 48px;
    &:active.enable {
        transform: scale(0.7);
    }
    &:hover.enable {
        opacity: 0.5;
    }
`
const PreviewBtn = styled(BaseBtn)`
    ${BtnCss};
    background-color: transparent;
    border-color: ${({ theme }) => theme.primary1};
    color: ${({ theme }) => theme.primary1};
    margin-bottom: 17px;
`
const PublishBtn = styled(BaseBtn)`
    ${BtnCss};
    background-color: rgba(36, 186, 98, 0.1);
    color: ${({ theme }) => theme.primary1};
`
const MotionsActionsBox = styled.div`
    position: absolute;
    right: 0;
    top: 48px;
`
const MotionActions = ({
    isActionDisabled,
    setIsPreview,
    isPreview,
    onPublish,
    isPublishing,
}: {
    isActionDisabled: boolean
    setIsPreview: (flag: boolean) => void
    isPreview: boolean
    onPublish: () => void
    isPublishing: boolean
}) => {
    const theme = useContext(ThemeContext)

    return (
        <>
            <PreviewBtn as="button" disabled={isActionDisabled} onClick={() => setIsPreview(!isPreview)}>
                {isPreview ? '编辑' : '预览'}
            </PreviewBtn>
            <PublishBtn as="button" disabled={isActionDisabled || isPreview} onClick={onPublish}>
                {isPublishing && <Loader stroke={theme.primary1} />}发布提案
            </PublishBtn>
        </>
    )
}

export default function SponsorMotion() {
    const [isActionDisabled, setIsActionDisabled] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isOpenTransitionModal, setIsOpenTransitionModal] = useState(false)
    const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
    const [hash, setHash] = useState<string>('')

    const { account } = useWeb3React() as { account: string }
    const history = useHistory()

    const [isPreview, setIsPreview] = useState(false)

    const [form] = Form.useForm<MotionFormProps>()

    const [isPublishing, setIsPublishing] = useState(false)

    const onPublish = useCallback(async () => {
        if (isPublishing) return
        form.submit()
    }, [form, isPublishing])

    const { isMP, getUserVotingInfo } = useGetUserVotingInfo()

    useEffect(() => {
        getUserVotingInfo()
    }, [])

    return (
        <StyledSponsorMotion>
            <Modal isOpen={!!errorMessage} onDismiss={() => setErrorMessage('')} maxHeight={90}>
                <TransactionErrorContent message={errorMessage} onDismiss={() => setErrorMessage('')} />
            </Modal>
            <TransactionConfirmationModal
                isOpen={isOpenTransitionModal}
                attemptingTxn={attemptingTxn}
                hash={hash}
                onDismiss={() => setIsOpenTransitionModal(false)}
                content={() => <>在你的钱包中确认这笔交易</>}
            />
            <ModalConfirmed
                isOpen={isOpen}
                onDismiss={() => history.go(-1)}
                onConfirm={() => setIsOpen(false)}
                confirmText="继续编辑"
                cancelText="离开"
                onClose={() => setIsOpen(false)}
            >
                <TYPE.subHeader>该版尚无草稿功能，离开将丢失内容</TYPE.subHeader>
            </ModalConfirmed>
            <Back beforeBack={() => setIsOpen(true)} />
            <MotionContentWrapper>
                {isPreview ? (
                    !isUndefined(isMP) && (
                        <MotionDetail
                            content={form.getFieldValue('describeContent')}
                            title={form.getFieldValue('title')}
                            motionSponsor={account}
                            isDemocraticMotion={!isMP}
                            isNotPreview={false}
                        />
                    )
                ) : (
                    <MotionForm
                        form={form}
                        setIsActionDisabled={setIsActionDisabled}
                        setAttemptingTxn={setAttemptingTxn}
                        setHash={setHash}
                        setIsOpenTransitionModal={setIsOpenTransitionModal}
                        setIsPublishing={setIsPublishing}
                        setErrorMessage={setErrorMessage}
                    />
                )}
            </MotionContentWrapper>
            {
                <MotionsActionsBox>
                    <MotionActions
                        isActionDisabled={isActionDisabled}
                        setIsPreview={setIsPreview}
                        isPreview={isPreview}
                        onPublish={onPublish}
                        isPublishing={isPublishing}
                    />
                </MotionsActionsBox>
            }
        </StyledSponsorMotion>
    )
}
