import 'antd/dist/antd.css'

import LottieLeftBottomAnimation from 'assets/lottie/lottieLeftBottom.json'
import LottieLeftTopAnimation from 'assets/lottie/lottieLeftTop.json'
import LottieRightBottomAnimation from 'assets/lottie/lottieRightBottom.json'
import LottieRightTopAnimation from 'assets/lottie/lottieRightTop.json'
import Header from 'components/Header'
import Lottie from 'lottie-react'
import React, { Suspense } from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

// import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
// import Language from 'components/Language'
// import GoTop from 'components/goTop'
import bodyBg from '../assets/img/body-bg.png'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import Council from './Council'
import DirectorInfo from './DirectorInfo'
import DoVote from './DoVote'
import MyProposal from './MyProposal'
import Overview from './Overview'
import SponsorMotion from './SponsorMotion'
import ToBeDirector from './ToBeDirector'
import Vote from './Vote'

// import Swap from './Swap'

const AppWrapper = styled.div`
    overflow: hidden;
    /* min-width:1280px ; */
    margin: 0 auto;
    height: 100vh;
    padding-top: 88px;
`

// const HeaderWrapper = styled.div`
//   ${({ theme }) => theme.flexRowNoWrap}
//   width: 100%;
//   justify-content: space-between;
// `

const BodyWrapper = styled.div`
    /* display: flex;
  flex-direction: column; */
    width: 100%;
    height: calc(100vh - 88px);
    position: relative;
    /* min-width:1280px ; */
    margin: 0 auto;
    /* padding-top: 160px; */
    align-items: center;
    /* flex: 1; */
    overflow-y: auto;
    overflow-x: auto;
    z-index: 10;
    /* flex: 1; */
    /* display: flex; */
    padding-top: 20px;
    /* ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 16px;
  `}; */
    background: url(${bodyBg}) repeat;
    z-index: 1;
`

// const Marginer = styled.div`
//   margin-top: 5rem;
// `

export default function App(): JSX.Element {
    return (
        <Suspense fallback={null}>
            <HashRouter>
                <Route component={DarkModeQueryParamReader} />
                <Header />
                <AppWrapper>
                    {/* <Language /> */}
                    {/* <GoTop /> */}
                    <Popups />
                    <BodyWrapper>
                        <Web3ReactManager>
                            <Switch>
                                <Route exact strict path="/Overview" component={Overview} />
                                <Route exact strict path="/Vote" component={Vote} />
                                <Route exact strict path="/Council" component={Council} />
                                <Route exact strict path="/MyProposal/:type?" component={MyProposal} />
                                <Route exact strict path="/DoVote/:type/:id/:isDetail?" component={DoVote} />
                                <Route exact strict path="/SponsorMotion" component={SponsorMotion} />
                                <Route exact strict path="/ToBeDirector" component={ToBeDirector} />
                                <Route exact strict path="/DirectorInfo/:address" component={DirectorInfo} />
                                <Route component={() => <Redirect to={{ pathname: '/Overview' }} />} />
                            </Switch>
                        </Web3ReactManager>
                        <Lottie
                            animationData={LottieLeftTopAnimation}
                            style={{
                                position: 'fixed',
                                left: 0,
                                top: 0,
                            }}
                        />
                        <Lottie
                            animationData={LottieRightBottomAnimation}
                            style={{
                                position: 'fixed',
                                right: 0,
                                bottom: 0,
                            }}
                        />
                        <Lottie
                            animationData={LottieRightTopAnimation}
                            style={{
                                position: 'fixed',
                                right: 0,
                                top: 0,
                            }}
                        />
                        <Lottie
                            animationData={LottieLeftBottomAnimation}
                            style={{
                                position: 'fixed',
                                left: 0,
                                bottom: 0,
                            }}
                        />
                    </BodyWrapper>
                </AppWrapper>
            </HashRouter>
        </Suspense>
    )
}
