import { BigNumber } from '@ethersproject/bignumber'
import { formatEther } from 'ethers/lib/utils'
import { useSingleCallResult } from 'state/multicall/hooks'

import { useGsTreasuryContract } from './useContract'

const millionSuffix = (val: string) =>
    val.replace(/^(\d+)(\d{2})(\d{4})(.\d+)?$/, (m, p1, p2) => {
        p2 = +`0.${p2}` + ''
        return `${p1}${+p2 ? p2.slice(1) : ''}M`
    })

const formatDigit = ({
    val,
    digit = 2,
    isMillionSuffix = true,
}: {
    val: string
    digit?: number
    isMillionSuffix?: boolean
}) => {
    if (!val.includes('.')) return val
    const [_integer, _decimal] = val.split('.')
    if (_decimal.length <= digit) return val
    val = `${_integer}.${_decimal.slice(0, digit)}`
    if (isMillionSuffix) val = millionSuffix(val)
    return val
}

export const useTreasury = () => {
    const contract = useGsTreasuryContract()
    const data = useSingleCallResult(contract, 'balanceOf')
    const [proposalAsset = BigNumber.from(0), marketAsset = BigNumber.from(0)] = data?.result || []

    return [
        formatDigit({
            val: formatEther(proposalAsset.add(marketAsset)),
        }),
        formatDigit({
            val: formatEther(proposalAsset),
        }),
        formatDigit({
            val: formatEther(marketAsset),
        }),
    ]
}
