import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { load, save } from 'redux-localstorage-simple'

import application from './application/reducer'
import { updateVersion } from './global/actions'
// import swap from './swap/reducer'
// import mint from './mint/reducer'
import lists from './lists/reducer'
// import burn from './burn/reducer'
import multicall from './multicall/reducer'
import transactions from './transactions/reducer'
import user from './user/reducer'

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists', 'application.blockNumber', 'application.popupList']
const PRELOADED_STATE_PERSISTED_KEYS: string[] = [
    'user',
    'transactions',
    'lists',
    'application.blockNumber',
    'application.popupList',
    'application.token',
]

const store = configureStore({
    reducer: {
        application,
        user,
        transactions,
        // swap,
        // mint,
        // burn,
        multicall,
        lists,
    },
    middleware: [...getDefaultMiddleware({ thunk: false }), save({ states: PERSISTED_KEYS })],
    preloadedState: load({
        states: PRELOADED_STATE_PERSISTED_KEYS,
    }),
})

store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
