import { Web3Provider } from '@ethersproject/providers'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
// import { ajaxPost } from 'api/axios'
import { ChainId } from 'gsswap_sdk'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setToken } from 'state/application/actions'

import { injected } from '../connectors'
import { NetworkContextName } from '../constants'
// import { isMobile } from 'react-device-detect'
import { AppDispatch, AppState } from './../state/index'

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: ChainId } {
    const context = useWeb3ReactCore<Web3Provider>()
    const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
    const result = context.active ? context : contextNetwork
    localStorage.setItem('chainId', result.chainId as any)
    return result
}

export function useEagerConnect(): boolean {
    const { activate, active } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
    const [tried, setTried] = useState(false)

    useEffect(() => {
        injected.isAuthorized().then((isAuthorized) => {
            if (isAuthorized) {
                activate(injected, undefined, true).catch(() => {
                    setTried(true)
                })
            } else {
                // isMobile &&
                if (window.ethereum) {
                    activate(injected, undefined, true).catch(() => {
                        setTried(true)
                    })
                } else {
                    setTried(true)
                }
            }
        })
    }, [activate]) // intentionally only running on mount (make sure it's only mounted once :))

    // if the connection worked, wait until we get confirmation of that to flip the flag
    useEffect(() => {
        if (active) {
            setTried(true)
        }
    }, [active])

    return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 */
export function useInactiveListener(suppress = false) {
    const { active, error, activate } = useWeb3ReactCore() // specifically using useWeb3React because of what this hook does
    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => {
        const { ethereum } = window
        if (ethereum && ethereum.on && !active && !error && !suppress) {
            const handleChainChanged = () => {
                // eat errors
                activate(injected, undefined, true).catch((error) => {
                    console.error('Failed to activate after chain changed', error)
                })
            }

            const handleAccountsChanged = (accounts: string[]) => {
                dispatch(setToken({ token: '', save: false }))
                if (accounts.length > 0) {
                    // eat errors
                    activate(injected, undefined, true).catch((error) => {
                        console.error('Failed to activate after accounts changed', error)
                    })
                }
            }

            ethereum.on('chainChanged', handleChainChanged)
            ethereum.on('accountsChanged', handleAccountsChanged)

            return () => {
                if (ethereum.removeListener) {
                    ethereum.removeListener('chainChanged', handleChainChanged)
                    ethereum.removeListener('accountsChanged', handleAccountsChanged)
                }
            }
        }
        return undefined
    }, [active, error, suppress, activate, dispatch])
}

export const useLoginStatus = () => {
    const [loginStatus, setLoginStatus] = useState(false)
    const { account } = useWeb3ReactCore()
    const token = useSelector<AppState>((state) => state.application.token)
    useEffect(() => {
        const _account = localStorage.getItem('account')
        account && setLoginStatus(account === _account)
    }, [account, setLoginStatus, token])

    return loginStatus
}
