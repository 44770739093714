import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import React, { useEffect, useState } from 'react'
import { Box } from 'rebass'
import styled from 'styled-components'

const StyledEditor = styled(Editor)`
    --w-e-textarea-color: rgba(0, 0, 0, 0.9);
    font-size: 16px;
    .w-e-text-container {
        border-radius: 0 0 6px 6px;
        span {
            line-height: 24px;
        }
    }
    .w-e-scroll {
        &::-webkit-scrollbar {
            width: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 100px;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
            background-color: #e5e5e5;
        }
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            background: #fff;
        }
    }
`
const StyledToolbar = styled(Toolbar)`
    .w-e-bar {
        border-radius: 6px 6px 0 0;
    }
`

interface EditorProps {
    placeholder?: string
    height?: number
    width?: number
    value?: string
    onChange?: Function
    maxLength?: number
    disabled?: boolean
}

export default ({
    height = 212,
    placeholder = '请输入内容...',
    maxLength = undefined,
    width = 537,
    value = '',
    onChange,
    disabled = false,
}: EditorProps) => {
    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null)

    // 工具栏配置
    const toolbarConfig: Partial<IToolbarConfig> = {
        excludeKeys: [
            'redo',
            'undo',
            'blockquote',
            'bold',
            'insertTable',
            'headerSelect',
            'fullScreen',
            '|',
            'lineHeight',
            'group-image',
            'group-video',
            'fontSize',
            'fontFamily',
            'emotion',
            'todo',
            'bgColor',
            'color',
            'codeBlock',
            'code',
        ],
    }

    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {
        autoFocus: false,
        placeholder,
        maxLength,

        hoverbarKeys: {
            text: {
                menuKeys: [],
            },
        },
    }

    // 及时销毁 editor ，重要！
    useEffect(() => {
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        }
    }, [editor])

    useEffect(() => {
        editor?.[disabled ? 'disable' : 'enable']?.()
    }, [disabled])

    return (
        <Box
            style={{
                cursor: disabled ? 'not-allowed' : 'auto',
            }}
        >
            <StyledToolbar
                editor={editor}
                defaultConfig={toolbarConfig}
                mode="default"
                style={{ borderBottom: '1px solid rgba(0,0,0,0.1)', width }}
            />
            <StyledEditor
                defaultConfig={editorConfig}
                value={value}
                onChange={(editor) => onChange?.(editor.getHtml())}
                onCreated={setEditor}
                mode="default"
                style={{ height, width }}
            />
        </Box>
    )
}
