import { useWeb3React } from '@web3-react/core'
import { ajaxPost } from 'api/axios'
import { ButtonPrimary } from 'components/Button'
import { VoteTable } from 'components/Table'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex } from 'rebass'
import styled from 'styled-components'
import { TYPE } from 'theme'

import Card from './components/Card'
import { ICardProps } from './components/Card/Card'

const Header = styled.div``
const Title = styled.div`
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
`
const Body = styled.div`
    margin-top: 20px;
`
const DataOverview = styled.div`
    margin-top: 20px;
    padding: 16px 20px;
    border-radius: 10px;
    background: #ffffff;
`
const AccountInfo = styled.div`
    display: flex;
    align-items: center;
`
const AccountAddress = styled(TYPE.body)`
    margin-left: 20px !important;
`
const DataOverviewInfo = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
`
const VoteCardTitle = styled.div`
    color: rgba(0, 0, 0, 0.6);
`
const VoteCardBody = styled.div`
    color: rgba(0, 0, 0, 0.9);
    font-size: 24px;
    font-weight: 500;
`
const VoteTag = styled(Flex)`
    min-width: 98px !important;
    border-radius: 49px;
    height: 40px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    margin-left: 20px !important;
`
export interface VoteProps {
    number: number
    avatar: string
    content: string
    time: string
    count: number
    startTime: string
}

const urlList = ['getProposalList', 'getCouncilList', 'getNodeList']
export default () => {
    const { account } = useWeb3React()
    const [title, setTitle] = useState('全民投票')
    const [tableRequestUrl, setTableRequestUrl] = useState(urlList[0])
    const [proposalInfo, setProposalInfo] = useState({
        isTopMeber: false,
        isNode: false,
        voterNumber: 0,
        councilVoterNumber: 0,
        nodeVoterNumber: 0,
        applyAmount: 0,
        proposalAmount: 0,
    })
    const receiveButtonStyle = useMemo(
        () => ({
            width: 'auto',
            // backgroundColor: '#E2E5E3',
            // color: '#FFFFFF',
            backgroundColor: '#E5F6EC',
            // color: '#24BA62',
            borderRadius: '77px',
            fontSize: '16px',
            height: '36px',
            minWidth: '96px',
            margin: 'auto',
        }),
        []
    )

    const cardCommonStyle: ICardProps = useMemo(
        () => ({
            headerStyle: { padding: '0' },
            bodyStyle: { padding: '28px 0 0 0' },
            footerStyle: { padding: '28px 0 0 0' },
            radius: '4px',
            width: '225px',
            padding: '30px 20px',
            border: true,
            align: 'center',
        }),
        []
    )
    const previewButtonStyle = useMemo(
        () => ({
            width: 'auto',
            backgroundColor: '#E5F6EC',
            color: '#5D9599',
            borderRadius: '77px',
            fontSize: '16px',
            height: '36px',
            minWidth: '96px',
            margin: 'auto',
        }),
        []
    )
    const getMyProposal = useCallback(async () => {
        if (!account) return
        const [myProposalRes] = await Promise.allSettled([ajaxPost('myProposal', { address: account })])
        if (myProposalRes.status === 'fulfilled' && myProposalRes.value.code === '200') {
            const { data } = myProposalRes.value
            setProposalInfo({
                isTopMeber: data.type === 1 || data.type === 3 ? true : false,
                isNode: data.type === 2 || data.type === 3 ? true : false,
                voterNumber: data.voterNumber || 0,
                councilVoterNumber: data.councilVoterNumber || 0,
                nodeVoterNumber: data.nodeVoterNumber || 0,
                applyAmount: data.applyAmount || 0,
                proposalAmount: data.proposalAmount || 0,
            })
        }
    }, [account, setProposalInfo])

    // 改为轮训
    useEffect(() => {
        getMyProposal()
        const timer = setInterval(() => getMyProposal(), 10000)
        return () => clearInterval(timer)
    }, [])
    const history = useHistory()
    const handlePreviewClick = useCallback((url: string) => history.push(url), [history])
    return (
        <>
            <Header>
                <Title>{title}</Title>
                <DataOverview>
                    <AccountInfo>
                        <TYPE.mediumHeader>我的帐户</TYPE.mediumHeader>
                        <AccountAddress>{account}</AccountAddress>
                        {proposalInfo.isNode && (
                            <VoteTag backgroundColor="#E5F6EC" color="#5D9599">
                                当选节点
                            </VoteTag>
                        )}
                        {proposalInfo.isTopMeber && (
                            <VoteTag backgroundColor="#FCE4C7" color="#568C6C">
                                当选议员
                            </VoteTag>
                        )}
                    </AccountInfo>
                    <DataOverviewInfo>
                        <Card
                            {...cardCommonStyle}
                            header={<VoteCardTitle>全民投票</VoteCardTitle>}
                            body={<VoteCardBody>{proposalInfo.voterNumber}</VoteCardBody>}
                            footer={
                                <ButtonPrimary
                                    {...previewButtonStyle}
                                    onClick={() => handlePreviewClick('/MyProposal/0')}
                                >
                                    查看
                                </ButtonPrimary>
                            }
                        />
                        <Card
                            {...cardCommonStyle}
                            header={<VoteCardTitle>理事会投票</VoteCardTitle>}
                            body={<VoteCardBody>{proposalInfo.councilVoterNumber}</VoteCardBody>}
                            footer={
                                <ButtonPrimary
                                    {...previewButtonStyle}
                                    onClick={() => handlePreviewClick('/MyProposal/1')}
                                >
                                    查看
                                </ButtonPrimary>
                            }
                        />
                        <Card
                            {...cardCommonStyle}
                            header={<VoteCardTitle>节点投票</VoteCardTitle>}
                            body={<VoteCardBody>{proposalInfo.nodeVoterNumber}</VoteCardBody>}
                            footer={
                                <ButtonPrimary
                                    {...previewButtonStyle}
                                    onClick={() => handlePreviewClick('/MyProposal/2')}
                                >
                                    查看
                                </ButtonPrimary>
                            }
                        />
                        <Card
                            {...cardCommonStyle}
                            header={<VoteCardTitle>待领取提案金</VoteCardTitle>}
                            body={<VoteCardBody>{proposalInfo.proposalAmount}</VoteCardBody>}
                            footer={
                                <ButtonPrimary
                                    {...receiveButtonStyle}
                                    color={proposalInfo.proposalAmount ? '#24BA62' : ''}
                                    disabled={!proposalInfo.proposalAmount}
                                    onClick={() => handlePreviewClick('/MyProposal/1')}
                                >
                                    领取GC
                                </ButtonPrimary>
                            }
                        />
                        <Card
                            {...cardCommonStyle}
                            header={<VoteCardTitle>待领取申请资金</VoteCardTitle>}
                            body={<VoteCardBody>{proposalInfo.applyAmount}</VoteCardBody>}
                            footer={
                                <ButtonPrimary
                                    {...receiveButtonStyle}
                                    color={proposalInfo.applyAmount ? '#24BA62' : ''}
                                    disabled={!proposalInfo.applyAmount}
                                    onClick={() => handlePreviewClick('/MyProposal/2')}
                                >
                                    领取GC
                                </ButtonPrimary>
                            }
                        />
                    </DataOverviewInfo>
                </DataOverview>
            </Header>
            <Body>
                <VoteTable<VoteProps>
                    hasShowMe
                    pla="搜索提案标题/提案者账户"
                    url={tableRequestUrl}
                    hasTitle={false}
                    tabChange={(p, { label, index }) => {
                        setTitle(label)
                        setTableRequestUrl(urlList[index])
                    }}
                />
            </Body>
        </>
    )
}
