import { useWeb3React } from '@web3-react/core'
import { ajaxPost } from 'api/axios'
import Back from 'components/Back'
import { ButtonPrimary } from 'components/Button'
import { VoteTable } from 'components/Table'
import Card, { ICardProps } from 'pages/Vote/components/Card/Card'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Flex } from 'rebass'
import styled from 'styled-components'
import { TYPE } from 'theme'

export interface VoteProps {
    number: number
    avatar: string
    content: string
    time: string
    count: number
}
export interface RouteParams {
    type: string
}
const Header = styled.div`
    margin-top: 20px;
`
const Title = styled.div`
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
`
const Body = styled.div`
    margin-top: 20px;
`
const DataOverview = styled.div`
    margin-top: 20px;
    padding: 16px 20px;
    border-radius: 10px;
    background: #ffffff;
`
const AccountInfo = styled.div`
    display: flex;
    align-items: center;
`
const AccountAddress = styled(TYPE.body)`
    margin-left: 20px !important;
`
const DataOverviewInfo = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
`
const VoteCardTitle = styled.div`
    color: rgba(0, 0, 0, 0.6);
`
const VoteCardBody = styled.div`
    color: rgba(0, 0, 0, 0.9);
    font-size: 24px;
    font-weight: 500;
`
const VoteTag = styled(Flex)`
    min-width: 98px !important;
    border-radius: 49px;
    height: 40px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    margin-left: 20px !important;
`
const urlList = ['myProposalList', 'myCouncilList', 'myNodeList']
export default (): JSX.Element => {
    const { account } = useWeb3React()
    const params = useParams<RouteParams>()
    const [tableRequestUrl, setTableRequestUrl] = useState(urlList[Number(params.type)])
    const [proposalInfo, setProposalInfo] = useState({
        isTopMeber: false,
        isNode: false,
        voterNumber: 0,
        councilVoterNumber: 0,
        nodeVoterNumber: 0,
        applyAmount: 0,
        proposalAmount: 0,
    })
    const receiveButtonStyle = useMemo(
        () => ({
            width: 'auto',
            // backgroundColor: '#E2E5E3',
            // color: '#FFFFFF',
            backgroundColor: '#E5F6EC',
            borderRadius: '77px',
            fontSize: '16px',
            height: '36px',
            minWidth: '96px',
            margin: 'auto',
        }),
        []
    )

    const cardCommonStyle: ICardProps = useMemo(
        () => ({
            headerStyle: { padding: '0' },
            bodyStyle: { padding: '28px 0 0 0' },
            footerStyle: { padding: '28px 0 0 0' },
            radius: '4px',
            width: '225px',
            padding: '30px 20px',
            border: true,
            align: 'center',
        }),
        []
    )
    const previewButtonStyle = useMemo(
        () => ({
            width: 'auto',
            backgroundColor: '#E5F6EC',
            color: '#5D9599',
            borderRadius: '77px',
            fontSize: '16px',
            height: '36px',
            minWidth: '96px',
            margin: 'auto',
        }),
        []
    )
    const refreshData = useCallback(async () => {
        const [myProposalRes] = await Promise.allSettled([ajaxPost('myProposal', { address: account })])
        if (myProposalRes.status === 'fulfilled' && myProposalRes.value.code === '200') {
            const { data } = myProposalRes.value
            setProposalInfo({
                isTopMeber: data.type === 1 || data.type === 3 ? true : false,
                isNode: data.type === 2 || data.type === 3 ? true : false,
                voterNumber: data.voterNumber || 0,
                councilVoterNumber: data.councilVoterNumber || 0,
                nodeVoterNumber: data.nodeVoterNumber || 0,
                applyAmount: data.applyAmount || 0,
                proposalAmount: data.proposalAmount || 0,
            })
        }
    }, [account])
    // 改为轮训
    useEffect(() => {
        refreshData()
    }, [params.type])
    const history = useHistory()
    const handlePreviewClick = useCallback((url: string) => history.replace(url), [history])

    const onClickUnclaimedProposalFund = useCallback(async () => {
        if (!account) return
        const {
            data: { list },
        } = await ajaxPost('myCouncilList', {
            address: account,
            pageNum: 1,
            pageSize: 9999,
        })
        const type = list.some((item: any) => !item.isReceiveAmount) ? 1 : 2

        handlePreviewClick(`/MyProposal/${type}`)
    }, [handlePreviewClick, account])

    return (
        <>
            <Back />
            <Header>
                <Title>我的提案</Title>
                <DataOverview>
                    <AccountInfo>
                        <TYPE.mediumHeader>我的帐户</TYPE.mediumHeader>
                        <AccountAddress>{account}</AccountAddress>
                        {proposalInfo.isNode ? (
                            <VoteTag backgroundColor="#E5F6EC" color="#5D9599">
                                当选节点
                            </VoteTag>
                        ) : (
                            <></>
                        )}
                        {proposalInfo.isTopMeber ? (
                            <VoteTag backgroundColor="#FCE4C7" color="#568C6C">
                                当选议员
                            </VoteTag>
                        ) : (
                            <></>
                        )}
                    </AccountInfo>
                    <DataOverviewInfo>
                        <Card
                            {...cardCommonStyle}
                            header={<VoteCardTitle>全民投票</VoteCardTitle>}
                            body={<VoteCardBody>{proposalInfo.voterNumber}</VoteCardBody>}
                            footer={
                                <ButtonPrimary
                                    {...previewButtonStyle}
                                    onClick={() => handlePreviewClick('/MyProposal/0')}
                                >
                                    查看
                                </ButtonPrimary>
                            }
                        />
                        <Card
                            {...cardCommonStyle}
                            header={<VoteCardTitle>理事会投票</VoteCardTitle>}
                            body={<VoteCardBody>{proposalInfo.councilVoterNumber}</VoteCardBody>}
                            footer={
                                <ButtonPrimary
                                    {...previewButtonStyle}
                                    onClick={() => handlePreviewClick('/MyProposal/1')}
                                >
                                    查看
                                </ButtonPrimary>
                            }
                        />
                        <Card
                            {...cardCommonStyle}
                            header={<VoteCardTitle>节点投票</VoteCardTitle>}
                            body={<VoteCardBody>{proposalInfo.nodeVoterNumber}</VoteCardBody>}
                            footer={
                                <ButtonPrimary
                                    {...previewButtonStyle}
                                    onClick={() => handlePreviewClick('/MyProposal/2')}
                                >
                                    查看
                                </ButtonPrimary>
                            }
                        />
                        <Card
                            {...cardCommonStyle}
                            header={<VoteCardTitle>待领取提案金</VoteCardTitle>}
                            body={<VoteCardBody>{proposalInfo.proposalAmount}</VoteCardBody>}
                            footer={
                                <ButtonPrimary
                                    {...receiveButtonStyle}
                                    color={proposalInfo.proposalAmount ? '#24BA62' : ''}
                                    disabled={!proposalInfo.proposalAmount}
                                    onClick={onClickUnclaimedProposalFund}
                                >
                                    领取GC
                                </ButtonPrimary>
                            }
                        />
                        <Card
                            {...cardCommonStyle}
                            header={<VoteCardTitle>待领取申请资金</VoteCardTitle>}
                            body={<VoteCardBody>{proposalInfo.applyAmount}</VoteCardBody>}
                            footer={
                                <ButtonPrimary
                                    {...receiveButtonStyle}
                                    color={proposalInfo.applyAmount ? '#24BA62' : ''}
                                    disabled={!proposalInfo.applyAmount}
                                    onClick={() => handlePreviewClick('/MyProposal/2')}
                                >
                                    领取GC
                                </ButtonPrimary>
                            }
                        />
                    </DataOverviewInfo>
                </DataOverview>
            </Header>
            <Body>
                <VoteTable<VoteProps>
                    isMyProposal
                    pla="搜索提案标题"
                    url={tableRequestUrl}
                    hasTitle={false}
                    defaultIndex={Number(params.type)}
                    tabChange={(p, { index }) => {
                        setTableRequestUrl(urlList[index])
                    }}
                />
            </Body>
        </>
    )
}
