import { Contract } from '@ethersproject/contracts'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { useWeb3React } from '@web3-react/core'
import { ChainId, WETH } from 'gsswap_sdk'
import { useMemo } from 'react'

import {
    GS_COUNCILLORS_VOTING_ADDRESS,
    GS_NODES_BASE_ADDRESS,
    GS_NODES_VOTING_ADDRESS,
    GS_REFERENDUMS_FACTORY_ADDRESS,
    GS_TREASURY_ADDRESS,
} from '../constants'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import ERC20_ABI, { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import GSCouncil from '../constants/abis/GSCouncil.json'
import GSCouncillorsVoting from '../constants/abis/GSCouncillorsVoting.json'
import GSNodesBase from '../constants/abis/GSNodesBase.json'
import GSNodesVoting from '../constants/abis/GSNodesVoting.json'
import GSReferendumsFactory from '../constants/abis/GSReferendumsFactory.json'
import GSTreasury from '../constants/abis/GSTreasury.json'
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract } from '../utils'
import { GS_COUNCIL_ADDRESS } from './../constants/index'
import { useActiveWeb3React } from './index'

// returns null on errors
export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
    const { library, account } = useActiveWeb3React()

    return useMemo(() => {
        if (!address || !ABI || !library) return null
        try {
            return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [address, ABI, library, withSignerIfPossible, account])
}

export function useGsCouncillorsVotingContract(): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(GS_COUNCILLORS_VOTING_ADDRESS[chainId as ChainId], GSCouncillorsVoting, true)
}
export function useGsNodesVotingContract(): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(GS_NODES_VOTING_ADDRESS[chainId as ChainId], GSNodesVoting, true)
}

export function useGsNodesBaseContract(): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(GS_NODES_BASE_ADDRESS[chainId as ChainId], GSNodesBase, true)
}
export function useGsCouncilContract(): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(GS_COUNCIL_ADDRESS[chainId as ChainId], GSCouncil, true)
}

export function useGsTreasuryContract(): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(GS_TREASURY_ADDRESS[chainId as ChainId], GSTreasury, true)
}

export function useGsReferendumsFactoryContract(): Contract | null {
    const { chainId } = useWeb3React()
    return useContract(GS_REFERENDUMS_FACTORY_ADDRESS[chainId as ChainId], GSReferendumsFactory, true)
}

export function useV2MigratorContract(): Contract | null {
    return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useActiveWeb3React()
    return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useActiveWeb3React()
    let address: string | undefined
    if (chainId) {
        switch (chainId) {
            case ChainId.MAINNET:
            case ChainId.GÖRLI:
            case ChainId.ROPSTEN:
            case ChainId.RINKEBY:
                address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
                break
        }
    }
    // console.log(address)

    return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
    return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
    const { chainId } = useActiveWeb3React()
    return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useSocksController(): Contract | null {
    const { chainId } = useActiveWeb3React()
    return useContract(
        chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
        UNISOCKS_ABI,
        false
    )
}
